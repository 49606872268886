/* eslint-disable */
import axios from "axios";
import React, { useEffect, useState } from "react";

import qs from "qs";

import folder from "../images/folder.png";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import LoadingOverlay from "react-loading-overlay";
import QuizzesInFolder from "./QuizzesInFolder";
import SearchedQuizzesInFolder from "./SearchedQuizzesInFolder";

import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
// import "../less/owl.carousel.min.css"
import "../less/folder.css";
import "../less/test.css";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function MyFolderDetails(props) {
  console.log(props);

  const classes = useStyles();

  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const token = cookies["access_token"]; //getToken();
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  const [folderId, setFolderId] = useState(props.location.state.id);
  const [folderName, setFolderName] = useState(props.location.state.name);
  const createdDateInISO = props.location.state.created_at;
  let date = new Date(createdDateInISO);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();
  const [createdAt, setCreatedAt] = useState(dt + "-" + month + "-" + year);
  const [parentFolderId, setParentFolderId] = useState(
    props.location.state.parent_folder_id
  );

  const [subFolders, setSubFolders] = useState([]);
  const [pagesInSubfolder, setPagesInSubFolder] = useState();

  const myFoldersList = props.location.state.myFoldersList;

  const [totalQuizzes, setTotalQuizzes] = useState();
  const [pagesInQuizzes, setPagesInQuizzes] = useState();
  const [quizzesDetails, setQuizzesDetails] = useState([]);

  const [newFolderName, setNewFolderName] = useState();

  const [folderCreated, setFolderCreated] = useState(false);
  const [firstTimeData, setFirstTimeData] = useState(true);

  const [sortingParams, setSortingParams] = useState("name");
  const [sortingDirection, setSortingDirection] = useState("ASC");

  const [quizzesLoading, setQuizzesLoading] = useState(false);

  const [searchedTermForContent, setSearchedTermFForContent] = useState();
  const [pagesInSearchedContent, setPagesInSearchedContent] = useState();
  const [totalSearchedContent, setTotalSearchedContent] = useState();
  const [searchedContent, setSearchedContent] = useState([]);
  const [isSearchOn, setIsSearchOn] = useState(false);

  const [banners, setBanners] = useState();

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let ADVERTISEMENT_PATH = awsImageUrl + "/uploads/advertisement/";
  let BANNER_PATH = awsImageUrl + "/uploads/banners/";

  useEffect(() => {
    setQuizzesLoading(true);
    axios({
      method: "post",
      url: "/api/folders/quiz/list",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        folder_id: folderId,
      }),
    })
      .then((response) => {
        console.log("response", response);
        setPagesInQuizzes(response.data.total_pages);
        setTotalQuizzes(response.data.total);
        setQuizzesLoading(false);
      })
      .catch((error) => {
        // removeUserSession();
        setQuizzesLoading(false);
      });

    axios({
      method: "post",
      url: "/api/banners/list",
      headers: {
        locale: globalLangCode,
        // 'x-access-token': "Memo "+token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log(response);
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].banner_type == 2) {
            setBanners(response.data.data[i]);
            break;
          }
        }
      })
      .catch((error) => {
        // removeUserSession();
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    setQuizzesLoading(true);
    console.log("check for folderId", folderId);
    setPagesInSubFolder(0);
    setPagesInQuizzes(0);
    setIsSearchOn(false);
    if (folderCreated == true || firstTimeData == true) {
      setFolderCreated(false);
      axios({
        method: "post",
        url: "/api/folders/list",
        headers: {
          locale: globalLangCode,
          "x-access-token": "Memo " + token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
          parent_folder_id: folderId,
        }),
      })
        .then((response) => {
          console.log("response", response);
          setPagesInSubFolder(response.data.total_pages);
          setQuizzesLoading(false);
        })
        .catch((error) => {
          // removeUserSession();
          setQuizzesLoading(false);
        });

      axios({
        method: "post",
        url: "/api/folders/quiz/list",
        headers: {
          locale: globalLangCode,
          "x-access-token": "Memo " + token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
          folder_id: folderId,
        }),
      })
        .then((response) => {
          console.log("response", response);
          setPagesInQuizzes(response.data.total_pages);
          setTotalQuizzes(response.data.total);
          setQuizzesLoading(false);
        })
        .catch((error) => {
          // removeUserSession();
          setQuizzesLoading(false);
        });
    }
    setQuizzesLoading(false);
  }, [folderId, folderCreated, sortingParams, sortingDirection]);

  useEffect(() => {
    setQuizzesLoading(true);
    if (pagesInQuizzes == 0) {
      console.log(pagesInQuizzes);
      setQuizzesDetails([]);
      setQuizzesLoading(false);
    }
    for (let i = 1; i <= pagesInQuizzes; i++) {
      axios({
        method: "post",
        url: "/api/folders/quiz/list",
        headers: {
          locale: globalLangCode,
          "x-access-token": "Memo " + token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
          folder_id: folderId,
          page: i,
          "order[0].[column]": sortingParams,
          "order[0].[dir]": sortingDirection,
        }),
      })
        .then((response) => {
          console.log("response", response);
          setQuizzesDetails((quizzesDetails) =>
            quizzesDetails.concat(response.data.data)
          );
          setQuizzesLoading(false);
        })
        .catch((error) => {
          // removeUserSession();
          setQuizzesLoading(false);
        });
    }
  }, [pagesInQuizzes]);

  useEffect(() => {
    console.log(pagesInSubfolder);
    setQuizzesLoading(true);
    if (pagesInSubfolder == 0) {
      setSubFolders([]);
      setQuizzesLoading(false);
    }
    for (let i = 1; i <= pagesInSubfolder; i++) {
      axios({
        method: "post",
        url: "/api/folders/list",
        headers: {
          locale: globalLangCode,
          "x-access-token": "Memo " + token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
          parent_folder_id: folderId,
        }),
      })
        .then((response) => {
          console.log("response", response);
          setSubFolders((subFolders) => subFolders.concat(response.data.data));
          setQuizzesLoading(false);
        })
        .catch((error) => {
          // removeUserSession();
          setQuizzesLoading(false);
        });
    }
  }, [pagesInSubfolder]);

  const goToMyFolderDetails = (id, name, parent_folder_id, created_at) => {
    console.log("going");
    setFolderId(id);
    setFolderName(name);
    setParentFolderId(parent_folder_id);
    setSearchedContent([]);
    setSearchedTermFForContent();
    const dateInISO = created_at;
    let date = new Date(dateInISO);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    setCreatedAt(dt + "-" + month + "-" + year);
  };

  const addFolder = () => {
    axios({
      method: "post",
      url: "/api/folders/create",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        parent_folder_id: folderId,
        folder_name: newFolderName,
      }),
    })
      .then((response) => {
        console.log("response", response);
        setFolderCreated(true);
        // window.location.reload()
        setNewFolderName();
      })
      .catch((error) => {
        // removeUserSession();
      });
  };

  const renameFolder = () => {
    axios({
      method: "post",
      url: "/api/folders/rename",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        folder_id: folderId,
        folder_name: newFolderName,
      }),
    })
      .then((response) => {
        console.log("response", response);
        setFolderCreated(true);
        // window.location.reload()
        setFolderName(newFolderName);
        setNewFolderName();
      })
      .catch((error) => {
        // removeUserSession();
      });
  };

  const deleteFolder = () => {
    axios({
      method: "post",
      url: "/api/folders/delete",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        folder_id: folderId,
      }),
    })
      .then((response) => {
        console.log("response", response);
        goToMyFolders();
      })
      .catch((error) => {
        // removeUserSession();
      });
  };

  const clearFolderNameInput = () => {
    setNewFolderName();
    const input = document.querySelectorAll('input[name="folderName"]');
    input[0].value = null;
    input[1].value = null;
  };

  const handleChangeSortingParams = (event) => {
    setIsSearchOn(false);
    setSortingParams(event.target.value);
  };

  const handleChangeSortingDirection = (event) => {
    setIsSearchOn(false);
    setSortingDirection(event.target.value);
  };

  useEffect(() => {
    if (!searchedTermForContent) {
      clearSearches();
    }
  }, [searchedTermForContent]);

  const searchContentForThisString = () => {
    if (folderId && searchedTermForContent) {
      setIsSearchOn(true);
      setQuizzesLoading(true);
      setPagesInSearchedContent();
      setTotalSearchedContent();
      setSearchedContent([]);
      axios({
        method: "post",
        url: "/api/folders/search/quiz",
        headers: {
          locale: globalLangCode,
          "x-access-token": "Memo " + token,
        },
        data: qs.stringify({
          folder_id: folderId,
          term: searchedTermForContent,
        }),
      })
        .then((response) => {
          console.log("search response", response);
          setPagesInSearchedContent(response.data.total_pages);
          setTotalSearchedContent(response.data.total);
        })
        .catch((error) => {
          // removeUserSession();
        });
    } else {
      clearSearches();
    }
  };

  useEffect(() => {
    if (pagesInSearchedContent > 0) {
      setQuizzesLoading(true);
      for (let i = 1; i <= pagesInSearchedContent; i++) {
        axios({
          method: "post",
          url: "/api/folders/search/quiz",
          headers: {
            locale: globalLangCode,
            "x-access-token": "Memo " + token,
          },
          data: qs.stringify({
            folder_id: folderId,
            term: searchedTermForContent,
            page: i,
          }),
        })
          .then((response) => {
            console.log("search response for all pages", response);
            setSearchedContent((search) => search.concat(response.data.data));
            setQuizzesLoading(false);
          })
          .catch((error) => {
            // removeUserSession();
          });
      }
    } else {
      setQuizzesLoading(false);
    }
  }, [pagesInSearchedContent]);

  const clearSearches = () => {
    setTotalQuizzes(0);
    setPagesInQuizzes(0);
    setSearchedTermFForContent("");
    setIsSearchOn(false);
    axios({
      method: "post",
      url: "/api/folders/quiz/list",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        folder_id: folderId,
      }),
    })
      .then((response) => {
        console.log("response", response);
        setPagesInQuizzes(response.data.total_pages);
        setTotalQuizzes(response.data.total);
        // setQuizzesLoading(false)
      })
      .catch((error) => {
        // removeUserSession();
        // setQuizzesLoading(false)
      });
  };

  const goToDashboard = () => {
    props.history.push("/myprofile");
  };

  const goToMyFolders = () => {
    props.history.push("/myfolders");
  };

  return (
    <LoadingOverlay
      active={quizzesLoading}
      spinner
      text="Loading your content..."
    >
      <React.Fragment>
        {/* <BasicHeader 
    {...props}
/> */}

        <div class="inner-header">
          <div class="container">
            <div class="row">
              <div class="col-sm-5 col-md-6 col-lg-6">
                <h2 class="animated fadeInLeft">{t("folderdetailtitle")}</h2>
              </div>
              <div class="col-sm-7 col-md-6 col-lg-6">
                <div class="breadcrumb-item animated fadeInRight">
                  <ul>
                    <li onClick={goToDashboard}>
                      <a href="javascript:void(0)">
                        <span class="icon-home1"></span> {t("home")}
                      </a>
                    </li>
                    <li>
                      <span class="icon-next"></span>
                    </li>
                    <li onClick={goToMyFolders}>
                      <a href="javascript:void(0)">
                        <span></span>
                        {t("my_folders")}
                      </a>
                    </li>
                    <li>
                      <span class="icon-next"></span>
                    </li>
                    <li>
                      <a href="javascript:void(0)" class="active">
                        {t("folderdetailtitle")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {banners ? (
          <div className="banner_image">
            <a class="item" href={banners.link}>
              <img src={BANNER_PATH + banners.image} class="img_banner"></img>
            </a>
          </div>
        ) : null}

        <section class="gener_gap">
          <div class="container">
            <div class="row bord_sh">
              <div class="col-lg-2 col-md-3">
                <div class="cust_folder">
                  <img src={folder} />
                  <h4>{folderName}</h4>
                </div>
              </div>
              <div class="col-lg-10 col-md-9">
                <div class="row">
                  <div class="col-md-11">
                    <div class="row folder-icons" id="owl-carousel4">
                      <div class="col-sm-2 col-6">
                        <div class="item">
                          <div class="cust_genre">
                            <span class="icon icon-content1"></span>
                            <h4>{t("total_contents")}</h4>
                            <strong>{totalQuizzes}</strong>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-2 col-6">
                        <div class="item">
                          <div class="cust_genre">
                            <span class="icon icon-paid"></span>
                            <h4>{t("paid")}</h4>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-2 col-6">
                        <div class="item">
                          <div class="cust_genre">
                            <span class="icon icon-free"></span>
                            <h4>{t("free")}</h4>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-2 col-6">
                        <div class="item">
                          <div class="cust_genre">
                            <span class="icon icon-calendar2"></span>
                            <h4>{t("created_on")}</h4>
                            <strong>{createdAt}</strong>
                          </div>
                        </div>
                      </div>
                      {/* <div class="col-sm-2">
                            <div class="item">
                                <div class="cust_genre">
                                    <span class="icon icon-accuracy"></span>
                                    <h4>{t("accuracy")}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="item">
                                <div class="cust_genre">
                                <span class="icon icon-creators"></span>
                                    <h4>{t("creators")}</h4>
                                </div>
                            </div>
                        </div>                             */}
                    </div>
                  </div>
                  <div class="col-md-1 customize_link">
                    <a onClick={deleteFolder} href="javascript:void(0)">
                      <i class="fa fa-trash"></i>
                    </a>
                    <a
                      onClick={() => clearFolderNameInput()}
                      data-toggle="modal"
                      data-target="#myModalRename"
                      href="javascript:void(0)"
                    >
                      <i class="fa fa-edit"></i>
                    </a>
                    <a
                      onClick={() => clearFolderNameInput()}
                      data-toggle="modal"
                      data-target="#myModal"
                      href="javascript:void(0)"
                    >
                      <i class="fa fa-plus"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="gener_gap">
          <div className="container">
            <div class="invite-info" id="collapseInvite">
              <h2>{t("search_contents_in_this_folder")}</h2>
              <div class="search-control">
                <input
                  type="text"
                  placeholder={t("search_here")}
                  value={searchedTermForContent}
                  onChange={(e) => setSearchedTermFForContent(e.target.value)}
                />
              </div>
            </div>
          </div>
        </section>

        <div class="quize_listing">
          <button
            class="continue blue-bg-btn"
            style={{ margin: "10px" }}
            onClick={searchContentForThisString}
          >
            {t("search_here")}
          </button>

          <button
            class="continue blue-bg-btn"
            style={{ margin: "10px" }}
            onClick={clearSearches}
          >
            {t("clear_searches")}
          </button>
        </div>

        {pagesInQuizzes > 0 &&
        quizzesDetails &&
        totalQuizzes === quizzesDetails.length ? (
          <section class="gener_gap">
            <div class="container">
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  {t("sorting_params")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sortingParams}
                  onChange={handleChangeSortingParams}
                >
                  <MenuItem value={"name"}>{t("namee")}</MenuItem>
                  <MenuItem value={"created_at"}>{t("created_time")}</MenuItem>
                </Select>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  {t("sorting_directions")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sortingDirection}
                  onChange={handleChangeSortingDirection}
                >
                  <MenuItem value={"ASC"}>{t("asc")}</MenuItem>
                  <MenuItem value={"DESC"}>{t("desc")}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </section>
        ) : null}

        {isSearchOn === false &&
        quizzesLoading === false &&
        pagesInQuizzes > 0 &&
        quizzesDetails &&
        totalQuizzes === quizzesDetails.length ? (
          pagesInQuizzes > 0 &&
          quizzesDetails &&
          totalQuizzes === quizzesDetails.length ? (
            <QuizzesInFolder quizzesDetails={quizzesDetails} {...props} />
          ) : null
        ) : null}

        {isSearchOn === true &&
        quizzesLoading === false &&
        pagesInSearchedContent > 0 &&
        searchedContent &&
        totalSearchedContent === searchedContent.length ? (
          pagesInSearchedContent > 0 &&
          searchedContent &&
          totalSearchedContent === searchedContent.length ? (
            <SearchedQuizzesInFolder
              quizzesDetails={searchedContent}
              {...props}
            />
          ) : null
        ) : null}

        <section class="gener_gap">
          <div class="container">
            <div class="d-flex performance2">
              <div class="mr-auto">
                <h3 style={{ color: "#1d5190!important" }}>
                  {t("sub_folders")}
                </h3>
              </div>
              {/* <div class="d-flex flex-wrap align-items-end folder-all cust_link">
            <h5 class="mr-2 mb-0">View all<i class="fa fa-arrow-right"></i></h5>
            
        </div> */}
            </div>

            <div class="row" id="owl-carousel">
              {subFolders
                ? subFolders.length > 0
                  ? subFolders.map((myfolder) => (
                      <div
                        class="col-lg-2 col-md-3 col-sm-4 col-6"
                        onClick={() =>
                          goToMyFolderDetails(
                            myfolder.id,
                            myfolder.name,
                            myfolder.parent_folder_id,
                            myfolder.created_at
                          )
                        }
                      >
                        <div class="item">
                          <div class="cust_genre">
                            <img src={folder} />
                            <h4
                              dangerouslySetInnerHTML={{
                                __html: myfolder.name,
                              }}
                            ></h4>
                          </div>
                        </div>
                      </div>
                    ))
                  : null
                : null}
            </div>
          </div>
        </section>

        <section class="gener_gap">
          <div class="container">
            <div class="d-flex performance2">
              <div class="mr-auto">
                <h3 style={{ color: "#1d5190!important" }}>
                  {t("more_folders")}
                </h3>
              </div>
              {/* <div class="d-flex flex-wrap align-items-end folder-all cust_link">
            <h5 class="mr-2 mb-0">View all<i class="fa fa-arrow-right"></i></h5>
            
        </div> */}
            </div>

            <div class="row" id="owl-carousel">
              {myFoldersList
                ? myFoldersList.length > 0
                  ? myFoldersList.map((myfolder) => (
                      <div
                        class="col-lg-2 col-md-3 col-sm-3 col-6"
                        onClick={() =>
                          goToMyFolderDetails(
                            myfolder.id,
                            myfolder.name,
                            myfolder.parent_folder_id,
                            myfolder.created_at
                          )
                        }
                      >
                        <div class="item">
                          <div class="cust_genre">
                            <img src={folder} />
                            <h4
                              dangerouslySetInnerHTML={{
                                __html: myfolder.name,
                              }}
                            ></h4>
                          </div>
                        </div>
                      </div>
                    ))
                  : null
                : null}
            </div>
          </div>
        </section>

        <div id="myModal" class="modal fade" role="dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{t("create_folder")}</h4>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <form method="#">
                  <div class="form-group">
                    <label>{t("folder_name")}</label>
                    <input
                      value={newFolderName}
                      type="text"
                      name="folderName"
                      class="form-control"
                      onChange={(e) => setNewFolderName(e.target.value)}
                    />
                    <br></br>
                    <div
                      onClick={addFolder}
                      class="btn btn-primary btn_cst blue-bg-btn"
                      data-dismiss="modal"
                    >
                      {t("addTitle")}
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-default blue-bg-btn"
                  data-dismiss="modal"
                >
                  {t("close")}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="myModalRename" class="modal fade" role="dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{t("lbl_rename_folder")}</h4>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div class="modal-body">
                <form method="#">
                  <div class="form-group">
                    <label>{t("folder_name")}</label>
                    <input
                      value={newFolderName}
                      type="text"
                      name="folderName"
                      class="form-control"
                      onChange={(e) => setNewFolderName(e.target.value)}
                    />
                    <br></br>
                    <div
                      onClick={renameFolder}
                      class="btn btn-primary btn_cst blue-bg-btn"
                      data-dismiss="modal"
                    >
                      {t("rename")}
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-default blue-bg-btn"
                  data-dismiss="modal"
                >
                  {t("close")}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <BasicFooter /> */}
      </React.Fragment>
    </LoadingOverlay>
  );
}

export default MyFolderDetails;
