/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Context } from "../Reducers/Store";
import Pagination from "./Pagination";

const PAGE_LIMIT = 10;

function CreateContentPage(props) {
  console.log("content props", props);

  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const [state, dispatch] = useContext(Context);
  const [quizzes, setQuizzes] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState();
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [total, setTotal] = useState(null);
  const [current, setCurrent] = useState(1);

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";

  const token = cookies["access_token"]; //getToken();

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  useEffect(() => {
    dispatch({ type: "UPDATE_CURRENT_MENU", payload: "id_contents_page" });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "get",
      url: "/api/language/list",
      headers: {
        locale: globalLangCode,
      },
    })
      .then((response) => {
        console.log("languages", response.data.data);
        setLanguages(response.data.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (languages.length > 0) {
      let setCurrent = false;
      for (let i = 0; i < languages.length; i++) {
        if (globalLangId == languages[i].id) {
          setCurrentLanguage(languages[i]);
          setCurrent = true;
        }
      }
      if (!setCurrent) {
        setCurrentLanguage(languages[0]);
      }
    }
  }, [languages]);

  useEffect(() => {
    setLoading(true);
    if (currentLanguage) {
      console.log("current language code is", currentLanguage);
      fetchQuizzes();
    }
  }, [currentLanguage]);

  const fetchQuizzes = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/my/played/quiz/list",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: currentLanguage.lang_code,
      },
      data: qs.stringify({
        page: currentPage.toString(),
        limit: PAGE_LIMIT.toString(),
      }),
    })
      .then((response) => {
        console.log(response);
        let quizzesList = response.data.data;
        setQuizzes(quizzesList);
        setTotalPages(response.data.total_pages);
        setTotal(response.data.total);
        setLoading(false);
        //setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
      });
  };

  const gotoAttributesPage = () => {
    axios({
      method: "post",
      url: "/api/quiz_deck/createcheck",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: "en",
      },
    })
      .then((response) => {
        console.log("can create content success", response);
        props.history.push("/createcontent");
        //setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
        console.log("can create content error", error.response);
        document.getElementById("modalOpenButton").click();
      });
  };

  const goToMyBecomeAPremiumUser = () => {
    props.history.push("/managesubscriptions/" + 3); //3 for premium user
  };

  const goToDashboardPage = () => {
    props.history.push("/myprofile");
  };

  const goToContentDetailsPage = (quizdeckid) => {
    props.history.push("/contentdetails/" + quizdeckid);
  };

  const onPageChanged = (data) => {
    setLoading(true);
    fetchQuizzes(data.currentPage);
    setCurrent(data.currentPage);
  };

  return (
    <React.Fragment>
      {/* <BasicHeader 
                {...props}
           /> */}
      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("contents_list")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li onClick={goToDashboardPage}>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span>
                      {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("contents_list")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading === false ? (
        <div class="create-content-wrapper">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-12 col-md-10 col-lg-7">
                <div class="create-content-tabs animated fadeInRight">
                  <ul class="nav nav-tabs nav-justified">
                    {languages && currentLanguage
                      ? languages.map((language) => (
                          <li
                            class="nav-item"
                            onClick={() => setCurrentLanguage(language)}
                          >
                            <a
                              href=""
                              data-toggle="tab"
                              class={
                                language.id == currentLanguage.id
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                            >
                              <img src={COUNTRY_FLAG + language.flag} />
                              <span>{t(language.name)}</span>
                            </a>
                          </li>
                        ))
                      : null}
                  </ul>
                  {/* <!-- Tab panes --> */}
                  <div class="tab-content">
                    <div class="tab-pane container active" id="EnglishTab">
                      <div onClick={gotoAttributesPage} class="add-quiz-btn">
                        <a href="javascript:void(0)">
                          <span class="icon icon-plus blue-bg-btn"></span>{" "}
                          <span>{t("create_content")}</span>
                        </a>
                      </div>

                      <div class="create-content-accordion">
                        <div id="accordion" class="accordion">
                          {quizzes && quizzes.length > 0
                            ? quizzes.map((object, i) => (
                                // return <ContentRow obj={object} key={i} index={i} />;
                                <div class="card">
                                  <div
                                    class="card-header"
                                    data-toggle="collapse"
                                    href={"#collapse" + i}
                                  >
                                    <div class="row">
                                      <div
                                        class="col-sm-9"
                                        dangerouslySetInnerHTML={{
                                          __html: object.name,
                                        }}
                                      ></div>
                                      <div class="col-sm-3 question">
                                        {object.questionCount} {t("question")}{" "}
                                        <span class="icon-next"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    id={"collapse" + i}
                                    class="card-body collapse show"
                                    data-parent="#accordion"
                                  >
                                    <p
                                      dangerouslySetInnerHTML={
                                        object.description
                                          ? { __html: object.description }
                                          : null
                                      }
                                    ></p>
                                    <div
                                      class="text-center"
                                      onClick={() =>
                                        goToContentDetailsPage(object.id)
                                      }
                                    >
                                      <a
                                        href="javascript:void(0)"
                                        class="btn-background blue-bg-btn"
                                      >
                                        {t("view_content_detail")}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center pt-4">
                  {total > PAGE_LIMIT ? (
                    <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                      <Pagination
                        totalRecords={total}
                        totalPages={totalPages}
                        currentPage={current}
                        pageLimit={PAGE_LIMIT}
                        pageNeighbours={1}
                        onPageChanged={onPageChanged}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          type="ThreeDots"
          color="#294177"
          height={100}
          width={100}
          // timeout={3000} //3 secs
        />
      )}

      <p
        class="details-play-quiz"
        id="modalOpenButton"
        data-toggle="modal"
        data-target="#freeCheck"
        style={{ display: "none" }}
      >
        Open Modal
      </p>

      <div
        class="modal fade add-rate-review-info"
        id="freeCheck"
        tabindex="-1"
        role="dialog"
        aria-labelledby="freeCheck"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Access rights
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="add-rate-review-info">
                <p>
                  <strong>{t("free_user_cannot_create_5_contents")}</strong>
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <div class="save-cancel-btn">
                <a
                  data-dismiss="modal"
                  onClick={goToMyBecomeAPremiumUser}
                  href="javascript:void(0)"
                  class="btn-border blue-bg-btn"
                >
                  {t("become_a_premium_member")}
                </a>
                {/*
                                <a
                                    data-dismiss="modal"
                                    // onClick={skipReview} 
                                    href="javascript:void(0)" class="btn-background blue-bg-btn">
                                    {t('skip')}
                            </a>
                                */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default CreateContentPage;
