/* eslint-disable */
import axios from "axios";
import i18next from "i18next";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getDeviceUUID } from "../Utils/Common";
import BasicFooter from "./BasicFooter";
import LanguagesComponent from "./LanguagesComponent";
import LoginHeader from "./LoginHeader";

import dashlogo from "../images/dash-logo.png";
import languagelogo from "../images/memorize-language-logo.png";
//import "../images/favicon.ico"

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/login.css";
import "../less/releway-font.css";

let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";

import firebase from "firebase";

function Login(props) {
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  let bFromExternalLink = false;
  let for_user_type_id = "";
  let quiz_deck_id = "";
  const { t } = useTranslation();
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode;

  if (!globalLang) {
    globalLangCode = i18next.language;
  } else {
    globalLangCode = globalLang.lang_code;
  }
  console.log("globalLang", globalLangCode);
  let temp = "";
  let redirect_type = props.match.params.redirect_type
    ? props.match.params.redirect_type
    : "";
  if (redirect_type) {
    //alert(redirect_type)
    bFromExternalLink = true;
    temp = props.match.params.email_id ? props.match.params.email_id : "";
    switch (redirect_type) {
      case "1": //KYC link
        for_user_type_id = props.match.params.data
          ? props.match.params.data
          : "";
        break;
      case "2": //Content Details
        quiz_deck_id = props.match.params.data ? props.match.params.data : "";
        //alert(quiz_deck_id)
        break;
    }
  }

  /*
    //console.log(props.match.params)
    let temp = (props.match.params.email_id) ? props.match.params.email_id : '';
    //console.log('email_id=' + temp)
    if(temp) {
        bFromExternalLink = true;
        for_user_type_id = (props.match.params.user_type_id) ? props.match.params.user_type_id : '';
    }
    */

  const emailId = useFormInput(temp);
  const password = useFormInput("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("password");

  const [emailIdForForgetPassword, setEmailIdForForgetPassword] = useState();
  const [user, setUser] = useState();
  const [userId, setUserId] = useState();
  const [otp, setOTP] = useState();
  const [resetPassword, setResetPassword] = useState();

  const [userNameSignup, setUserNameSignup] = useState();
  const [userLastNameSignup, setUserLastNameSignup] = useState();

  const [emailIdSignup, setEmailIdSignup] = useState();
  const [dialCodeSignup, setDialCodeSignup] = useState();
  const [countryCodeSignup, setCountryCodeSignup] = useState();
  const [phoneNumberSignup, setPhoneNumberSignup] = useState();
  const [passwordSignup, setPasswordSignup] = useState();
  const [confirmPasswordSignup, setConfirmPasswordSignup] = useState();
  //const [referalCodeSignup, setReferalCodeSignup] = useState()
  const [countriesListSignup, setCountriesListSignup] = useState([]);

  const [tokenAfterSigningUp, setTokenAfterSigningUp] = useState();

  const [termsAndConditions, setTermsAndConditions] = useState();
  const [privacyPolicy, setPrivacyPolicy] = useState();
  const [refreshLoginHeader, ToggleRefreshLoginHeader] = useState(false);
  const [deviceUUID, setDeviceUUID] = useState("");

  const showMessageToUser = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showVerifyMessageToUser = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 15000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorMessageToUser = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // const defaultLang =  {
  //     "id": 1,
  //     "name": "English",
  //     "lang_code": "en",
  //     "flag": "usa.png"
  // }

  const defaultLang = cookies["login_language"]; //getUserLoginLanguage();
  console.log("defaultLang", defaultLang);

  useEffect(() => {
    if (!globalLangCode) return;
    console.log("useEffect called");
    updateMetaTags();
    axios({
      method: "get",
      url: "/api/language/list",
      headers: {
        locale: globalLangCode,
      },
    })
      .then((response) => {
        console.log("languages", response.data.data);
        response.data.data.forEach((languageObj) => {
          console.log("i18next.language", i18next.language);
          if (languageObj.lang_code === i18next.language) {
            console.log("hereoooooooooooo");

            setCookieGlobalLanguage(languageObj);
          }
        });
      })
      .catch((error) => {});
  }, [globalLangCode]);

  const action = new URLSearchParams(props.location.search).get("action"); // for opening signup page & saving redirect url
  if (action === "signup") {
    useEffect(() => {
      setEmailIdSignup(props.match.params.email_id);
      localStorage.setItem(
        "signup_redirect",
        `/contentdetails/${props.match.params.data}`
      );
      signupPage();
    }, [countriesListSignup]);
  }

  const redirectFromLogin = (user) => {
    if (bFromExternalLink) {
      if (redirect_type == 1) {
        //KYC link
        console.log("redirect_type == 1");
        switch (parseInt(for_user_type_id)) {
          case 10: //Become a Creator
            props.history.push("/sellerKYC");
            break;
          case 3: //Become a Premium user
            props.history.push("/managesubscriptions/" + 3); //3 for premium user
            break;
        }
      } else if (redirect_type == 2) {
        //content details with quiz_deck_id
        console.log("redirect_type == 2");
        props.history.push("/contentdetails/" + quiz_deck_id); //3 for premium user
      }
    } else {
      //setUserGlobalLanguage(response.data.token, defaultLang)
      const signup_redirect = localStorage.getItem("signup_redirect");

      console.log(user.is_first_time_login === 1, user.is_first_time_login);
      if (signup_redirect) {
        localStorage.removeItem("signup_redirect");
        props.history.push(signup_redirect);
      } else if (user.is_first_time_login === 1) {
        props.history.push("/updateprofile");
      } else {
        props.history.push("/home");
      }
    }
  };

  useEffect(() => {
    setDeviceUUID(getDeviceUUID());
  }, []);

  const selectedCountry = (id) => {
    for (let i = 0; i < countriesListSignup.length; i++) {
      if (countriesListSignup[i].id == id) {
        setDialCodeSignup(countriesListSignup[i].dial_code);
        setCountryCodeSignup(countriesListSignup[i].id);
        return;
      }
    }
  };

  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState({});

  useEffect(() => {
    updateMetaTags();
    console.log("useEffect called");
    axios({
      method: "get",
      url: "/api/language/list",
      headers: {
        locale: globalLangCode,
      },
    })
      .then((response) => {
        console.log("languages", response.data.data);
        response.data.data.forEach((languageObj) => {
          console.log("i18next.language", i18next.language);
          if (languageObj.lang_code === i18next.language) {
            console.log("here");
            //setUserLoginLanguage(languageObj)
            setCookieLoginLanguage(languageObj);
            setLanguage(languageObj);
          }
        });
        setLanguages(response.data.data);
        const loginForm = document.getElementById("login-form");
        const signupForm = document.getElementById("signup-form");
        const languageForm = document.getElementById("language-form");
        if (signupForm.style.display == "block") {
          languageForm.style.display = "none";
          loginForm.style.display = "none";
          signupForm.style.display = "block";
        } else {
          languageForm.style.display = "none";
          loginForm.style.display = "block";
          signupForm.style.display = "none";
        }
      })
      .catch((error) => {});
  }, [refreshLoginHeader]);

  useEffect(() => {
    console.log("Changing languages");
  }, [languages]);

  const updateMetaTags = () => {
    // Update title
    document.title = t("meta_title");

    // Update meta tags
    const metaTags = [
      { name: "description", content: t("meta_description") },
      { name: "keywords", content: t("meta_keywords") },
      { property: "og:title", content: t("meta_ogTitle") },
      { property: "og:description", content: t("meta_ogDescription") },
    ];

    metaTags.forEach((meta) => {
      let element = document.querySelector(
        `meta[${meta.name ? "name" : "property"}="${
          meta.name || meta.property
        }"]`
      );
      if (!element) {
        element = document.createElement("meta");
        if (meta.name) {
          element.setAttribute("name", meta.name);
        } else {
          element.setAttribute("property", meta.property);
        }
        document.head.appendChild(element);
      }
      element.setAttribute("content", meta.content);
    });
  };
  const handelLanguageChange = (language) => {
    console.log(language);

    //setUserLoginLanguage(language)

    //Do not set the cookie login_language here,
    //since already in LanguageComponent.

    setLanguage(language);
    const languageForm = document.getElementById("language-form");
    const loginForm = document.getElementById("login-form");
    const signupForm = document.getElementById("signup-form");
    languageForm.style.display = "none";
    signupForm.style.display = "block";
    loginForm.style.display = "none";
    ToggleRefreshLoginHeader((refreshLoginHeader) => !refreshLoginHeader);
    //window.location.reload()
    updateMetaTags();
  };

  useEffect(() => {
    axios({
      method: "get",
      url: "/api/countries/list",
      headers: {
        locale: globalLangCode,
      },
    })
      .then((response) => {
        console.log("countries", response);
        setCountriesListSignup([]);
        setCountriesListSignup((country) => country.concat(response.data.data));
        //setDialCodeSignup(response.data.data[0].dial_code)
        //setCountryCodeSignup(response.data.data[0].id)
      })
      .catch((error) => {
        //   removeUserSession();
      });
  }, [languages]);

  useEffect(() => {
    //console.log('globalLangCode====',globalLangCode)
    axios({
      method: "get",
      url: "/api/termsandconditions/get",
      headers: {
        // 'Content-Type' : 'application/x-www-form-urlencoded',
        locale: globalLangCode,
      },
    })
      .then((response) => {
        //console.log("terms", response.data.data)
        setTermsAndConditions(response.data.data[0].description);
        // setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
      });

    axios({
      method: "get",
      url: "/api/privacypolicy/get",
      headers: {
        // 'Content-Type' : 'application/x-www-form-urlencoded',
        locale: globalLangCode,
      },
    })
      .then((response) => {
        //console.log("privacy policy", response.data.data)
        setPrivacyPolicy(response.data.data[0].description);
        // setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
      });
  }, [globalLang]);

  const handleSignUp = () => {
    console.log("JSON.parse(defaultLang).id", defaultLang.id);

    const termsCheck = document.getElementById("customCheck1");
    if (termsCheck.checked) {
      if (passwordSignup === confirmPasswordSignup) {
        axios({
          method: "post",
          url: "/api/user/register",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: qs.stringify({
            name: userNameSignup,
            //first_name: userNameSignup,
            //last_name: userLastNameSignup,
            dial_code: dialCodeSignup,
            country_id: countryCodeSignup,
            //phone_no:phoneNumberSignup,
            email_id: emailIdSignup,
            password: passwordSignup,
            fcm_id: "123",
            device_id: deviceUUID, //"1",
            device_type: "2",
            default_language_id: defaultLang.id,
          }),
          /*
                ,
                    referred_by: referalCodeSignup
                */
        })
          .then((response) => {
            console.log("response after signing up", response);

            props.history.push({
              pathname: "/verifyemail",
              state: {
                msg: response.data.msg,
                msg2: response.data.msg2,
              },
            });
            //alert(response.data.msg)

            setTokenAfterSigningUp(response.data.token);
          })
          .catch((error) => {
            showErrorMessageToUser(error.response.data[0].msg);
          });
      } else {
        showErrorMessageToUser("Password Does not match");
      }
    } else {
      showErrorMessageToUser("Please agree to the terms and conditions");
    }
  };

  useEffect(() => {
    if (tokenAfterSigningUp) {
      loginPage();
    }
  }, [tokenAfterSigningUp]);

  // handle button click of login form
  const handleLogin = () => {
    setError(false);
    setLoading(true);
    axios({
      method: "post",
      //url: 'http://localhost:8000/api/user/login',
      url: "/api/user/login",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        locale: globalLangCode,
      },
      data: qs.stringify({
        email_id: emailId.value,
        password: password.value,
        fcm_id: "123",
        device_id: deviceUUID, //"1",
        device_type: "2",
      }),
    })
      .then((response) => {
        console.log("response from login api", response);

        setLoading(false);
        setCookiesOnLogin(
          response.data.token,
          response.data.user,
          response.data.token_expires_in
        );

        //setUserSession(response.data.token, response.data.user);
        //setUserGlobalLanguage(response.data.token, defaultLang)
        redirectFromLogin(response.data.user_info);
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  const setCookieLoginLanguage = (loginLanguage) => {
    let exp = 30 * 24 * 60 * 60;
    var expires = new Date();
    expires.setTime(expires.getTime() + exp * 1000);
    setCookie("login_language", JSON.stringify(loginLanguage), {
      path: "/",
      expires,
    });
  };
  const setCookieGlobalLanguage = (globalLanguage) => {
    let exp = 30 * 24 * 60 * 60;
    console.log("in setCookieGlobalLanguage", exp);
    var expires = new Date();
    expires.setTime(expires.getTime() + exp * 1000);
    setCookie("global_language", JSON.stringify(globalLanguage), {
      path: "/",
      expires,
    });
  };

  const setCookiesOnLogin = (token, user, tok_expires_in) => {
    var expires = new Date();
    expires.setTime(expires.getTime() + tok_expires_in * 1000);

    process.env.REACT_APP_SERVER_PORT == 443
      ? setCookie("access_token", token, { path: "/", expires, secure: true })
      : setCookie("access_token", token, { path: "/", expires });
    setCookie("user", JSON.stringify(user), { path: "/", expires });
    setCookie("expiry_time", expires.toUTCString(), { path: "/", expires });
  };

  const showPassword = () => {
    if (type === "password") {
      setType("text");
    } else if (type === "text") {
      setType("password");
    }
  };

  function useFormInput(initialValue) {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
      setValue(e.target.value);
    };
    return {
      value,
      onChange: handleChange,
    };
  }

  const forgotPasswordSubmission = () => {
    axios({
      method: "post",
      url: "/api/user/forget/password",
      headers: {},
      data: qs.stringify({
        email_id: emailIdForForgetPassword,
      }),
    })
      .then((response) => {
        console.log("forget password response", response);
        showMessageToUser(response.data.msg);
        setUserId(response.data.data.id);
      })
      .catch((error) => {
        //   removeUserSession();
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  useEffect(() => {
    if (userId) {
      document.getElementById("otpModalOpen").click();
    }
  }, [userId]);

  const resendOTP = () => {
    axios({
      method: "post",
      url: "/api/user/resend/otp",
      headers: {},
      data: qs.stringify({
        user_id: userId,
      }),
    })
      .then((response) => {
        console.log("forget password response", response);
        showMessageToUser(response.data.msg);
      })
      .catch((error) => {
        //   removeUserSession();
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  const submitOTP = () => {
    axios({
      method: "post",
      url: "/api/user/verify/otp",
      headers: {},
      data: qs.stringify({
        user_id: userId,
        otp: otp,
      }),
    })
      .then((response) => {
        console.log("forget password response", response);
        showMessageToUser(response.data.msg);

        document.getElementById("dismissOTPModal").click();
        document.getElementById("resetPasswordButton").click();
      })
      .catch((error) => {
        //   removeUserSession();
        setUserId(null);
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  const submitNewResetPassword = () => {
    axios({
      method: "post",
      url: "/api/user/reset/forget/password",
      headers: {},
      data: qs.stringify({
        user_id: userId,
        otp: otp,
        new_password: resetPassword,
      }),
    })
      .then((response) => {
        console.log("password changed", response);

        showMessageToUser(response.data.msg);
      })
      .catch((error) => {
        //   removeUserSession();
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  const signupPage = () => {
    //language-form
    const languageForm = document.getElementById("language-form");
    const loginForm = document.getElementById("login-form");
    const signupForm = document.getElementById("signup-form");
    //languageForm.style.display = "block"
    loginForm.style.display = "none";
    signupForm.style.display = "block";
  };

  const loginPage = () => {
    const signupForm = document.getElementById("signup-form");
    const loginForm = document.getElementById("login-form");
    signupForm.style.display = "none";
    loginForm.style.display = "block";
  };

  const twitterLogIn = () => {
    var provider = new firebase.auth.TwitterAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
        // You can use these server side with your app's credentials to access the Twitter API.
        var token = result.credential.accessToken;
        var secret = result.credential.secret;
        // The signed-in user info.
        var user = result.user;
        console.log(user);
        const email_from_user = user.email;
        const name_from_user = user.displayName;
        const uid_from_user = user.uid;

        axios({
          method: "post",
          url: "/api/user/social-login",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: qs.stringify({
            email_id: email_from_user,
            name: name_from_user,
            auth_type: "twitter",
            social_id: uid_from_user,
            fcm_id: "123",
            device_id: deviceUUID, //"1",
            device_type: "1",
            default_language_id: "1",
          }),
        })
          .then((response) => {
            console.log("social login response", response);
            showMessageToUser(response.data.msg);
            setCookiesOnLogin(
              response.data.token,
              response.data.user,
              response.data.token_expires_in
            );
            //setUserSession(response.data.token, response.data.user);
            //setUserGlobalLanguage(response.data.token, defaultLang)
            redirectFromLogin();
          })
          .catch((error) => {
            // removeUserSession();
            showErrorMessageToUser(error.response.data[0].msg);
          });
        // ...
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        showErrorMessageToUser(errorMessage);
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
  };

  const googlepluslogin = () => {
    var provider = new firebase.auth.GoogleAuthProvider();

    provider.addScope("https://www.googleapis.com/auth/plus.me");
    provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
    provider.addScope("https://www.googleapis.com/auth/userinfo.email");

    //provider.addScope('profile')
    //provider.addScope('email')
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
        // You can use these server side with your app's credentials to access the Twitter API.
        var token = result.credential.accessToken;
        var secret = result.credential.secret;
        // The signed-in user info.
        var user = result.user;
        console.log("googlepluslogin auth user", user);
        /*
            const email_from_user = user.email
            const name_from_user = user.displayName
            const uid_from_user = user.uid
            */
        const email_from_user = user.providerData[0].email;
        const name_from_user = user.providerData[0].displayName;
        const uid_from_user = user.providerData[0].uid;

        axios({
          method: "post",
          url: "/api/user/social-login",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: qs.stringify({
            email_id: email_from_user,
            name: name_from_user,
            auth_type: "google",
            social_id: uid_from_user,
            fcm_id: "123",
            device_id: deviceUUID, //"1",
            device_type: "1",
            default_language_id: "1",
          }),
        })
          .then((response) => {
            console.log("social login response", response);
            showMessageToUser(response.data.msg);
            setCookiesOnLogin(
              response.data.token,
              response.data.user,
              response.data.token_expires_in
            );
            //setUserSession(response.data.token, response.data.user);
            //setUserGlobalLanguage(response.data.token, defaultLang)
            redirectFromLogin();
          })
          .catch((error) => {
            // removeUserSession();
            showErrorMessageToUser(error.response.data[0].msg);
          });
        // ...
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        showErrorMessageToUser(errorMessage);
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
  };

  //----------------- Facebook 2.6 login-------------------------
  // Facebook

  window.fbAsyncInit = function () {
    window["FB"].init({
      appId: "4020616848047773", //'1103684713695314'
      cookie: true,
      xfbml: true,
      version: "v2.8",
    });
  };

  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");

  const facebooklogin = () => {
    window["FB"].login(checkLoginState, {
      scope: "public_profile,email",
      return_scopes: true,
    });
    /*
        window['FB'].getLoginStatus(function(response) {   // Called after the JS SDK has been initialized.
            statusChangeCallback(response);        // Returns the login status.
        });
        */
  };

  /*
    function statusChangeCallback(response) {  // Called with the results from FB.getLoginStatus().
        console.log('statusChangeCallback');
        console.log(response);                   // The current login status of the person.
        if (response.status === 'connected') {   // Logged into your webpage and Facebook.
          testAPI();  
        } else {                                 // Not logged into your webpage or we are unable to tell.
        
        }
      }
      */
  function isUserEqual(facebookAuthResponse, firebaseUser) {
    if (firebaseUser) {
      var providerData = firebaseUser.providerData;
      for (var i = 0; i < providerData.length; i++) {
        if (
          providerData[i].providerId ===
            firebase.auth.FacebookAuthProvider.PROVIDER_ID &&
          providerData[i].uid === facebookAuthResponse.userID
        ) {
          // We don't need to re-auth the Firebase connection.
          return true;
        }
      }
    }
    return false;
  }

  function checkLoginState(response) {
    if (response.authResponse) {
      console.log(
        "FB response.authResponse",
        JSON.stringify(response.authResponse)
      );

      // User is signed-in Facebook.
      var unsubscribe = firebase.auth().onAuthStateChanged((firebaseUser) => {
        unsubscribe();
        let userID = response.authResponse.userID;
        // Check if we are already signed-in Firebase with the correct user.
        if (!isUserEqual(response.authResponse, firebaseUser)) {
          // Build Firebase credential with the Facebook auth token.
          var credential = firebase.auth.FacebookAuthProvider.credential(
            response.authResponse.accessToken
          );

          // Sign in with the credential from the Facebook user.
          firebase
            .auth()
            .signInWithCredential(credential)
            .catch((error) => {
              // Handle Errors here.
              var errorCode = error.code;
              var errorMessage = error.message;
              // The email of the user's account used.
              var email = error.email;
              // The firebase.auth.AuthCredential type that was used.
              var credential = error.credential;
              // ...
            });

          let email_from_user;
          let name_from_user;
          window["FB"].api(
            "/me?fields=id,email,first_name,last_name",
            function (response) {
              console.log("FB.me response" + JSON.stringify(response));
              email_from_user = response.email;
              name_from_user = response.first_name + " " + response.last_name;
              axios({
                method: "post",
                url: "/api/user/social-login",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                data: qs.stringify({
                  email_id: email_from_user,
                  name: name_from_user,
                  auth_type: "facebook",
                  social_id: userID,
                  fcm_id: "123",
                  device_id: deviceUUID, //"1",
                  device_type: "1",
                  default_language_id: "1",
                }),
              })
                .then((response) => {
                  console.log("social login response", response);
                  showMessageToUser(response.data.msg);
                  setCookiesOnLogin(
                    response.data.token,
                    response.data.user,
                    response.data.token_expires_in
                  );
                  //setUserSession(response.data.token, response.data.user);
                  //setUserGlobalLanguage(response.data.token, defaultLang)
                  redirectFromLogin();
                })
                .catch((error) => {
                  // removeUserSession();
                  showErrorMessageToUser(error.response.data[0].msg);
                });
            }
          );
        } else {
          // User is already signed-in Firebase with the correct user.
        }
      });
    } else {
      // User is signed-out of Facebook.
      firebase.auth().signOut();
    }
  }

  /*
      const facebooklogin = () => {

        var provider = new firebase.auth.FacebookAuthProvider();

        firebase.auth().signInWithPopup(provider).then(function(result) {
            // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
            // You can use these server side with your app's credentials to access the Twitter API.
            var token = result.credential.accessToken;
            // The signed-in user info.
            var user = result.user;
            console.log('facebooklogin auth user',user)
            let email_from_user;
            let name_from_user;
            let uid_from_user;
            if(user.email !== null){
                 email_from_user = user.email
                 name_from_user = user.displayName
                 uid_from_user = user.uid
            }else{
                 email_from_user = user.providerData[0].email
                 name_from_user = user.providerData[0].displayName
                 uid_from_user = user.uid
            }
            


            axios({
                method: 'post',
                url: '/api/user/social-login',
                headers:{
                    'Content-Type' : 'application/x-www-form-urlencoded',
                },
                data: qs.stringify({
                    email_id: email_from_user,
                    name: name_from_user,
                    auth_type:"facebook",
                    social_id:uid_from_user,
                    fcm_id: "123",
                    device_id: "1",
                    device_type: "1",
                    default_language_id:'1'
                })
            })
            .then(response => {
            console.log("social login response", response)
            showMessageToUser(response.data.msg)
            setUserSession(response.data.token, response.data.user);
            setUserGlobalLanguage(response.data.token, defaultLang)
            redirectFromLogin();
            }).catch(error => {
            // removeUserSession();
            showErrorMessageToUser(error.response.data[0].msg)
            });
            // ...
          }).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            console.log(error)
            var errorMessage = error.message;
            showErrorMessageToUser(errorMessage)
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
          });
        
      };
      */

  const linkedInLogIn = () => {
    axios({
      method: "get",
      url: "https://cors-anywhere.herokuapp.com/https://www.linkedin.com/oauth/v2/authorization",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      params: {
        // grant_type: 'authorization_code',
        response_type: "code",
        // code: 'AQSow7V6s2F2koWzIsnVKcQGt_cHtsM1F3FHZOrEV0UY1KIFkWiFJpi8dt1NtjuZMOO6-NStoCjTf58awk6GBcH2XQRctt7IBtel4Oeop5yVIBqiedk8qxlIlbkMxlfGg1gCVoupXL6xUc3-jegKYDPSe0rl4mygdpIzGdej2_hhJ827vJcojtvaMXCCGw',
        // redirect_uri: 'https://com.memory.linkedin.oauth/oauth',

        redirect_uri: "https://memorizeitall.com.us.auth0.com/login/callback",
        client_id: "867287ffa5ii9t",
        scope: "r_emailaddress",
        // client_secret: 'fGGgdqqcx5t3cRfw'
      },
    })
      .then((response) => {
        console.log("social login response", response);
        console.log("social login response", response.request.responseURL);
        redirectFromLogin();

        // showMessageToUser(response.data.msg)
        // setUserSession(response.data.token, response.data.user);
        // props.history.push('/dashboard');
      })
      .catch((error) => {
        // removeUserSession();
        console.log(error.response);
      });
    // ...
  };

  const lineLogIn = () => {
    axios({
      method: "get",
      url: "https://cors-anywhere.herokuapp.com/https://access.line.me/oauth2/v2.1/authorize",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      params: {
        // grant_type: 'authorization_code',
        response_type: "code",
        // code: 'AQSow7V6s2F2koWzIsnVKcQGt_cHtsM1F3FHZOrEV0UY1KIFkWiFJpi8dt1NtjuZMOO6-NStoCjTf58awk6GBcH2XQRctt7IBtel4Oeop5yVIBqiedk8qxlIlbkMxlfGg1gCVoupXL6xUc3-jegKYDPSe0rl4mygdpIzGdej2_hhJ827vJcojtvaMXCCGw',
        // redirect_uri: 'https://com.memory.linkedin.oauth/oauth',

        redirect_uri:
          "https://memorizeitall-ba135.firebaseapp.com/__/auth/handler",
        client_id: "1653833202",
        scope: "openid%20email",
        state: "1a2b3c4d",
        // client_secret: 'fGGgdqqcx5t3cRfw'
      },
    })
      .then((response) => {
        console.log("social login response", response);
        console.log("social login response", response.request.responseURL);

        redirectFromLogin();

        // showMessageToUser(response.data.msg)
        // setUserSession(response.data.token, response.data.user);
        // props.history.push('/dashboard');
      })
      .catch((error) => {
        // removeUserSession();
        console.log(error.response);
      });
    // ...
  };

  const handleLinkedInSuccess = (data) => {
    console.log("success");
    console.log(data.code);
    axios({
      method: "post",
      url: "https://cors-anywhere.herokuapp.com/https://www.linkedin.com/oauth/v2/accessToken",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify({
        grant_type: "authorization_code",
        code: data.code,
        redirect_uri: `${window.location.origin}/signin-linkedin`,
        client_id: "867287ffa5ii9t",
        client_secret: "Fu9yE7B5h7bkn5KA",
      }),
    })
      .then((response) => {
        console.log("linkedin response", response);
        const access_token = response.data.access_token;
        console.log(access_token);
        axios({
          method: "get",
          url: "https://cors-anywhere.herokuapp.com/https://api.linkedin.com/v2/me",
          headers: {
            Authorization: `Bearer ${access_token}`,
            "cache-control": "no-cache",
          },
        })
          .then((response) => {
            console.log("linkedin response", response);
            console.log(response.data);

            const urn = response.data.id;
            const name = response.data.localizedFirstName;

            axios({
              method: "get",
              url: "https://cors-anywhere.herokuapp.com/https://api.linkedin.com/v2/emailAddress?q=members&projection=(elements*(handle~))",
              headers: {
                Authorization: `Bearer ${access_token}`,
                handle: urn,
                "cache-control": "no-cache",
              },
            })
              .then((response) => {
                console.log("linkedin response", response);
                console.log(response.data);

                const email_id =
                  response.data.elements[0]["handle~"].emailAddress;

                axios({
                  method: "post",
                  url: "/api/user/social-login",
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                  data: qs.stringify({
                    email_id: email_id,
                    name: name,
                    auth_type: "linkedin",
                    social_id: urn,
                    fcm_id: "123",
                    device_id: deviceUUID, //"1",
                    device_type: "1",
                    default_language_id: "1",
                  }),
                })
                  .then((response) => {
                    console.log("social login response", response);
                    showMessageToUser(response.data.msg);
                    setCookiesOnLogin(
                      response.data.token,
                      response.data.user,
                      response.data.token_expires_in
                    );
                    //setUserSession(response.data.token, response.data.user);
                    //setUserGlobalLanguage(response.data.token, defaultLang)
                    redirectFromLogin();
                  })
                  .catch((error) => {
                    // removeUserSession();
                    showErrorMessageToUser(error.response.data[0].msg);
                  });
              })
              .catch((error) => {
                console.log(error.response);
              });
          })
          .catch((error) => {
            console.log(error.response);
          });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleLinkedInFailure = (data) => {
    console.log(data);
  };

  return (
    <React.Fragment>
      <LoginHeader refresh={refreshLoginHeader} />

      <ToastContainer />

      <div className="login-form-control">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-9">
              <div id="login-form" style={{ display: "none" }}>
                <div class="row row-eq-height">
                  <div class="col-sm-12 col-md-12 col-lg-5">
                    <div class="same-height login-box animated fadeInLeft">
                      <div class="login-box-logo">
                        <img src={dashlogo} />
                      </div>
                      <h4>{t("Welcome")}</h4>
                      <div class="input-field left-icon">
                        <span class="icons left icon-user"></span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("usernamepassword")}
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          {...emailId}
                          autoComplete="new-password"
                        />
                      </div>
                      <div class="input-field left-icon">
                        <span class="icons left icon-password"></span>
                        <input
                          type={type}
                          className="form-control"
                          placeholder={t("Password")}
                          aria-label="Amount (to the nearest dollar)"
                          {...password}
                          autoComplete="new-password"
                        />
                        <a
                          href="javascript:void(0)"
                          onClick={showPassword}
                          className="show-password icons right"
                          toggle="#password-field1"
                        >
                          <span className="icon-eye"></span>
                        </a>
                      </div>
                      <div className="forgot-password-link">
                        <a
                          data-toggle="modal"
                          data-target="#forgetPasswordModal"
                          href="javascript:void(0)"
                        >
                          {t("forgot_your_password")}
                        </a>
                      </div>
                      <div class="login-btn">
                        <a
                          href="javascript:void(0)"
                          className="blue-bg-btn"
                          onClick={handleLogin}
                        >
                          {t("login")}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-2 or-login-via animated fadeInDown">
                    {/* 
                                <div class="same-height">
                                    <div class="txt">{t("or_login_via")}</div>
                                </div>
                                */}
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-5">
                    <div class="same-height social-link-option animated fadeInRight">
                      {/* <div class="social-links">
                        <ul>
                          <li onClick={() => facebooklogin()}>
                            <a href="javascript:void(0)" class="facebook">
                              <span class="icon-facebook"></span>
                            </a>
                          </li>
                          <li onClick={() => googlepluslogin()}>
                            <a href="javascript:void(0)" class="google-plus">
                              <span class="icon-google-plus"></span>
                            </a>
                          </li>
                          <li onClick={() => twitterLogIn()}>
                            <a href="javascript:void(0)" class="twitter">
                              <span class="icon-twitter"></span>
                            </a>
                          </li>

                          <li onClick={() => lineLogIn()}>
                            <a href="javascript:void(0)" class="line">
                              <span class="icon-line"></span>
                            </a>
                          </li>

                          <li><a href="javascript:void(0)" class="linkedin"><span class="icon-linkedin"></span></a></li>

                          <li>
                            <LinkedIn
                              clientId="867287ffa5ii9t"
                              // response_type ='code'
                              onFailure={handleLinkedInFailure}
                              onSuccess={handleLinkedInSuccess}
                              redirectUri={`${window.location.origin}/signin-linkedin`} 
                              scope="r_emailaddress r_liteprofile w_member_social"
                              redirectPath="/signin-linkedin" //'/linkedin'
                              state="14789632"
                              className="Btnlinkedin"
                            >
                              <a href="javascript:void(0)" class="linkedin">
                                <span class="icon-linkedin"></span>
                              </a>
                            </LinkedIn>
                          </li>
                        </ul>
                      </div> */}
                      <div class="right-btns">
                        <div class="link">
                          {/*<a href="javascript:void(0)">{t('dont_have_an_account')}</a>*/}
                          <label>{t("dont_have_an_account")}</label>
                        </div>
                        <div class="sign-up-btn">
                          <a
                            href="javascript:void(0)"
                            id="signin_btn"
                            onClick={() => signupPage()}
                          >
                            {t("SignUp")}
                          </a>
                        </div>
                        {/* <div class="link">
                                            <a href="javascript:void(0)">Not ready yet? continue as a guest</a>
                                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="language-form" style={{ display: "none" }}>
                <div class="row row-eq-height justify-content-center">
                  <div class="col-sm-12 col-md-6 col-lg-5">
                    <div class="same-height login-box animated fadeInLeft text-center">
                      <div class="login-box-logo">
                        <img src={languagelogo} />
                      </div>
                      <p>{t("please_select_lang")}</p>
                      <div className="language">
                        <div className="dropdown">
                          <a
                            href="javascript:void(0)"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            aria-expanded="true"
                          >
                            {language ? (
                              <React.Fragment>
                                <img
                                  height="30%"
                                  width="30%"
                                  src={COUNTRY_FLAG + language.flag}
                                  alt="Img"
                                ></img>{" "}
                                <span>{language.name}</span>
                              </React.Fragment>
                            ) : null}
                          </a>
                          <LanguagesComponent
                            languages={languages}
                            onSelectLanguage={handelLanguageChange}
                          />
                        </div>
                      </div>
                      <h4>
                        Memorize<span>it</span>all
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              <div id="signup-form" style={{ display: "none" }}>
                <div class="row row-eq-height">
                  <div class="col-sm-12 col-md-12 col-lg-5">
                    <div class="same-height login-box animated fadeInLeft">
                      <div class="login-box-logo">
                        <img src={dashlogo} />
                      </div>
                      <h4>{t("SignUpHeading")}</h4>
                      <div class="input-field left-icon">
                        <span class="icons left icon-user"></span>
                        <input
                          type="text"
                          placeholder={t("namee")}
                          value={userNameSignup}
                          onChange={(e) => setUserNameSignup(e.target.value)}
                        />
                      </div>
                      {/* 
                                    <div class="input-field left-icon">
                                        <span class="icons left icon-user"></span>
                                        <input type="text" placeholder={t("first_name")}
                                            value={userNameSignup} onChange={(e) => setUserNameSignup(e.target.value)}
                                        />
                                    </div>
                                    <div class="input-field left-icon">
                                        <span class="icons left icon-user"></span>
                                        <input type="text" placeholder={t("last_name")}
                                            value={userLastNameSignup} onChange={(e) => setUserLastNameSignup(e.target.value)}
                                        />
                                    </div>
                                    */}
                      <div class="input-field left-icon">
                        <span class="icons left icon-email1"></span>
                        <input
                          type="text"
                          placeholder={t("email")}
                          value={emailIdSignup}
                          onChange={(e) => setEmailIdSignup(e.target.value)}
                        />
                      </div>

                      <div class="input-field left-icon">
                        <span class="icons left icon-phone1"></span>
                        <div class="phone-number">
                          <div class="dial-code">
                            <select
                              id="countriesListID"
                              onChange={(e) => selectedCountry(e.target.value)}
                            >
                              {countriesListSignup.length > 0
                                ? (function star() {
                                    let opts = [];
                                    countriesListSignup.map((country) => {
                                      if (country.id == countryCodeSignup) {
                                        opts.push(
                                          <option value={country.id} selected>
                                            +{country.dial_code} {country.name}
                                          </option>
                                        );
                                      } else {
                                        opts.push(
                                          <option value={country.id}>
                                            +{country.dial_code} {country.name}
                                          </option>
                                        );
                                      }
                                    });
                                    opts.push(
                                      <option
                                        value="none"
                                        selected
                                        disabled
                                        hidden
                                      >
                                        {t("select_country_code")}
                                      </option>
                                    );
                                    return opts;
                                  })()
                                : null}
                            </select>
                          </div>
                        </div>
                      </div>
                      {/* 
                                    <div class="input-field left-icon">
                                        <span class="icons left icon-phone1"></span>
                                        <input type="text" placeholder={t("mobile_no")}
                                            value={phoneNumberSignup} onChange={(e) => setPhoneNumberSignup(e.target.value)}/>
                                    </div>
                                    */}
                      <div class="input-field left-icon">
                        <span class="icons left icon-password"></span>
                        <input
                          type={type}
                          placeholder={t("Password")}
                          value={passwordSignup}
                          onChange={(e) => setPasswordSignup(e.target.value)}
                        />
                      </div>
                      <div class="input-field left-icon">
                        <span class="icons left icon-password"></span>
                        <input
                          type={type}
                          placeholder={t("confirm_password")}
                          value={confirmPasswordSignup}
                          onChange={(e) =>
                            setConfirmPasswordSignup(e.target.value)
                          }
                        />
                      </div>
                      {/* <div class="input-field left-icon">
                                        <span class="icons left icon-referral"></span>
                                        <input type="text" placeholder={t("enter_your_referral_code")}
                                            value={referalCodeSignup} onChange={(e) => setReferalCodeSignup(e.target.value)}
                                        />
                                    </div> */}
                      <div class="conditions">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck1"
                          />
                          <label
                            class="custom-control-label"
                            for="customCheck1"
                          >
                            {t("by_signing_up")}{" "}
                            <a
                              data-toggle="modal"
                              data-target="#termsconditions"
                              href="javascript:void(0)"
                            >
                              {t("terms_and_conditions")}{" "}
                            </a>{" "}
                            {t("and")}{" "}
                            <a
                              data-toggle="modal"
                              data-target="#privacyconditions"
                              href="javascript:void(0)"
                            >
                              {t("privacy_policy")}
                            </a>{" "}
                            {t(".")}
                          </label>
                        </div>
                      </div>
                      <div class="login-btn">
                        <a
                          onClick={() => handleSignUp()}
                          className="blue-bg-btn"
                          href="javascript:void(0)"
                        >
                          {t("signup_button")}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-2 or-login-via animated fadeInDown">
                    <div class="same-height"></div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-5">
                    <div class="same-height social-link-option animated fadeInRight">
                      {/*
                                    <div class="social-links">
                                        <ul>
                                            <li><a href="javascript:void(0)" class="facebook"><span class="icon-facebook"></span></a></li>
                                            <li><a href="javascript:void(0)" class="google-plus"><span class="icon-google-plus"></span></a></li>
                                            <li><a href="javascript:void(0)" class="twitter"><span class="icon-twitter"></span></a></li>
                                            <li><a href="javascript:void(0)" class="line"><span class="icon-line"></span></a></li>
                                            <li><a href="javascript:void(0)" class="linkedin"><span class="icon-linkedin"></span></a></li>
                                        </ul>
                                    </div>
                                     */}
                      <div class="right-btns">
                        <div class="link">
                          <a href="javascript:void(0)">
                            {t("already_have_an_account")}
                          </a>
                        </div>
                        <div class="sign-up-btn">
                          <a
                            onClick={() => loginPage()}
                            href="javascript:void(0)"
                            id="login_btn"
                          >
                            {t("login")}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="forgot-password-link" style={{ display: "none" }}>
        <a
          id="otpModalOpen"
          data-toggle="modal"
          data-target="#otpSubmission"
          href="javascript:void(0)"
        >
          OTP Modal open
        </a>
      </div>

      <div className="forgot-password-link" style={{ display: "none" }}>
        <a
          id="resetPasswordButton"
          data-toggle="modal"
          data-target="#passwordReset"
          href="javascript:void(0)"
        >
          OTP Modal open
        </a>
      </div>

      <div id="forgetPasswordModal" class="modal fade" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">{t("forget")}</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body">
              <form method="#">
                <div class="form-group">
                  <label>{t("type_your_email_id")}</label>
                  <input
                    value={emailIdForForgetPassword}
                    type="text"
                    name="folderName"
                    class="form-control"
                    onChange={(e) =>
                      setEmailIdForForgetPassword(e.target.value)
                    }
                  />
                  <br></br>
                  {/* <div
                        onClick={addFolder}
                        class="btn btn-primary btn_cst">Submit
                    </div> */}
                </div>
              </form>
            </div>
            <div
              class="modal-footer"
              onClick={() => forgotPasswordSubmission()}
            >
              <a
                href="javascript:void(0)"
                class="btn-background blue-bg-btn"
                data-dismiss="modal"
              >
                {">"}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="otpSubmission" class="modal fade" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">{t("OTP_submission")}</h4>
              <button
                id="dismissOTPModal"
                type="button"
                class="close"
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>
            <div class="modal-body">
              <form method="#">
                <div class="form-group">
                  <label>{t("type_your_OTP")}</label>
                  <input
                    value={otp}
                    type="text"
                    name="folderName"
                    class="form-control"
                    onChange={(e) => setOTP(e.target.value)}
                  />
                  <br></br>
                  {/* <div
                        onClick={resendOTP}
                        class="btn btn-primary btn_cst">Resend OTP
                    </div> */}
                  <div onClick={resendOTP}>
                    <a
                      href="javascript:void(0)"
                      class="btn-background blue-bg-btn"
                    >
                      {t("resend_OTP")}
                    </a>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer" onClick={() => submitOTP()}>
              <a
                href="javascript:void(0)"
                class="btn-background blue-bg-btn"
                data-dismiss="modal"
              >
                {">"}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="passwordReset" class="modal fade" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">{t("password_reset")}</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body">
              <form method="#">
                <div class="form-group">
                  <label>{t("enter_password")}</label>
                  <input
                    value={resetPassword}
                    type="text"
                    name="folderName"
                    class="form-control"
                    onChange={(e) => setResetPassword(e.target.value)}
                  />
                  <br></br>
                  {/* <div
                       onClick={() => submitNewResetPassword()}
                        class="btn btn-primary btn_cst">Submit
                    </div> */}
                </div>
              </form>
            </div>

            <div class="modal-footer" onClick={() => submitNewResetPassword()}>
              <a
                href="javascript:void(0)"
                class="btn-background blue-bg-btn"
                data-dismiss="modal"
              >
                Submit
              </a>
            </div>
          </div>
        </div>
      </div>

      {termsAndConditions ? (
        <div class="modal" id="termsconditions">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Terms and conditions</h4>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div class="modal-body">
                <div
                  class="terms-conditions"
                  dangerouslySetInnerHTML={{ __html: termsAndConditions }}
                ></div>
              </div>

              <div class="modal-footer">
                <a
                  href="javascript:void(0)"
                  class="btn-background blue-bg-btn"
                  data-dismiss="modal"
                >
                  OK
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {privacyPolicy ? (
        <div class="modal" id="privacyconditions">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Privacy policy</h4>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div class="modal-body">
                <div
                  class="terms-conditions"
                  dangerouslySetInnerHTML={{ __html: privacyPolicy }}
                ></div>
              </div>

              <div class="modal-footer">
                <a
                  href="javascript:void(0)"
                  class="btn-background blue-bg-btn"
                  data-dismiss="modal"
                >
                  OK
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <BasicFooter {...props} />
    </React.Fragment>
  );
}

export default Login;
