/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useEffect, useState } from "react";

import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import Pagination from "./Pagination";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const PAGE_LIMIT = 10;

function MyContentsUnpublished(props) {
  console.log("content props", props);
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const [quizzes, setQuizzes] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState();
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [total, setTotal] = useState(null);
  const [current, setCurrent] = useState(1);
  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";

  const token = cookies["access_token"]; //getToken();
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  useEffect(() => {
    setLoading(true);
    axios({
      method: "get",
      url: "/api/language/list",
      headers: {
        locale: globalLangCode,
      },
    })
      .then((response) => {
        console.log("languages", response.data.data);
        setLanguages(response.data.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (languages.length > 0) {
      let setCurrent = false;
      for (let i = 0; i < languages.length; i++) {
        if (globalLangId == languages[i].id) {
          setCurrentLanguage(languages[i]);
          setCurrent = true;
        }
      }
      if (!setCurrent) {
        setCurrentLanguage(languages[0]);
      }
    }
  }, [languages]);

  useEffect(() => {
    setLoading(true);
    if (currentLanguage) {
      console.log("current language code is", currentLanguage);
      fetchQuizzes();
    }
  }, [currentLanguage]);

  const fetchQuizzes = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/my/quizzes/list",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: currentLanguage.lang_code,
      },
      data: qs.stringify({
        page: currentPage.toString(),
        limit: PAGE_LIMIT.toString(),
        unpublished: "1",
      }),
    })
      .then((response) => {
        let quizzesList = response.data.data;
        setQuizzes(quizzesList);
        setTotalPages(response.data.total_pages);
        setTotal(response.data.total);
        setLoading(false);
        //setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
      });
  };

  const goToDashboardPage = () => {
    props.history.push("/myprofile");
  };

  const goToContentsEdit = (quizdeckid, primaryLanguageId, languages) => {
    // props.history.push("/contentsedit/"+quizdeckid)
    props.history.push({
      pathname: "/contentseditunpublished/" + quizdeckid,
      state: {
        ...props.location.state,
        primaryLanguageId: primaryLanguageId,
        languages: languages,
      },
    });
  };

  const goToContentsDetailsEdit = (
    quizdeckid,
    primaryLanguageId,
    languages
  ) => {
    // props.history.push("/contentsedit/"+quizdeckid)
    props.history.push({
      pathname: "/contentsdetailsedit/" + quizdeckid,
      state: {
        ...props.location.state,
        primaryLanguageId: primaryLanguageId,
        languages: languages,
      },
    });
  };

  const gotoAttributesPage = () => {
    props.history.push("/createcontent");
  };

  const onPageChanged = (data) => {
    setLoading(true);
    fetchQuizzes(data.currentPage);
    setCurrent(data.currentPage);
  };

  return (
    <React.Fragment>
      {loading === false ? (
        <div class="create-content-wrapper">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-12 col-md-10 col-lg-7">
                <div class="create-content-tabs animated fadeInRight">
                  <ul class="nav nav-tabs nav-justified">
                    {languages && currentLanguage
                      ? languages.map((language) => (
                          <li
                            class="nav-item"
                            onClick={() => setCurrentLanguage(language)}
                          >
                            <a
                              href=""
                              data-toggle="tab"
                              class={
                                language.id == currentLanguage.id
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                            >
                              <img src={COUNTRY_FLAG + language.flag} />
                              <span>{t(language.name)}</span>
                            </a>
                          </li>
                        ))
                      : null}
                  </ul>
                  {/* <!-- Tab panes --> */}
                  <div class="tab-content">
                    <div class="tab-pane container active" id="EnglishTab">
                      {/* <div  
                                        onClick={gotoAttributesPage}
                                    class="add-quiz-btn"><a href="javascript:void(0)"><span class="icon icon-plus"></span> <span>Add Content</span></a></div>
                                     */}
                      <div class="create-content-accordion">
                        <div id="accordion" class="accordion">
                          {quizzes && quizzes.length > 0
                            ? quizzes.map((object, i) => (
                                // return <ContentRow obj={object} key={i} index={i} />;
                                //    object.is_published === 0 ?
                                //    (
                                <div class="card">
                                  <div
                                    class="card-header"
                                    data-toggle="collapse"
                                    href={"#collapse" + i}
                                  >
                                    <div class="row">
                                      <div
                                        class="col-sm-9"
                                        dangerouslySetInnerHTML={
                                          object.name
                                            ? { __html: object.name }
                                            : null
                                        }
                                      ></div>
                                      {/* <div class="col-sm-3 question">{object.questionCount} question <span class="icon-next"></span></div> */}
                                    </div>
                                  </div>
                                  <div
                                    id={"collapse" + i}
                                    class="card-body collapse show"
                                    data-parent="#accordion"
                                  >
                                    <p
                                      dangerouslySetInnerHTML={
                                        object.description
                                          ? { __html: object.description }
                                          : null
                                      }
                                    ></p>
                                    <div class="text-center">
                                      <a
                                        onClick={() =>
                                          goToContentsEdit(
                                            object.id,
                                            object.language_id,
                                            object.languages
                                          )
                                        }
                                        href="javascript:void(0)"
                                        class="btn-background blue-bg-btn"
                                      >
                                        {t("edit_contents")}
                                      </a>
                                      {/* <a 
                                                                onClick={() => goToContentsDetailsEdit(object.id, object.language_id, object.languages)}
                                                                href="javascript:void(0)" class="btn-background">
                                                                    Edit content details
                                                            </a> */}
                                    </div>
                                  </div>
                                </div>
                                //    )
                                //    :
                                //    null
                              ))
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center pt-4">
                  {total > PAGE_LIMIT ? (
                    <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                      <Pagination
                        totalRecords={total}
                        totalPages={totalPages}
                        currentPage={current}
                        pageLimit={PAGE_LIMIT}
                        pageNeighbours={1}
                        onPageChanged={onPageChanged}
                      />
                    </div>
                  ) : null}
                  {/* <a href="javascript:void(0)" class="btn-border mr-2">Save as draft</a> */}
                  {/* <a href="javascript:void(0)" class="btn-background">Publish now</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          type="ThreeDots"
          color="#294177"
          height={100}
          width={100}
          // timeout={3000} //3 secs
        />
      )}
    </React.Fragment>
  );
}

export default MyContentsUnpublished;
