/* eslint-disable */
import axios from "axios";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";

import { useCookies } from "react-cookie";

function PurchaseCredits(props) {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode, globalLangId;
  if (!globalLang) {
    globalLangCode = i18next.language;
  } else {
    globalLangCode = globalLang.lang_code;
    globalLangId = globalLang.id;
  }

  const token = cookies["access_token"]; //getToken()

  const [plans, setPlans] = useState([]);

  useEffect(() => {
    console.log(token);
    axios({
      method: "post",
      url: "/api/credits/plans/list",
      headers: {
        // 'Content-Type' : 'application/x-www-form-urlencoded',
        //'x-access-token': "Memo "+token,
        locale: globalLangCode,
      },
    })
      .then((response) => {
        //   setUserSession(response.data.token, response.data.user);
        //   setUserSession(token, response.data.user);
        console.log("response", response.data.data);
        //   plans = response.data.data;
        setPlans((plans) => plans.concat(response.data.data));
        console.log("plans", plans);
      })
      .catch((error) => {
        //   removeUserSession();
      });
  }, []);

  const buyCreditsForThisPlan = async (id) => {
    console.log("buyCreditsForThisPlan");

    props.history.push({
      pathname: "/paymentgateway",
      state: { id: id, plantype: "CreditPlan" },
    });
  };

  const goToDashboard = () => {
    props.history.push("/myprofile");
  };

  return (
    <React.Fragment>
      {/* <BasicHeader 
            {...props}
        /> */}
      <div className="inner-header">
        <div className="container">
          <div className="row">
            <div className="col-sm-5 col-md-6 col-lg-6">
              <h2 className="animated fadeInLeft">
                {t("memorizeitallpurchasecredits")}
              </h2>
            </div>
            <div className="col-sm-7 col-md-6 col-lg-6">
              <div className="breadcrumb-item animated fadeInRight">
                <ul>
                  <li onClick={goToDashboard}>
                    <a href="javascript:void(0)">
                      <span className="icon-home1"></span>
                      {t("home")}
                    </a>
                  </li>
                  <li>
                    <span className="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" className="active">
                      {t("credits")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-credits-wrapper">
        <div className="container">
          <div className="purchase-credits-info">
            <div className="row">
              {/* <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="credits-available-box animated fadeInLeft">
                            <div className="credit-icon"><img src="images/my-credits/credit.png" alt="Credit Img" /></div>
                            <div className="credit-txt">
                                <div className="points">Firstfull of 30 Credits</div>
                            </div>
                            <div className="more-credits-btn">
                                <a href="javascript:void(0)" className="btn-background">Take for $15.00</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="credits-available-box animated fadeInLeft">
                            <div className="credit-icon"><img src="images/my-credits/credit.png" alt="Credit Img" /></div>
                            <div className="credit-txt">
                                <div className="points">Firstfull of 30 Credits</div>
                            </div>
                            <div className="more-credits-btn">
                                <a href="javascript:void(0)" className="btn-background">Take for $15.00</a>
                            </div>
                        </div>
                    </div> */}
            </div>
            <div className="center-box">
              <div className="row justify-content-center">
                <div className="col-md-12 col-lg-9">
                  <div className="row">
                    {plans.length > 0
                      ? plans.map((plan) => (
                          <div className="col-sm-12 col-md-4 col-lg-4">
                            <div className="credits-available-box animated fadeInRight">
                              <div className="credit-icon btn-block">
                                <span class="icon icon-dollar"></span>
                              </div>
                              <div className="credit-txt">
                                <div className="points">
                                  {plan.credits} {t("credits")}{" "}
                                </div>
                              </div>

                              <div
                                className="more-credits-btn"
                                onClick={() => buyCreditsForThisPlan(plan.id)}
                              >
                                <a
                                  href="javascript:void(0)"
                                  className="btn-background blue-bg-btn"
                                >
                                  {plan.currency_symbol} {plan.amount}
                                </a>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <a href="javascript:void(0)" className="signup-points">
                            <span className="icon"><span className="icon-diamond"></span></span>
                            <span>Sign Up for paid membership to get a lot of extra features!</span>
                        </a>
                    </div>
                </div> */}
          </div>
        </div>
      </div>
      {/* <LoginFooter /> */}
    </React.Fragment>
    // <div>
    //     {
    //         plans.length>0 ?
    //         (
    //             plans.map(plan => (
    //             <button
    //                 key={plan.id}
    //                 onClick={() => buyCreditsForThisPlan(plan.id, plan.amount, plan.currency)}>
    //                 {plan.plan_name} of {plan.credits} credits. Take for {plan.amount}$
    //             </button>
    //             ))
    //         )
    //         :
    //         null
    //     }
    // </div>
  );
}

export default PurchaseCredits;
