import { DeviceUUID } from "device-uuid";

// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

export const getDeviceUUID = () => {
  var du = new DeviceUUID().parse();
  var dua = [
    du.language,
    du.platform,
    du.os,
    du.cpuCores,
    du.isAuthoritative,
    du.silkAccelerated,
    du.isKindleFire,
    du.isDesktop,
    du.isMobile,
    du.isTablet,
    du.isWindows,
    du.isLinux,
    du.isLinux64,
    du.isMac,
    du.isiPad,
    du.isiPhone,
    du.isiPod,
    du.isSmartTV,
    du.pixelDepth,
    du.isTouchScreen,
  ];
  var uuid = du.hashMD5(dua.join(":"));
  return uuid;
};
// return the token from the session storage
export const getToken = () => {
  //console.log('token',localStorage.getItem('token'))
  return localStorage.getItem("token") || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("globalLanguage");
  localStorage.removeItem("loginLanguage");
};

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  // console.log(token)
  console.log("outer user" + JSON.stringify(user));
  localStorage.setItem("token", token);
  if (JSON.stringify(user) !== undefined) {
    console.log("setting user" + JSON.stringify(user));
    localStorage.setItem("user", JSON.stringify(user));
  }
  console.log(localStorage);
};

export const setUserGlobalLanguage = (token, language) => {
  localStorage.setItem("token", token);
  if (JSON.stringify(language) !== undefined) {
    console.log("setUserGlobalLanguage language", language);
    localStorage.setItem("globalLanguage", JSON.stringify(language));
  }
};

export const getUserGlobalLanguage = () => {
  return localStorage.getItem("globalLanguage") || null;
};

export const setUserLoginLanguage = (language) => {
  if (JSON.stringify(language) !== undefined) {
    console.log("language", language);
    localStorage.setItem("loginLanguage", JSON.stringify(language));
  }
};

export const getUserLoginLanguage = () => {
  return localStorage.getItem("loginLanguage") || null;
};
