/* eslint-disable */

import axios from "axios";
import React, { useEffect, useState } from "react";

import { useCookies } from "react-cookie";

import { useTranslation } from "react-i18next";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/dashboard.css";
import "../less/icon-font.css";
import "../less/releway-font.css";
import SideNavBar from "./SideNavBar";

import UserTypesAndPricingComponent from "./UserTypesAndPricingComponent";

function ManageSubscriptions(props) {
  console.log("props in ManageSubscriptions", props);
  //console.log(props.match.params.type)

  const user_type_id =
    props.match && props.match.params && props.match.params.type
      ? props.match.params.type
      : -1;
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const token = cookies["access_token"]; //getToken()
  const user = cookies["user"]; //getUser()

  console.log("user", user);
  console.log("user_type_id=" + user_type_id);
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  const [subscriptions, setSubscriptions] = useState([]);
  const [bSubscriptionHistryListRefresh, setSubscriptionHistryListRefresh] =
    useState(false);
  const [mySubscriptions, setMySubscriptions] = useState([]);

  useEffect(() => {
    console.log(token);
    axios({
      method: "get",
      url: "/api/subscriptions/history/list",
      headers: {
        locale: "en",
        "x-access-token": "Memo " + token,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        //setUserSession(token, response.data.user);
        console.log("subscriptions/history/list response", response.data);
        setMySubscriptions([]);
        setMySubscriptions(response.data.data);
        ////user.user_type_id == 3 (pro) or user.user_type_id == 10 (Creator),
        if (response.data.data.length > 0) {
          setSubscriptions([]);
          console.log("mySubscriptions", response.data.data);
        } else {
          axios({
            method: "get",
            url: "/api/subscriptions/plans/list",
            headers: {
              locale: globalLangCode,
              "Content-Type": "application/json",
            },
          })
            .then((response) => {
              console.log("response", response.data);
              setSubscriptions([]);
              setSubscriptions(response.data.data);
            })
            .catch((error) => {
              //   removeUserSession();
            });
        }

        //setSubscriptions(subscriptions => subscriptions.concat(response.data.data))
      })
      .catch((error) => {
        //removeUserSession();
      });
  }, [bSubscriptionHistryListRefresh]);

  useEffect(() => {
    console.log("subscriptions", subscriptions);
  }, [subscriptions]);

  const gotoMyCredits = () => {
    props.history.push("/mycredits");
  };

  const gotoDashboard = () => {
    props.history.push("/myprofile");
  };

  const buySubscriptionForThisPlan = (subscription) => {
    console.log("buySubscriptionForThisPlan", subscription);

    props.history.push({
      pathname: "/paymentgateway",
      state: { id: subscription.id, plantype: "SubscriptionPlan" },
    });
  };

  const toggleSubscription = (subscription) => {
    console.log(
      "subscription.stripe_subscription_id=",
      subscription.stripe_subscription_id
    );
    axios({
      method: "post",
      url: "/api/stripe/subscription/toggle",
      headers: {
        locale: "en",
        "x-access-token": "Memo " + token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        stripe_subscription_id: subscription.stripe_subscription_id,
      }),
    })
      .then((response) => {
        //setTimeout(()=> {
        console.log("response", response.data);
        setMySubscriptions([]);
        setSubscriptionHistryListRefresh(!bSubscriptionHistryListRefresh);
        //},2000)
      })
      .catch((error) => {});
  };

  const deleteSubscription = (sub) => {
    console.log("sub to delete", sub);
    console.log("sub.stripe_subscription_id=", sub.stripe_subscription_id);
    axios({
      method: "post",
      url: "/api/stripe/subscription/delete",
      headers: {
        locale: "en",
        "x-access-token": "Memo " + token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        stripe_subscription_id: sub.stripe_subscription_id,
      }),
    })
      .then((response) => {
        //setTimeout(()=> {
        console.log("response", response.data);
        setMySubscriptions([]);
        setSubscriptionHistryListRefresh(!bSubscriptionHistryListRefresh);
        //},2000)
      })
      .catch((error) => {});
  };
  return (
    <React.Fragment>
      {/* <BasicHeader 
                {...props}
            /> */}
      <div class="dashboard-wrapper">
        <div class="container">
          <div class="dashboard-content">
            <SideNavBar {...props} />
            <div class="dashboard-information">
              {/* <!-- Begin Dashboard Tab Section --> */}
              <section class="dashboard-section" style={{ display: "none" }}>
                <div class="header-txt animated fadeInRight">Dashboard</div>
                <div class="profile-box animated fadeInRight">
                  <div class="topside">
                    <div class="row align-items-center">
                      <div class="col-sm-12 col-md-6 col-lg-6">
                        <h3>Welcome</h3>
                        <div class="user-name">Sivapathacholan</div>
                        <div class="info-icon">
                          <span class="icon icon-phone"></span>
                          <span>+91 8877665544</span>
                        </div>
                        <div class="info-icon">
                          <span class="icon icon-user-email"></span>
                          <span class="email">sivapathacholan@gmail.com</span>
                        </div>
                        <div class="info-icon">
                          <span class="icon icon-time-left"></span>
                          <span>4435566</span>
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-3 col-lg-3">
                        <div class="profile-btns">
                          <a href="javascript:void(0)">Premium</a>
                          <a href="javascript:void(0)">Creator</a>
                          <a href="javascript:void(0)">Edit</a>
                        </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-3 col-lg-3">
                        <div class="profile-image">
                          <img
                            src="images/dashboard/profile.png"
                            alt="Profile Img"
                          />
                          <a href="javascript:void(0)" class="edit-profile">
                            <span class="icon-camera1"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bottomsite">
                    <div class="row">
                      <div class="col-4 b-boxs">
                        <div class="b-icon">
                          <img
                            src="images/dashboard/question-icon.jpg"
                            alt="Img"
                          />
                        </div>
                        <div class="number">4</div>
                        <div class="pr-1 pl-1">No of quizes</div>
                      </div>
                      <div class="col-4 b-boxs">
                        <div class="b-icon">
                          <img
                            src="images/dashboard/credits-icon.jpg"
                            alt="Img"
                          />
                        </div>
                        <div class="number">245</div>
                        <div class="pr-1 pl-1">Credits available</div>
                      </div>
                      <div class="col-4 b-boxs">
                        <div class="b-icon">
                          <img
                            src="images/dashboard/referal-icon.jpg"
                            alt="Img"
                          />
                        </div>
                        <div class="number">4</div>
                        <div class="pr-1 pl-1">My referal</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="chart-info animated fadeInRight">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="chart-boxs">Accuracy</div>
                    </div>
                    <div class="col-sm-4">
                      <div class="chart-boxs">Accuracy</div>
                    </div>
                    <div class="col-sm-4">
                      <div class="chart-boxs">Accuracy</div>
                    </div>
                  </div>
                </div>
                <div class="preferred-info preferred-genres animated fadeInRight">
                  <div class="row mb-4">
                    <div class="col-8">
                      <h3>Preferred Genres</h3>
                    </div>
                    <div class="col-4 view-all-btn">
                      <a href="javascript:void(0)">
                        View all <span class="icon-arrow-right"></span>
                      </a>
                    </div>
                  </div>
                  <div class="row row-eq-height">
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                      <a
                        href="javascript:void(0)"
                        class="preferred-boxs square"
                      >
                        <span>
                          <img src="images/dashboard/music.png" alt="Img" />
                        </span>
                        <span class="in-txt">Bollywood Songs</span>
                      </a>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                      <a
                        href="javascript:void(0)"
                        class="preferred-boxs square"
                      >
                        <span>
                          <img
                            src="images/dashboard/electric-guitar.png"
                            alt="Img"
                          />
                        </span>
                        <span class="in-txt">Rock n Roll</span>
                      </a>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                      <a
                        href="javascript:void(0)"
                        class="preferred-boxs square"
                      >
                        <span>
                          <img
                            src="images/dashboard/health-checkup.png"
                            alt="Img"
                          />
                        </span>
                        <span class="in-txt">Bollywood Songs</span>
                      </a>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                      <a
                        href="javascript:void(0)"
                        class="preferred-boxs square"
                      >
                        <span>
                          <img src="images/dashboard/music.png" alt="Img" />
                        </span>
                        <span class="in-txt">Rock n Roll</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="preferred-info preferred-genres animated fadeInRight">
                  <div class="row mb-4">
                    <div class="col-8">
                      <h3>Preferred Categories</h3>
                    </div>
                    <div class="col-4 view-all-btn">
                      <a href="javascript:void(0)">
                        View all <span class="icon-arrow-right"></span>
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                      <a
                        href="javascript:void(0)"
                        class="preferred-boxs circle"
                      >
                        <img src="images/dashboard/music.png" alt="Img" />
                      </a>
                      <div class="in-txt">Music Collections</div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                      <a
                        href="javascript:void(0)"
                        class="preferred-boxs circle"
                      >
                        <img
                          src="images/dashboard/electric-guitar.png"
                          alt="Img"
                        />
                      </a>
                      <div class="in-txt">Rock Music</div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                      <a
                        href="javascript:void(0)"
                        class="preferred-boxs circle"
                      >
                        <img
                          src="images/dashboard/health-checkup.png"
                          alt="Img"
                        />
                      </a>
                      <div class="in-txt">Health & Medicines</div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                      <a
                        href="javascript:void(0)"
                        class="preferred-boxs circle"
                      >
                        <img src="images/dashboard/music.png" alt="Img" />
                      </a>
                      <div class="in-txt">Music Collections</div>
                    </div>
                  </div>
                </div>
                <div class="preferred-info preferred-genres animated fadeInRight">
                  <div class="row mb-4">
                    <div class="col-8">
                      <h3>Preferred Languages</h3>
                    </div>
                    <div class="col-4 view-all-btn">
                      <a href="javascript:void(0)">
                        View all <span class="icon-arrow-right"></span>
                      </a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                      <a
                        href="javascript:void(0)"
                        class="preferred-boxs circle"
                      >
                        <span class="dashborad-flags india"></span>
                      </a>
                      <div class="in-txt">Hindi</div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                      <a
                        href="javascript:void(0)"
                        class="preferred-boxs circle"
                      >
                        <span class="dashborad-flags usa"></span>
                      </a>
                      <div class="in-txt">English</div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                      <a
                        href="javascript:void(0)"
                        class="preferred-boxs circle"
                      >
                        <span class="dashborad-flags china"></span>
                      </a>
                      <div class="in-txt">Chinese</div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3 col-lg-3 mb-3">
                      <a
                        href="javascript:void(0)"
                        class="preferred-boxs circle"
                      >
                        <span class="dashborad-flags japan"></span>
                      </a>
                      <div class="in-txt">Japanese</div>
                    </div>
                  </div>
                </div>
              </section>
              {/* <!-- End Dashboard Tab Section --> */}
              <div class="header-txt"> {t("type_of_subscription_plan")}</div>
              <UserTypesAndPricingComponent {...props} />

              {/* <!-- Begin Manage Subscription Section --> */}
              <div class="manage-subscription-section">
                <div class="">
                  <div class="header-txt">
                    {" "}
                    {t("type_of_subscription_plan")}
                  </div>
                  {/* <div class="header-txt"> {t('your_subscription_plan')}</div> */}

                  {mySubscriptions && mySubscriptions.length > 0
                    ? mySubscriptions.map((subs, i) => (
                        <React.Fragment>
                          <h4>{subs.plan_name}</h4>
                          {subs.user_type_id == 3 ? (
                            <div class="col-md-7 col-lg-9 text-center pl-0 my-4">
                              <div className="d-flex align-items-center">
                                <h3 className="mr-4">
                                  {subs.user_type_id == 3
                                    ? t("pricing_pro_user")
                                    : t("creator")}
                                </h3>
                                <a
                                  className="blue-bg-btn deleteSubscriptionBtn"
                                  style={{
                                    display: subs.stripe_subscription_id
                                      ? "block"
                                      : "none",
                                  }}
                                  href="javascript:void(0)"
                                  onClick={() => deleteSubscription(subs)}
                                >
                                  {t("delete_subscription")}
                                </a>
                              </div>
                            </div>
                          ) : null}
                          <div class="row align-items-center">
                            <div class="col-md-6 col-lg-3">
                              <p>{t("lbl_start_date")}:</p>
                              <b>{subs.start_at_formatted}</b>
                            </div>
                            <div class="col-md-6 col-lg-3">
                              <p>{t("lbl_expiry_date")}: </p>
                              <b>{subs.expired_at_formatted}</b>
                            </div>
                            <div class="col-md-5 col-lg-2">
                              <p>{t("amount")}: </p>
                              <b>
                                {subs.currency_symbol} {subs.amount}
                              </b>
                            </div>
                            <div class="col-md-7 col-lg-4 text-center">
                              <a
                                className="blue-bg-btn"
                                style={{
                                  display: subs.stripe_subscription_id
                                    ? "block"
                                    : "none",
                                }}
                                href="javascript:void(0)"
                                onClick={() => toggleSubscription(subs)}
                              >
                                {subs.auto_renewing == "1"
                                  ? t("switch_auto_renew_off")
                                  : t("switch_auto_renew_on")}
                              </a>
                            </div>
                          </div>
                        </React.Fragment>
                      ))
                    : null}
                </div>
                <div class="subscription-item">
                  <div class="row row-eq-height">
                    {subscriptions.length > 0
                      ? subscriptions.map((subscription) =>
                          user_type_id == -1 ||
                          subscription.user_type_id == user_type_id ? (
                            <div class="col-md-6 col-lg-4 mb-3">
                              <div class="subscription-box">
                                {subscription.user_type_id !== 10 ? (
                                  <span class="icon icon-idea"></span>
                                ) : (
                                  <span class="icon icon-subscription1"></span>
                                )}
                                <h5>
                                  {subscription.spl_plan_name
                                    ? subscription.spl_plan_name
                                    : subscription.plan_name}
                                </h5>
                                <h5 className="price-bold">
                                  {" "}
                                  {subscription.user_type_name.toLowerCase() ==
                                  "pro"
                                    ? t("pro")
                                    : t("creator")}{" "}
                                </h5>

                                <div class="price">
                                  <span>
                                    {subscription.currency_symbol}{" "}
                                    {subscription.price_per_month}/{t("month")}
                                  </span>
                                </div>
                                <div class="price">
                                  <span className="price-bold">
                                    {t("total")} {subscription.currency_symbol}{" "}
                                    {subscription.amount}
                                  </span>
                                </div>
                                {/*<a href="javascript:void(0)" class="button" onClick={()=> gotoPaymentGateway(subscription)}>{t("buy_now")}</a> */}
                                <a
                                  style={{ display: 1 ? "block" : "none" }}
                                  href="javascript:void(0)"
                                  class="button"
                                  onClick={() =>
                                    buySubscriptionForThisPlan(subscription)
                                  }
                                >
                                  {t("buy_now")}
                                </a>
                              </div>
                            </div>
                          ) : null
                        )
                      : null}
                  </div>
                </div>
              </div>
              {/* <!-- End Manage Subscription Section --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default ManageSubscriptions;
