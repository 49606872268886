/* eslint-disable */
import qs from "qs";
import React, { useEffect, useState } from "react";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
// import "../less/owl.carousel.min.css"
import "../less/categories-preferences.css";
// import "../less/quiz-creation.css"
// import "../less/owl.carousel.min.css"
import plusImg from "../images/plus.png";
import "../less/attributes.css";

import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useCookies } from "react-cookie";
import useAsyncEffect from "../Hooks/useAsyncEffect";

function SelectCategories(props) {
  //console.log("categories props", props)
  let primaryLanguageId;
  let primaryLanguageCode;
  //const location = useLocation()
  //console.log('location',props.location.state)
  /*
    if (props.location.state && props.location.state.primaryLanguage.id) {
        //console.log('defined')
        primaryLanguageId = props.location.state.primaryLanguage.id
        primaryLanguageCode = props.location.state.primaryLanguage.code
    }
    */

  //primaryLanguageId = JSON.parse(props.location.state.primaryLanguage).id
  //primaryLanguageCode = JSON.parse(props.location.state.primaryLanguage).code

  primaryLanguageId = props.location.state.primaryLanguage.id; //props.location.state.availableLanguages[0].id
  primaryLanguageCode = props.location.state.primaryLanguage.code;

  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const token = cookies["access_token"]; //getToken()
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  const [genreList, setGenreList] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [genreCategories, setGenreCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [topics, setTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [levels, setLevels] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [availableLanguages, setAvailableLangauges] = useState(
    props.location.state.availableLanguages
  );
  const [pagesInGenre, setPagesInGenre] = useState();
  const [keywords, setKeywords] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [selectedKeywordIds, setSelectedKeywordIds] = useState([]);
  const [currentGenrePages, setCurrentGenrePages] = useState([]);
  const [inputKeyword, setInputKeyword] = useState();

  const [showCategory, setShowCategory] = useState(true);
  const [showSubCategories, setShowSubCategories] = useState(true);
  const [showTopic, setShowTopic] = useState(true);
  const [showLevel, setShowLevel] = useState(true);

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";

  const showSuccessMessageToUser = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const showErrorMessageToUser = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  //console.log(token)

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/genres/list",
      headers: {
        locale: primaryLanguageCode,
      },
      data: qs.stringify({
        page: "1",
      }),
    })
      .then((response) => {
        console.log("genre response", response);
        setPagesInGenre(response.data.total_pages);
      })
      .catch((error) => {});

    axios({
      method: "post",
      url: "/api/sub-category/topics",
      headers: {
        locale: primaryLanguageCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        page: "1",
        sub_category_id: 300,
      }),
    })
      .then((response) => {
        console.log("topics response", response);
      })
      .catch((error) => {});

    axios({
      method: "post",
      url: "/api/topics/levels",
      headers: {
        locale: primaryLanguageCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        page: "1",
        topic_id: 369,
      }),
    })
      .then((response) => {
        console.log("topics response", response);
      })
      .catch((error) => {});

    axios({
      method: "get",
      url: "/api/keywords/list",
      headers: {
        locale: primaryLanguageCode,
        "x-access-token": "Memo " + token,
      },
    })
      .then((response) => {
        console.log("keyword response", response);
        setKeywords(response.data.data);
      })
      .catch((error) => {});
  }, []);

  function compare_name(a, b) {
    // a should come before b in the sorted order
    if (a.name < b.name) {
      return -1;
      // a should come after b in the sorted order
    } else if (a.name > b.name) {
      return 1;
      // and and b are the same
    } else {
      return 0;
    }
  }

  useAsyncEffect(
    function* (onCancel) {
      console.log("in useAsyncEffect");
      const cancelTokenSource = axios.CancelToken.source(); //new AbortController()
      const token1 = cancelTokenSource.token;

      onCancel(() => {
        console.log(
          "cancel while fetch is still executed, use controller for aborting the request."
        );
        cancelTokenSource.cancel();
      });

      try {
        setGenreList([]);
        setCurrentGenrePages([]);
        if (pagesInGenre) {
          for (let i = 1; i <= pagesInGenre; i++) {
            const responseGenreList = yield axios({
              method: "post",
              url: "/api/genres/list",
              headers: {
                locale: primaryLanguageCode,
              },
              data: qs.stringify(
                {
                  page: i,
                },
                { cancelToken: token1 }
              ),
            });

            console.log("genre response", responseGenreList);
            setGenreList((genreList) =>
              genreList.concat(responseGenreList.data.data)
            );
            setCurrentGenrePages((currentGenrePages) =>
              currentGenrePages.concat(i)
            );
          }
        }
      } catch (err) {
        if (err.name === "AbortError") {
          console.log("Request was canceled via controller.abort");
          // we know that an 'AbortError' occurs when the request is
          // cancelled this means that the next promise returned by yield
          // will be created but not actively used, thus, we return in
          // order to avoid the promise being created.
          return;
        }
      }
    },
    [pagesInGenre]
  );

  useEffect(() => {
    if (genreList.length > 0) {
      console.log("genreList", genreList);
      genreList.sort(compare_name);
    }
  }, [genreList]);

  const genreSelected = (id, name) => {
    let selectedGenreSet = new Set(selectedGenres);
    selectedGenreSet.has(id)
      ? selectedGenreSet.delete(id)
      : selectedGenreSet.add(id);
    setSelectedGenres(Array.from(selectedGenreSet));
    if (selectedGenreSet.size > 0) {
      //document.getElementById("plusImgCategories").style.display = "block";
    }
    document.getElementById("noCatsMessage").style.display = "none";
  };

  const categoriesSelected = (id, name) => {
    let tempArray = [];
    let alreadyselected = false;
    selectedCategories.map((eachCategory) => {
      if (eachCategory !== id) {
        tempArray.push(eachCategory);
      } else {
        alreadyselected = true;
      }
    });
    if (alreadyselected == false) {
      tempArray.push(id);
    }
    console.log("categoriesSelected", tempArray);
    setSelectedCategories(tempArray);
  };

  const subcategoriesSelected = (id, name) => {
    let tempArray = [];
    let alreadyselected = false;
    selectedSubCategories.map((eachSubCategory) => {
      if (eachSubCategory !== id) {
        tempArray.push(eachSubCategory);
      } else {
        alreadyselected = true;
      }
    });
    if (alreadyselected == false) {
      tempArray.push(id);
    }
    setSelectedSubCategories(tempArray);
  };

  const topicSelected = (id, name) => {
    let tempArray = [];
    let alreadyselected = false;
    selectedTopics.map((eachTopic) => {
      if (eachTopic !== id) {
        tempArray.push(eachTopic);
      } else {
        alreadyselected = true;
      }
    });
    if (alreadyselected == false) {
      tempArray.push(id);
    }
    setSelectedTopics(tempArray);
  };

  const levelSelected = (id, name) => {
    let tempArray = [];
    let alreadyselected = false;
    selectedLevels.map((eachLevel) => {
      if (eachLevel !== id) {
        tempArray.push(eachLevel);
      } else {
        alreadyselected = true;
      }
    });
    if (alreadyselected == false) {
      tempArray.push(id);
    }
    setSelectedLevels(tempArray);
  };

  useEffect(() => {
    setSelectedCategories([]);
    setSelectedSubCategories([]);
    setShowCategory(!showCategory);
  }, [selectedGenres]);

  useEffect(() => {
    setSelectedSubCategories([]);
    setShowSubCategories(!showSubCategories);
  }, [selectedCategories]);

  useEffect(() => {
    setSelectedTopics([]);
    setShowTopic(!showTopic);
  }, [selectedSubCategories]);

  useEffect(() => {
    setSelectedLevels([]);
    setShowLevel(!showLevel);
  }, [selectedTopics]);

  useEffect(() => {
    console.log("selectedGenres", selectedGenres);
    console.log("selectedCategories", selectedCategories);
    console.log(selectedKeywordIds);
    //console.log(totalCategoriesPages, currentCategoriesPages, currentCategoriesPages.length == totalCategoriesPages);
  }, [selectedGenres, selectedCategories, selectedKeywordIds]);

  useAsyncEffect(
    function* (onCancel) {
      console.log("in useAsyncEffect");
      const cancelTokenSource = axios.CancelToken.source(); //new AbortController()
      const token1 = cancelTokenSource.token;

      onCancel(() => {
        console.log(
          "cancel while fetch is still executed, use controller for aborting the request."
        );
        cancelTokenSource.cancel();
      });

      try {
        setGenreCategories([]);
        let genrePagesCategory = [];
        for (let i = 0; i < selectedGenres.length; i++) {
          const responseCat = yield axios({
            method: "post",
            url: "/api/genres/details/categories",
            headers: {
              locale: primaryLanguageCode,
            },
            data: qs.stringify(
              {
                genre_id: selectedGenres[i],
                page: 1,
              },
              { cancelToken: token1 }
            ),
          });
          console.log("responseCat", responseCat);
          //[{genre_id: 23, total_pages: responses[i].data.total_pages}]
          genrePagesCategory.push({
            genre_id: selectedGenres[i],
            total_pages: responseCat.data.total_pages,
          });
        }

        for (let index = 0; index < genrePagesCategory.length; index++) {
          for (
            let pg = 1;
            genrePagesCategory[index].total_pages > 0 &&
            pg <= genrePagesCategory[index].total_pages;
            pg++
          ) {
            const responseCat = yield axios({
              method: "post",
              url: "/api/genres/details/categories",
              headers: {
                locale: primaryLanguageCode,
              },
              data: qs.stringify(
                {
                  genre_id: genrePagesCategory[index].genre_id,
                  page: pg,
                },
                { cancelToken: token1 }
              ),
            });
            setGenreCategories((genreCategories) =>
              genreCategories.concat(responseCat.data.data)
            );
          }
        }
      } catch (err) {
        if (err.name === "AbortError") {
          console.log("Request was canceled via controller.abort");
          // we know that an 'AbortError' occurs when the request is
          // cancelled this means that the next promise returned by yield
          // will be created but not actively used, thus, we return in
          // order to avoid the promise being created.
          return;
        }
      }
    },
    [showCategory]
  );

  // Category Functions Ends

  // Subcategory Functions starts
  useAsyncEffect(
    function* (onCancel) {
      console.log("in useAsyncEffect");
      const cancelTokenSource = axios.CancelToken.source(); //new AbortController()
      const token1 = cancelTokenSource.token;

      onCancel(() => {
        console.log(
          "cancel while fetch is still executed, use controller for aborting the request."
        );
        cancelTokenSource.cancel();
      });

      try {
        setSubCategories([]);
        let categoryPagesSubCategory = [];
        for (let i = 0; i < selectedCategories.length; i++) {
          const responseSubCat = yield axios({
            method: "post",
            url: "/api/subcategories/list",
            headers: {
              "x-access-token": "Memo " + token,
              locale: primaryLanguageCode,
            },
            data: qs.stringify(
              {
                category_id: selectedCategories[i],
                page: 1,
              },
              { cancelToken: token1 }
            ),
          });
          console.log("responseSubCat", responseSubCat);
          //[{category_id: 23, total_pages: responses[i].data.total_pages}]
          categoryPagesSubCategory.push({
            category_id: selectedCategories[i],
            total_pages: responseSubCat.data.total_pages,
          });
        }
        console.log("here");
        for (let index = 0; index < categoryPagesSubCategory.length; index++) {
          for (
            let pg = 1;
            categoryPagesSubCategory[index].total_pages > 0 &&
            pg <= categoryPagesSubCategory[index].total_pages;
            pg++
          ) {
            const responseSubCat = yield axios({
              method: "post",
              url: "/api/subcategories/list",
              headers: {
                "x-access-token": "Memo " + token,
                locale: primaryLanguageCode,
              },
              data: qs.stringify(
                {
                  category_id: categoryPagesSubCategory[index].category_id,
                  page: pg,
                },
                { cancelToken: token1 }
              ),
            });
            setSubCategories((subCategories) =>
              subCategories.concat(responseSubCat.data.data)
            );
          }
        }
      } catch (err) {
        console.log("err", err);
        if (err.name === "AbortError") {
          console.log("Request was canceled via controller.abort");
          // we know that an 'AbortError' occurs when the request is
          // cancelled this means that the next promise returned by yield
          // will be created but not actively used, thus, we return in
          // order to avoid the promise being created.
          return;
        }
      }
    },
    [showSubCategories]
  );

  useAsyncEffect(
    function* (onCancel) {
      const cancelTokenSource = axios.CancelToken.source(); //new AbortController()
      const token1 = cancelTokenSource.token;

      onCancel(() => {
        console.log(
          "cancel while fetch is still executed, use controller for aborting the request."
        );
        cancelTokenSource.cancel();
      });

      try {
        setTopics([]);
        let subCategoryPageTopic = [];
        for (let i = 0; i < selectedSubCategories.length; i++) {
          const resTopic = yield axios({
            method: "post",
            url: "/api/sub-category/topics",
            headers: {
              "x-access-token": "Memo " + token,
              locale: primaryLanguageCode,
            },
            data: qs.stringify(
              {
                sub_category_id: selectedSubCategories[i],
                page: 1,
              },
              { cancelToken: token1 }
            ),
          });
          console.log("resTopic", resTopic);
          //[{category_id: 23, total_pages: responses[i].data.total_pages}]
          subCategoryPageTopic.push({
            sub_category_id: selectedSubCategories[i],
            total_pages: resTopic.data.total_pages,
          });
        }
        console.log("here");
        for (let index = 0; index < subCategoryPageTopic.length; index++) {
          for (
            let pg = 1;
            subCategoryPageTopic[index].total_pages > 0 &&
            pg <= subCategoryPageTopic[index].total_pages;
            pg++
          ) {
            const resTopic2 = yield axios({
              method: "post",
              url: "/api/sub-category/topics",
              headers: {
                "x-access-token": "Memo " + token,
                locale: primaryLanguageCode,
              },
              data: qs.stringify(
                {
                  sub_category_id: subCategoryPageTopic[index].sub_category_id,
                  page: pg,
                },
                { cancelToken: token1 }
              ),
            });
            console.log("resTopic2", resTopic2);
            setTopics((topics) => topics.concat(resTopic2.data.data));
          }
        }
      } catch (err) {
        console.log("err", err);
        if (err.name === "AbortError") {
          console.log("Request was canceled via controller.abort");
          // we know that an 'AbortError' occurs when the request is
          // cancelled this means that the next promise returned by yield
          // will be created but not actively used, thus, we return in
          // order to avoid the promise being created.
          return;
        }
      }
    },
    [showTopic]
  );

  useAsyncEffect(
    function* (onCancel) {
      const cancelTokenSource = axios.CancelToken.source(); //new AbortController()
      const token1 = cancelTokenSource.token;

      onCancel(() => {
        console.log(
          "cancel while fetch is still executed, use controller for aborting the request."
        );
        cancelTokenSource.cancel();
      });

      try {
        setLevels([]);
        let levelPageTopic = [];
        for (let i = 0; i < selectedTopics.length; i++) {
          const resLevel = yield axios({
            method: "post",
            url: "/api/topics/levels",
            headers: {
              "x-access-token": "Memo " + token,
              locale: primaryLanguageCode,
            },
            data: qs.stringify(
              {
                topic_id: selectedTopics[i],
                page: 1,
              },
              { cancelToken: token1 }
            ),
          });
          console.log("resLevel", resLevel);
          //[{category_id: 23, total_pages: responses[i].data.total_pages}]
          levelPageTopic.push({
            topic_id: selectedTopics[i],
            total_pages: resLevel.data.total_pages,
          });
        }
        console.log("here");
        for (let index = 0; index < levelPageTopic.length; index++) {
          for (
            let pg = 1;
            levelPageTopic[index].total_pages > 0 &&
            pg <= levelPageTopic[index].total_pages;
            pg++
          ) {
            const resLevel2 = yield axios({
              method: "post",
              url: "/api/topics/levels",
              headers: {
                "x-access-token": "Memo " + token,
                locale: primaryLanguageCode,
              },
              data: qs.stringify(
                {
                  topic_id: levelPageTopic[index].topic_id,
                  page: pg,
                },
                { cancelToken: token1 }
              ),
            });
            console.log("resLevel2", resLevel2);
            setLevels((levels) => levels.concat(resLevel2.data.data));
          }
        }
      } catch (err) {
        console.log("err", err);
        if (err.name === "AbortError") {
          console.log("Request was canceled via controller.abort");
          // we know that an 'AbortError' occurs when the request is
          // cancelled this means that the next promise returned by yield
          // will be created but not actively used, thus, we return in
          // order to avoid the promise being created.
          return;
        }
      }
    },
    [showLevel]
  );

  // Subcategory Functions ends

  const goToContentDeckPage = () => {
    if (selectedGenres.length > 0 && selectedCategories.length > 0) {
      document.getElementById("errorMessage").style.display = "none";
      props.history.push({
        pathname: "/createcontent-description",
        state: {
          ...props.location.state,
          //primaryLanguage:primaryLanguage,
          //availableLanguages: availableLanguages,
          selectedGenres: selectedGenres,
          selectedCategories: selectedCategories,
          selectedSubCategories: selectedSubCategories,
          selectedTopics: selectedTopics,
          selectedLevels: selectedLevels,
          selectedAttributes: [4],
          selectedKeywords: selectedKeywordIds,
        },
      });
    } else {
      document.getElementById("errorMessage").style.display = "block";
    }
  };
  /*
    const languageAddition = (id, name, code, flag) => {
        let remove = false;
        let add = false;
        let tempArray = [];
        let tempIdArray = [];
        if (availableLanguages.length > 0) {
            availableLanguages.map(language => {
                if (language.id === id) {
                    remove = true;
                } else {

                    tempArray.push({
                        id: language.id,
                        name: language.name,
                        code: language.code,
                        flag: language.flag
                    })
                    tempIdArray.push(language.id)

                }
            })
        }
        if (remove == false) {

            tempArray.push({
                id: id,
                name: name,
                code: code,
                flag: flag
            })
            tempIdArray.push(id)

        }
        setAvailableLangauges(tempArray)
        setAvailableLangaugeIds(tempIdArray)
    }
    */

  const addKeywordNames = (event, values) => {
    console.log(values);
    if (values) {
      setSelectedKeywordIds((selectedKeywordIds) =>
        selectedKeywordIds.concat(values.id)
      );
      setSelectedKeywords((selectedKeywords) =>
        selectedKeywords.concat(values)
      );
    }
  };

  const currentKeyword = (e) => {
    setInputKeyword(e.target.value);
  };

  const addExplicitKeywords = () => {
    console.log(inputKeyword);
    axios({
      method: "post",
      url: "/api/keywords/create",
      headers: {
        "x-access-token": "Memo " + token,
        locale: primaryLanguageCode,
      },
      data: qs.stringify({
        keyword_name: inputKeyword,
      }),
    })
      .then((response) => {
        console.log("keyword add response", response);
        showSuccessMessageToUser(response.data.msg);
        setSelectedKeywordIds((selectedKeywordIds) =>
          selectedKeywordIds.concat(response.data.id)
        );
        setSelectedKeywords((selectedKeywords) =>
          selectedKeywords.concat(response.data)
        );
        axios({
          method: "get",
          url: "/api/keywords/list",
          headers: {
            locale: primaryLanguageCode,
            "x-access-token": "Memo " + token,
          },
        })
          .then((response) => {
            console.log("keyword response", response);
            // setGenreList(genreList => genreList.concat(response.data.data))
            setKeywords(response.data.data);
            // setUserSession(token, response.data.user);
          })
          .catch((error) => {
            // removeUserSession();
          });
      })
      .catch((error) => {
        // removeUserSession();
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  return (
    <React.Fragment>
      {/* <BasicHeader 
            {...props}
        /> */}

      <div className="inner-header">
        <div className="container">
          <div className="row">
            <div className="col-sm-7 col-md-6 col-lg-6">
              <div className="breadcrumb-item animated fadeInRight">
                <ul>
                  <li>
                    <a href="javascript:void(0)">
                      <span className="icon-home1"></span> {t("home")}
                    </a>
                  </li>
                  <li>
                    <span className="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" className="active">
                      {t("create_content")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

      <section class="gener_gap">
        <div class="container">
          <div class="d-flex performance2">
            <div class="mr-auto">
              {/* <h3 style={{color: "#1d5190!important"}}>Preferences</h3> */}
            </div>
          </div>

          <div class="col-lg-6 col-md-8 col-sm-12 cust_center">
            {/* 
                        <h4>{t("select_secondary_language")}</h4>
                        <div class="row">
                            {
                                languages ?
                                    (
                                        languages.map(language => (
                                            language.id == primaryLanguageId
                                                ?
                                                (
                                                    <div
                                                        class="col-md-3 lang_sel text-center"
                                                    // onClick={() => languageAddition(language.id, language.name, language.lang_code, language.flag)}
                                                    >
                                                        <div
                                                            // className={(primaryLanguageId == language.id || availableLanguageIds.includes(language.id)) ?"circle active_circ" : "circle"}
                                                            className={(primaryLanguageId == language.id || availableLanguages.includes()) ? "circle active_circ" : "circle"}


                                                        >
                                                            <img src={(COUNTRY_FLAG + language.flag)} />
                                                        </div>
                                                        <p>{t(language.name)}</p>
                                                    </div>
                                                )
                                                :
                                                null
                                        ))
                                    )
                                    :
                                    null
                            }
                            {
                                languages ?
                                    (
                                        languages.map(language => (
                                            language.id !== primaryLanguageId ?
                                                (
                                                    <div
                                                        class="col-md-3 lang_sel text-center"
                                                        onClick={() => languageAddition(language.id, language.name, language.lang_code, language.flag)}
                                                    >
                                                        <div
                                                            // className={(primaryLanguageId == language.id || availableLanguageIds.includes(language.id)) ?"circle active_circ" : "circle"}
                                                            className={availableLanguageIds.includes(language.id) ? "circle active_circ" : "circle"}


                                                        >
                                                            <img src={(COUNTRY_FLAG + language.flag)} />
                                                        </div>
                                                        <p>{t(language.name)}</p>
                                                    </div>
                                                ) : null
                                        ))
                                    )
                                    :
                                    null
                            }
                        </div>
                    */}
            <h4>{t("select_genres")}</h4>
            <div style={{ width: "100%", position: "relative" }}>
              {/* <input type="text" value="" data-role="tagsinput" class="form-control" /> */}
              {/* <img src={require("../images/plus.png")} class="plus_img" /> */}
            </div>
            <div class="row genre_pref_list ">
              {genreList && currentGenrePages.length === pagesInGenre
                ? genreList.length > 0
                  ? genreList.map((eachGenre) => (
                      <div
                        class="col-lg-3 col-md-3 col-sm-4 col-xs-6 no-pad"
                        onClick={() =>
                          genreSelected(eachGenre.id, eachGenre.name)
                        }
                      >
                        <div
                          className={
                            selectedGenres.includes(eachGenre.id)
                              ? "item cur_list"
                              : "item"
                          }
                        >
                          <div class="cust_genre">
                            <img src={GENER_PATH + eachGenre.image} />
                            <h4
                              dangerouslySetInnerHTML={{
                                __html: eachGenre.name,
                              }}
                            ></h4>
                          </div>
                        </div>
                      </div>
                    ))
                  : null
                : null}
            </div>
            <h4>{t("select_categories")}</h4>
            <div style={{ width: "100%", position: "relative" }}>
              <img
                src={plusImg}
                class="plus_img"
                id="plusImgCategories"
                style={{ display: "none" }}
              />
            </div>
            <div class="row genre_pref_list ">
              {genreCategories
                ? // && currentCategoriesPages.length == totalCategoriesPages
                  genreCategories.length > 0
                  ? genreCategories.map((category) => (
                      <div
                        class="col-lg-3 col-md-3 col-sm-4 col-xs-6 no-pad"
                        onClick={() =>
                          categoriesSelected(category.id, category.name)
                        }
                      >
                        <div
                          className={
                            selectedCategories.includes(category.id)
                              ? "item cur_list"
                              : "item"
                          }
                        >
                          <div class="cust_genre">
                            <img src={CATEGORY_PATH + category.image} />
                            <h4>{category.name}</h4>
                          </div>
                        </div>
                      </div>
                    ))
                  : null
                : null}
            </div>
            <div
              id="errorMessage"
              style={{
                display: "none",
                color: "#fff",
                backgroundColor: "red",
                width: "400px",
              }}
            >
              {t("select_categories_and_genres")}
            </div>
            <div
              id="noCatsMessage"
              style={{
                display: "none",
                color: "#fff",
                backgroundColor: "red",
                width: "400px",
              }}
            >
              {t("no_categories_available")}
            </div>
            <h4>{t("select_sub_categories")}</h4>
            <div style={{ width: "100%", position: "relative" }}>
              {/* <input type="text" value="" data-role="tagsinput" class="form-control" /> */}
              <img
                src={plusImg}
                class="plus_img"
                id="plusImgSubCategories"
                style={{ display: "none" }}
              />
            </div>
            <div class="row genre_pref_list ">
              {subCategories
                ? // && currentSubCategoriesPages.length == totalSubCategoriesPages
                  subCategories.length > 0
                  ? subCategories.map((subcategory) => (
                      <div
                        class="col-lg-3 col-md-3 col-sm-4 col-xs-6 no-pad"
                        onClick={() =>
                          subcategoriesSelected(
                            subcategory.id,
                            subcategory.name
                          )
                        }
                      >
                        <div
                          className={
                            selectedSubCategories.includes(subcategory.id)
                              ? "item cur_list"
                              : "item"
                          }
                        >
                          <div class="cust_genre">
                            <img src={CATEGORY_PATH + subcategory.image} />
                            <h4>{subcategory.name}</h4>
                          </div>
                        </div>
                      </div>
                    ))
                  : null
                : null}
            </div>
            <h4>{t("select_topic")}</h4>
            <div style={{ width: "100%", position: "relative" }}>
              {/* <input type="text" value="" data-role="tagsinput" class="form-control" /> */}
              <img
                src={plusImg}
                class="plus_img"
                id="plusImgSubCategories"
                style={{ display: "none" }}
              />
            </div>
            <div class="row genre_pref_list ">
              {topics
                ? // && currenttopicPages.length == totaltopicPages
                  topics.length > 0
                  ? topics.map((topic) => (
                      <div
                        class="col-lg-3 col-md-3 col-sm-4 col-xs-6 no-pad"
                        onClick={() => topicSelected(topic.id, topic.name)}
                      >
                        <div
                          className={
                            selectedTopics.includes(topic.id)
                              ? "item cur_list"
                              : "item"
                          }
                        >
                          <div class="cust_genre">
                            <img src={CATEGORY_PATH + topic.image} />
                            <h4>{topic.name}</h4>
                          </div>
                        </div>
                      </div>
                    ))
                  : null
                : null}
            </div>
            <h4>{t("select_level")}</h4>
            <div style={{ width: "100%", position: "relative" }}>
              {/* <input type="text" value="" data-role="tagsinput" class="form-control" /> */}
              <img
                src={plusImg}
                class="plus_img"
                id="plusImgSubCategories"
                style={{ display: "none" }}
              />
            </div>
            <div class="row genre_pref_list ">
              {levels
                ? // && currentlevelsPages.length == totallevelsPages
                  levels.length > 0
                  ? levels.map((level) => (
                      <div
                        class="col-lg-3 col-md-3 col-sm-4 col-xs-6 no-pad"
                        onClick={() => levelSelected(level.id, level.name)}
                      >
                        <div
                          className={
                            selectedLevels.includes(level.id)
                              ? "item cur_list"
                              : "item"
                          }
                        >
                          <div class="cust_genre">
                            <img src={CATEGORY_PATH + level.image} />
                            <h4>{level.name}</h4>
                          </div>
                        </div>
                      </div>
                    ))
                  : null
                : null}
            </div>

            {keywords.length > 0 ? (
              <React.Fragment>
                <h4>{t("search_keyword")}</h4>
                <div style={{ width: "100%", position: "relative" }}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={keywords}
                    getOptionLabel={(option) => option.name}
                    onChange={addKeywordNames}
                    onInputChange={currentKeyword}
                    noOptionsText={t("click_+_to_add_keyword")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("keyword")}
                        variant="outlined"
                      />
                    )}
                  />
                  <img
                    src={plusImg}
                    class="plus_img"
                    onClick={() => addExplicitKeywords()}
                  />
                </div>
              </React.Fragment>
            ) : null}

            <div class="row genre_pref_list ">
              {selectedKeywords
                ? selectedKeywords.length > 0
                  ? selectedKeywords.map((keyword) => (
                      <div
                        class="col-lg-3 col-md-3 col-sm-4 col-xs-6 no-pad"
                        // onClick={() => subcategoriesSelected(subcategory.id, subcategory.name)}
                      >
                        <div
                          // className={selectedSubCategories.includes(subcategory.id)?"item cur_list":"item"}
                          className="item cur_list"
                        >
                          <div class="cust_genre">
                            <h4>{keyword.name}</h4>
                          </div>
                        </div>
                      </div>
                    ))
                  : null
                : null}
            </div>

            <div class="col-sm-12">
              <a onClick={goToContentDeckPage} class="cust_cat_btn blue-bg-btn">
                {t("Continue")}
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <BasicFooter 
            
        /> */}
    </React.Fragment>
  );
}

export default SelectCategories;
