/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import useAsyncEffect from "../Hooks/useAsyncEffect";
import Pagination from "./Pagination";
import SuggestedQuizzes from "./SuggestedQuizzes";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
// import "../less/owl.carousel.min.css"
import "../less/home.css";
import "../less/test.css";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { Context } from "../Reducers/Store";
import { tHydrate } from "../Utils/HydrateT";
import Creators from "./Creators";
import GenreList from "./GenreList";
import TopQuizzes from "./TopQuizzes";

function GenreListingPage(props) {
  const { t } = useTranslation();
  const [state, dispatch] = useContext(Context);
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const PAGE_LIMIT = 6;

  const user = cookies["user"];
  const user_id = user.id;

  //console.log(props)
  const [globalGenreId, setGlobalGenreId] = useState();
  const [genreList, setGenreList] = useState([]);

  //For Top Quizzes Pagination.
  const [topQuizListResults, setTopQuizListResults] = useState([]);
  const [totalTopQuizResultsPages, setTotalTopQuizResultsPages] =
    useState(null);
  const [totalTopQuizListResults, setTotalTopQuizListResults] = useState(null);
  const [currentTopQuizListResults, setCurrentTopQuizListResults] = useState(1);

  //For Suggested Quizzes Pagination.
  const [suggestedQuizResults, setSuggestedQuizResults] = useState([]);
  const [totalSuggestedQuizResultsPages, setTotalSuggestedQuizResultsPages] =
    useState(null);
  const [totalSuggestedQuizResults, setTotalSuggestedQuizResults] =
    useState(null);
  const [currentSuggestedQuizResults, setCurrentSuggestedQuizResults] =
    useState(1);

  const [creators, setCreators] = useState([]);
  const [pagesInCreatorsList, setPagesInCreatorsList] = useState(1);

  const [genreDetailsId, setGenreDetailsId] = useState();
  const [userProfile, setUserProfile] = useState();
  const [pagesInGenre, setPagesInGenre] = useState();
  const [adv, setAdv] = useState([]);
  const [adLength, setAdLength] = useState(0);

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());

  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  const token = cookies["access_token"];
  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let ADVERTISEMENT_PATH = awsImageUrl + "/uploads/advertisement/";

  useEffect(() => {
    dispatch({ type: "UPDATE_CURRENT_MENU", payload: "id_genre_page" });
  }, []);

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/genres/list",
      headers: {
        locale: globalLangCode,
      },
      data: qs.stringify({
        page: "1",
      }),
    })
      .then((response) => {
        console.log("genre response page 1", response);

        setGlobalGenreId(response.data.data[0].id);
        setPagesInGenre(response.data.total_pages);

        console.log(globalGenreId);
      })
      .catch((error) => {});

    axios({
      method: "post",
      url: "/api/advertisements/list",
      headers: {
        locale: globalLangCode,
      },
      data: qs.stringify({
        position: 0,
        size: 1,
        user_id: user_id,
      }),
    })
      .then((response) => {
        //console.log("advertisement", response)
        setAdLength(response.data.total);
        // setAdv(response.data.data)
        // setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
      });
  }, []);

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    if (adLength > 0) {
      setAdv([]);
      for (let i = 0; i < 2; i++) {
        let randomPosition = getRandomInt(0, adLength);
        axios({
          method: "post",
          url: "/api/advertisements/list",
          headers: {
            locale: globalLangCode,
          },
          data: qs.stringify({
            position: randomPosition,
            size: 1,
            user_id: user_id,
          }),
        })
          .then((response) => {
            setAdv((adv) => adv.concat(response.data.data));
          })
          .catch((error) => {
            // removeUserSession();
          });
      }
    }
  }, [adLength]);

  useEffect(() => {
    console.log("advertisement", adv);
  }, [adv]);

  useAsyncEffect(
    function* (onCancel) {
      console.log("in useAsyncEffect");
      const cancelTokenSource = axios.CancelToken.source(); //new AbortController()
      const token1 = cancelTokenSource.token;

      onCancel(() => {
        console.log(
          "cancel while fetch is still executed, use controller for aborting the request."
        );
        cancelTokenSource.cancel();
      });

      try {
        setGenreList([]);
        for (let i = 1; i <= pagesInGenre; i++) {
          let responseGenreList = yield axios({
            method: "post",
            url: "/api/genres/list",
            headers: {
              locale: globalLangCode,
            },
            data: qs.stringify(
              {
                page: i,
              },
              { cancelToken: token1 }
            ),
          });
          setGenreList((genreList) =>
            genreList.concat(responseGenreList.data.data)
          );
        }
      } catch (err) {
        if (err.name === "AbortError") {
          console.log("Request was canceled via controller.abort");
          // we know that an 'AbortError' occurs when the request is
          // cancelled this means that the next promise returned by yield
          // will be created but not actively used, thus, we return in
          // order to avoid the promise being created.
          return;
        }
      }
    },
    [pagesInGenre]
  );

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/all/genres/top/quiz/list",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        page: "1",
      }),
    })
      .then((response) => {
        console.log("top quiz response", response);
        setTopQuizListResults(response.data.data);
        setTotalTopQuizResultsPages(response.data.total_pages);
        setTotalTopQuizListResults(response.data.total);
      })
      .catch((error) => {});

    axios({
      method: "post",
      url: "/api/my/suggested/quiz/list",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        page: "1",
      }),
    })
      .then((response) => {
        console.log("suggested quiz response", response);
        setSuggestedQuizResults([]);
        setSuggestedQuizResults(response.data.data);
        setTotalSuggestedQuizResultsPages(response.data.total_pages);
        setTotalSuggestedQuizResults(response.data.total);
      })
      .catch((error) => {});
  }, [globalGenreId]);

  const goToDashboard = () => {
    props.history.push("/myprofile");
  };

  const fetchSuggestedQuizResults = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/my/suggested/quiz/list",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        page: currentPage.toString(),
      }),
    })
      .then((response) => {
        console.log("search response", response.data);
        //setResults(response.data)
        setSuggestedQuizResults(response.data.data);
        setTotalSuggestedQuizResultsPages(response.data.total_pages);
        setTotalSuggestedQuizResults(response.data.total);
        setCurrentSuggestedQuizResults(currentPage);
        //setLoading(false)
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          //setLoading(false);
          //setMessage("Failed to fetch results.Please check network")
        }
      });
  };
  const fetchTopQuizResults = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/all/genres/top/quiz/list",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        page: currentPage.toString(),
      }),
    })
      .then((response) => {
        console.log("search response", response.data);
        //setResults(response.data)
        setTopQuizListResults(response.data.data);
        setTotalTopQuizResultsPages(response.data.total_pages);
        setTotalTopQuizResults(response.data.total);
        setCurrentTopQuizListResults(currentPage);
        //setLoading(false)
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          //setLoading(false);
          //setMessage("Failed to fetch results.Please check network")
        }
      });
  };
  const onSuggestedQuizResultsPageChanged = (data) => {
    //setLoading(true);
    console.log("data.currentPage=" + data.currentPage);
    fetchSuggestedQuizResults(data.currentPage);
    setCurrentSuggestedQuizResults(data.currentPage);
  };

  const onTopQuizResultsPageChanged = (data) => {
    console.log("data.currentPage=" + data.currentPage);
    fetchTopQuizResults(data.currentPage);
    setCurrentTopQuizListResults(data.currentPage);
  };

  return (
    <React.Fragment>
      {/* <BasicHeader
                        {...props}
            />
         */}

      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{tHydrate("welcome_genre")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li onClick={goToDashboard}>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span> {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {tHydrate("gener")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {genreList.length > 0 ? (
        <GenreList genreList={genreList} {...props} />
      ) : null}

      {suggestedQuizResults.length > 0 ? (
        <SuggestedQuizzes suggestedQuizList={suggestedQuizResults} {...props} />
      ) : null}
      <div class="text-center pt-4">
        {totalSuggestedQuizResults > PAGE_LIMIT ? (
          <div className="d-flex flex-row py-4 align-items-center justify-content-center">
            <Pagination
              totalRecords={totalSuggestedQuizResults}
              totalPages={totalSuggestedQuizResultsPages}
              currentPage={currentSuggestedQuizResults}
              pageLimit={PAGE_LIMIT}
              pageNeighbours={1}
              onPageChanged={onSuggestedQuizResultsPageChanged}
            />
          </div>
        ) : null}
        {/* <a href="javascript:void(0)" class="btn-border mr-2">Save as draft</a> */}
        {/* <a href="javascript:void(0)" class="btn-background">Publish now</a> */}
      </div>

      <div class="advertisement-slider margin-top">
        <div class="container">
          <div class="row">
            {/* 
                <OwlCarousel
                                className="owl-carousel logo_slider"
                                // id="owl-carousel"
                                items="2"
                                autoplay
                                nav
                                dots
                                margin={10}
                                nav
                            >
                    */}
            {adv && adv.length > 0
              ? adv.map((ad) => (
                  <div className="col-md-6 col-sm-12">
                    <a class="item" target="_blank" href={ad.link}>
                      <img
                        src={ADVERTISEMENT_PATH + ad.image}
                        className="mb-2"
                      />
                    </a>
                  </div>
                ))
              : null}
            {/*</OwlCarousel>*/}
          </div>
        </div>
      </div>

      {topQuizListResults.length > 0 ? (
        <TopQuizzes topQuizList={topQuizListResults} {...props} />
      ) : null}
      <div class="text-center pt-4">
        {totalTopQuizListResults > PAGE_LIMIT ? (
          <div className="d-flex flex-row py-4 align-items-center justify-content-center">
            <Pagination
              totalRecords={totalTopQuizListResults}
              totalPages={totalTopQuizResultsPages}
              currentPage={currentTopQuizListResults}
              pageLimit={PAGE_LIMIT}
              pageNeighbours={1}
              onPageChanged={onTopQuizResultsPageChanged}
            />
          </div>
        ) : null}
        {/* <a href="javascript:void(0)" class="btn-border mr-2">Save as draft</a> */}
        {/* <a href="javascript:void(0)" class="btn-background">Publish now</a> */}
      </div>

      <Creators fetchType={1} {...props} />

      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default GenreListingPage;
