/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useEffect, useState } from "react";

import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "../less/bulk_upload.css";
import { tHydrate } from "../Utils/HydrateT";
import { showErrorMessageToUser } from "../Utils/toastr";
import Pagination from "./Pagination";

const PAGE_LIMIT = 20;

function QuestionList(props) {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  console.log("content list", props);
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;
  const token = cookies["access_token"]; //getToken()
  // const languageIdOfThisDeck = props.location.state.primaryLanguageId;
  let defaultLanguage = props.location.state.availableLanguages[0];
  const defaultLangId = defaultLanguage.id;
  let amount;
  if (props.location.state.amount) {
    amount = props.location.state.amount;
  }
  let languages = [];
  //languages.push(defaultLanguage)
  languages = languages.concat(props.location.state.availableLanguages);
  console.log(languages);
  const contentId = props.match.params.quizdeckId;
  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let PROFILE_PIC = awsImageUrl + "/uploads/users/";
  const isPublished = props.location.state.isPublished;
  const [currentLanguageId, setCurrentLanguageId] = useState(globalLang.id);

  const [questionListInThisDeck, setQuestionListInThisDeck] = useState([]);

  //sr
  const [selectedFile, setSelectedFile] = useState(null);
  const [answerMode, setAnswerMode] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [totalPages, setTotalPages] = useState(null);
  const [total, setTotal] = useState(null);
  const [current, setCurrent] = useState(1);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleRadioChange = (e) => {
    setAnswerMode(e.target.value);
  };
  const newAbortSignal = (timeoutMs) => {
    const abortController = new AbortController();
    setTimeout(() => abortController.abort(), timeoutMs || 0);

    return abortController.signal;
  };

  const handleUpload = async () => {
    if (!selectedFile || !answerMode) {
      showErrorMessageToUser(t("bulk_upload_please_select_file"));
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("quiz_deck_id", contentId);
    formData.append("ans_mode_id", answerMode);

    setUploading(true);
    setUploadSuccess(false);

    try {
      const response = await axios.post(
        "/api/questions/upload-bulk-question",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            locale: globalLangCode,
            "x-access-token": "Memo " + token, //getToken(),
          },
          timeout: 900000,
          signal: newAbortSignal(900000),
        }
      );
      setUploading(false);

      if (response.data && response.data[0].msg) {
        // showMessageToUser(response.data[0].msg);
        setUploadSuccess(true);
        // window.location.reload();
        // props.history.push({
        //   pathname: "/contentseditunpublished/" + contentId,
        //   state: {
        //     ...props.location.state,
        //     primaryLanguageId: defaultLangId,
        //   },
        // });
      }
    } catch (error) {
      setUploading(false);
      if (error.response && error.response.data && error.response.data[0].msg) {
        console.log(error.response.data[0].msg);
        console.error("Upload error:", error.response.data[0].msg);
        showErrorMessageToUser(error.response.data[0].msg);
      } else {
        showErrorMessageToUser(
          "Failed to upload. Please refresh the page and try again."
        );
      }
    }
  };

  const publishTheQuizDeck = () => {
    axios({
      method: "post",
      url: "/api/quiz_deck/publish",
      headers: {
        // 'Content-Type' : 'application/x-www-form-urlencoded',
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: qs.stringify({
        quiz_deck_id: props.location.state.quiz_deck_id,
      }),
    })
      .then((response) => {
        console.log("questions in this deck", response.data);
        // setUserSession(token, response.data.user);
        props.history.push({
          pathname: "/home",
          state: {
            ...props.location.state,
          },
        });
      })
      .catch((error) => {
        // removeUserSession();
      });
  };

  const saveasdraft = () => {
    props.history.push({
      pathname: "/home",
      state: {
        ...props.location.state,
      },
    });
  };

  const goToAddQuestionsPage = () => {
    props.history.push({
      pathname: "/addquestions/" + props.location.state.quiz_deck_id,
      state: {
        ...props.location.state,
      },
    });
  };

  const editQuestionAndAnswer = (questionId, question) => {
    axios({
      method: "post",
      url: "/api/answers/list",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        quiz_deck_id: contentId,
        language_id: contentDeckDetails.language_id,
        question_id: questionId,
      }),
    })
      .then((response) => {
        console.log("answer response from ", response.data.data);
        let ansModeId = response.data.data.answers[0].ans_mode_id;
        console.log(ansModeId);
        props.history.push({
          pathname: "/editquestion/" + questionId,
          state: {
            contentId: contentId,
            contentDeckDetails: contentDeckDetails,
            questionDetails: question,
            answerMode: ansModeId,
            isPublished: isPublished,
          },
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  //  useEffect(() => {

  //     axios({
  //         method: 'post',
  //         url: '/api/questions/list',
  //         headers:{
  //             // 'Content-Type' : 'application/x-www-form-urlencoded',
  //             'x-access-token': "Memo "+token,
  //             'locale': 'en',
  //         },
  //         data: qs.stringify({
  //             'quiz_deck_id' : props.location.state.quiz_deck_id,
  //             'language_id':currentLanguageId
  //         })
  //         })
  //         .then(response => {
  //         console.log("questions in this deck", response.data)
  //         setQuestionListInThisDeck(questionListInThisDeck => questionListInThisDeck.concat(response.data.data))
  //         // setUserSession(token, response.data.user);
  //         }).catch(error => {
  //         // removeUserSession();
  //         });
  // }, [])

  useEffect(() => {
    fetchQuestions();
    // setQuestionListInThisDeck([]);
    // axios({
    //   method: "post",
    //   url: "/api/questions/list",
    //   headers: {
    //     // 'Content-Type' : 'application/x-www-form-urlencoded',
    //     "x-access-token": "Memo " + token,
    //     locale: globalLangCode,
    //   },
    //   data: qs.stringify({
    //     quiz_deck_id: props.location.state.quiz_deck_id,
    //     language_id: currentLanguageId,
    //   }),
    // })
    //   .then((response) => {
    //     console.log("questions in this deck", response.data);
    //     setQuestionListInThisDeck((questionListInThisDeck) =>
    //       questionListInThisDeck.concat(response.data.data)
    //     );
    //     // setUserSession(token, response.data.user);
    //   })
    //   .catch((error) => {
    //     // removeUserSession();
    //   });
    axios({
      method: "get",
      url: `/api/question/upload-details/${contentId}`,
      headers: {
        locale: globalLangCode,
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
      },
    })
      .then((response) => {
        console.log("upload details::::", response.data[0].data.status);
        if (response.data && response.data[0].data.status === "pending") {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      })
      .catch((error) => {
        // removeUserSession();
        console.log("error", error);
      });
  }, [currentLanguageId]);

  const createMarkup = (question) => {
    console.log("question.name", question.name);

    return {
      __html: question.name,
    };
  };

  const fetchQuestions = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/questions/list",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: globalLangCode,
      },
      data: qs.stringify({
        quiz_deck_id: contentId,
        language_id: currentLanguageId,
        page: currentPage.toString(),
        limit: PAGE_LIMIT.toString(),
      }),
    })
      .then((response) => {
        console.log("questions list", response.data);
        setTotalPages(response.data.total_pages);
        setTotal(response.data.total);
        setQuestionListInThisDeck(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onPageChanged = (data) => {
    // setLoading(true);
    fetchQuestions(data.currentPage);
    setCurrent(data.currentPage);
  };

  return (
    <React.Fragment>
      {/* <BasicHeader 
                {...props}
           /> */}
      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("question_list")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span> {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  {/* <li><a href="javascript:void(0)" class="active">Create Content</a></li>
                            <li><span class="icon-next"></span></li> */}
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("question_list")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="create-content-wrapper">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-12 col-md-10 col-lg-9">
              <div class="create-content-tabs animated fadeInRight">
                {/* <ul class="nav nav-tabs nav-justified">
                                    {
                                        questionListInThisDeck ?
                                            
                                                questionListInThisDeck.map(question => (
                                                    <div className="question-id">
                                                        <div className="card id-no">
                                                            <div className="card-header">
                                                                {question.id}
                                                            </div>
                                                        </div>
                                                        <div class="card question-title">
                                                            <div class="card-header" data-toggle="collapse" href="#collapseOne">
                                                                <div class="row">
                                                                    <div class="col-sm-9" dangerouslySetInnerHTML={createMarkup(question)}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            )
                                            :
                                            null
                                    }
                                </ul> */}
                {/* <!-- Tab panes --> */}
                <div class="tab-content">
                  <div class="tab-pane container active" id="EnglishTab">
                    <div class="add-quiz-btn" onClick={goToAddQuestionsPage}>
                      <a href="javascript:void(0)">
                        <span class="icon icon-plus blue-bg-btn"></span>{" "}
                        <span>{t("add_questions")}</span>
                      </a>
                    </div>
                    <div
                      class="add-quiz-btn"
                      id="modalOpenButton"
                      data-toggle="modal"
                      data-target="#RateandReview"
                      style={{
                        cursor: isDisabled ? "not-allowed" : "pointer",
                        pointerEvents: isDisabled ? "none" : "auto",
                        opacity: isDisabled ? 0.5 : 1,
                      }}
                    >
                      <a href="javascript:void(0)">
                        <span class="icon icon-upload blue-bg-btn"></span>
                        <span>{t("bulk_upload_q_a")}</span>
                      </a>
                    </div>
                    <div class="create-content-accordion">
                      <div id="accordion" class="accordion">
                        {questionListInThisDeck
                          ? questionListInThisDeck.map((question, index) => (
                              <div className="question-id">
                                <div className="card id-no">
                                  <div className="card-header bg-transparent h-100">
                                    <span>
                                      {(current - 1) * PAGE_LIMIT + index + 1}/
                                    </span>
                                    <span>{question.id}</span>
                                  </div>
                                </div>

                                <div class="card question-title">
                                  <div
                                    class="card-header"
                                    data-toggle="collapse"
                                    onClick={() =>
                                      editQuestionAndAnswer(
                                        question.id,
                                        question
                                      )
                                    }
                                    href="#collapseOne"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-sm-9"
                                        dangerouslySetInnerHTML={createMarkup(
                                          question
                                        )}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center pt-4">
                {total > PAGE_LIMIT ? (
                  <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                    <Pagination
                      totalRecords={total}
                      totalPages={totalPages}
                      currentPage={current}
                      pageLimit={PAGE_LIMIT}
                      pageNeighbours={1}
                      onPageChanged={onPageChanged}
                    />
                  </div>
                ) : null}
              </div>
              <div class="text-center pt-4 add-qa">
                <a
                  onClick={() => saveasdraft()}
                  href="javascript:void(0)"
                  class="btn-border mr-2 blue-bg-btn"
                >
                  {t("save_as_draft")}
                </a>
                <a
                  onClick={() => publishTheQuizDeck()}
                  href="javascript:void(0)"
                  class="btn-background blue-bg-btn"
                >
                  {t("publish_now")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="RateandReview"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="RateandReview"
        aria-hidden="true"
      >
        <div className="modal-dialog flexible-modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t("bulk_upload_q_a")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-header">
              <p>
                <strong>{t("bulk_upload_modal_section1_title")} </strong>

                <p>{t("bulk_upload_modal_section1_paragraph1")}</p>
                <p>{t("bulk_upload_modal_section1_paragraph2")}</p>
                <p className="pl-5">
                  <ol>
                    <li>{t("bulk_upload_modal_section1_text_format_title")}</li>
                    <li>
                      {t("bulk_upload_modal_section1_body_qa_format_title")}
                    </li>
                  </ol>
                </p>
                <p>{t("bulk_upload_modal_section1_paragraph3")}</p>
                <strong>{t("bulk_upload_modal_section2_title")}</strong>
                <p>{t("bulk_upload_modal_section2_paragraph1")}</p>
                <p className="pl-5">
                  <ul>
                    <li>{t("bulk_upload_modal_section2_english")}</li>
                    <li>{t("bulk_upload_modal_section2_japanese")}</li>
                    <li>{t("bulk_upload_modal_section2_chainese")}</li>
                    <li>{t("bulk_upload_modal_section2_hindi")}</li>
                  </ul>
                </p>
                <p>{t("bulk_upload_modal_section2_paragraph2")}</p>
                <strong>{t("bulk_upload_modal_section3_title")}</strong>
                <p className="pl-5">
                  <ul>
                    <li>{t("bulk_upload_modal_section3_bullet1")}</li>
                    <li>{t("bulk_upload_modal_section3_bullet2")}</li>
                  </ul>
                </p>
                <p>{t("bulk_upload_modal_section3_paragraph1")}</p>
                <strong>{t("bulk_upload_modal_section4_title")}</strong>
                <p>{tHydrate("bulk_upload_modal_section4_paragraph1")}</p>
                <p>{t("bulk_upload_modal_section4_paragraph2")}</p>
              </p>
            </div>
            <div className="modal-body">
              <div>
                <div>
                  <input type="file" onChange={handleFileChange} />
                </div>
                <div className="radioContainer" style={{ marginTop: "20px" }}>
                  <label style={{ fontWeight: "bold" }}>
                    {t("bulk_upload_select_answer_type")}
                  </label>
                  <div>
                    <div>
                      <input
                        type="radio"
                        name="answerMode"
                        value="1"
                        onChange={handleRadioChange}
                        style={{ marginRight: "5px" }}
                      />
                      <label>{t("bulk_upload_input_type_answer")}</label>
                      <label className="pl-1">
                        {" "}
                        <a href="/text_input_answer_type.xlsx">
                          <button class="btn-background blue-bg-btn">
                            {" "}
                            {t("bulk_upload_download_sample_file")}
                          </button>
                        </a>
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        value="2"
                        name="answerMode"
                        onChange={handleRadioChange}
                        style={{ marginRight: "5px" }}
                      />
                      <label>{t("bulk_upload_multiple_choice_answer")}</label>
                      <label>
                        {" "}
                        <a href="/multiple_choice_answer_type.xlsx">
                          <button class="btn-background blue-bg-btn">
                            {" "}
                            {t("bulk_upload_download_sample_file")}
                          </button>
                        </a>
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  {uploading && (
                    <label style={{ color: "#294177" }}>
                      {t("bulk_uploading")}...
                    </label>
                  )}
                </div>
                <div>
                  {uploadSuccess && (
                    <label style={{ color: "green" }}>
                      {t("bulk_upload_success_message")}
                    </label>
                  )}
                </div>
                <div className="save-cancel-btn" style={{ marginTop: "15px" }}>
                  <a
                    onClick={handleUpload}
                    href="javascript:void(0)"
                    class="btn-background blue-bg-btn"
                  >
                    {t("bulk_upload_save_button")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <BasicFooter /> */}
      <ToastContainer />
    </React.Fragment>
  );
}

export default QuestionList;
