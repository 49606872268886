/* eslint-disable */
import React from "react";
import { useTranslation } from "react-i18next";

function Suggestions(props) {
  const { t } = useTranslation();

  const goToDashboard = () => {
    props.history.push("/myprofile");
  };

  return (
    <React.Fragment>
      {/* <BasicHeader 
            {...props}
        />  */}

      <div class="my-credits-wrapper">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-8 col-md-6 col-lg-9">
              <h3 class="animated fadeInLeft">{t("suggestions")}</h3>
            </div>
          </div>
        </div>
      </div>
      <section class="gener_gap suggestion-txt">
        <div class="container">
          <div class="col-lg-9 col-md-8 col-sm-12 cust_center">
            <div />
            <p>
              <strong>{t("suggestions_are_welcome")}</strong>
            </p>

            <p
              dangerouslySetInnerHTML={{
                __html: t("suggestions_are_welcome_text"),
              }}
            ></p>

            <p>
              <strong>{t("genre_categories")}</strong>
            </p>

            <p
              dangerouslySetInnerHTML={{ __html: t("genre_categories_text") }}
            ></p>

            <p>
              <strong>{t("contents_suggestion")}</strong>
            </p>

            <p
              dangerouslySetInnerHTML={{
                __html: t("contents_suggestion_text"),
              }}
            ></p>

            <p>
              <strong>{t("bugs_languages_error")}</strong>
            </p>

            <p
              dangerouslySetInnerHTML={{
                __html: t("bugs_languages_error_text"),
              }}
            ></p>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Suggestions;
