/* eslint-disable */

import React, { useContext, useEffect, useState } from "react";
//import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import { useCookies } from "react-cookie";
import { getDeviceUUID, removeUserSession } from "../Utils/Common";
// import "../images/favicon.ico"
import axios from "axios";
import qs from "qs";
import { useTranslation } from "react-i18next";
import dashlogo from "../images/dash-logo.png";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/counter.css";
import "../less/icon-font.css";
import "../less/login.css";
import "../less/menu.css";
import "../less/releway-font.css";

import { Context } from "../Reducers/Store";

let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";

const GraduationIcon = () => {
  const { t } = useTranslation();
  const color = "#294177";
  const white = "#fff";
  const [isHovered, setIsHovered] = useState(false);

  const goToAcademyPage = () => {
    window.open(
      "https://www.memorizeitall.info/memorizeitallacademy",
      "_blank"
    );
  };

  return (
    <span
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <li class="nav-item" onClick={goToAcademyPage}>
        <a
          class="nav-link"
          id="id_profile"
          href="javascript:void(0)"
          data-toggle="tooltip"
          data-placement="top"
          title={t("mita_academy")}
          style={{ display: "flex" }}
        >
          <div style={{ marginTop: "-8px" }}>
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="48px"
              viewBox="0 0 432 432"
              enable-background="red"
            >
              <rect
                width="100%"
                height="100%"
                fill={isHovered ? color : white}
                rx="50%"
                ry="50%"
              />
              <path
                fill={color}
                opacity="1.000000"
                stroke="none"
                d="
M1.000000,237.000000 
	C1.000000,224.312439 1.000000,211.624878 1.268732,198.212921 
	C1.881030,196.572281 2.463624,195.673492 2.532025,194.737137 
	C3.441400,182.288437 6.071177,170.186310 9.481645,158.205231 
	C21.609070,115.601151 44.564377,79.829231 78.455208,51.506920 
	C109.665054,25.425102 145.526886,9.022929 186.085632,3.316243 
	C189.331039,2.859609 193.222122,3.891338 196.000000,1.000000 
	C210.354233,1.000000 224.708450,1.000000 239.804291,1.220547 
	C245.396317,3.298431 250.606155,2.910089 255.599945,3.848678 
	C281.924774,8.796473 306.761505,17.739330 329.903046,31.345442 
	C346.171692,40.910603 360.914825,52.515572 373.983795,65.974854 
	C387.748444,80.150558 399.492828,95.967171 409.024994,113.455109 
	C418.796661,131.382446 425.780151,150.225739 430.431152,170.032074 
	C431.139526,173.048645 430.885620,176.376434 433.000000,179.000000 
	C433.000000,204.020889 433.000000,229.041794 432.975433,254.905365 
	C432.264160,257.617340 431.712555,259.495148 431.319855,261.477905 
	C427.852234,278.985352 422.469574,295.884949 414.672760,311.966187 
	C400.476288,341.246826 381.010559,366.282654 355.634430,386.867188 
	C332.290131,405.803589 306.246246,419.135376 277.456085,427.430542 
	C270.661011,429.388367 263.514587,430.037933 257.000000,433.000000 
	C231.979111,433.000000 206.958206,433.000000 181.206314,432.803772 
	C167.500473,429.339050 154.489365,426.312195 141.863571,421.682129 
	C115.445610,411.994324 92.037315,397.407166 71.361778,378.566742 
	C49.509979,358.654419 32.603039,335.009003 20.471979,307.960449 
	C12.075881,289.239716 6.270012,269.848083 3.373687,249.505280 
	C2.783921,245.362961 3.933269,240.735825 1.000000,237.000000 
M35.624908,135.049805 
	C34.555805,137.482727 33.456718,139.902969 32.422333,142.350571 
	C23.677006,163.043976 18.928064,184.729797 17.508171,207.044815 
	C16.429932,223.990387 17.933382,240.871811 21.337204,257.568695 
	C28.928928,294.808594 45.910885,327.239380 71.516380,355.137146 
	C83.133278,367.794037 96.426796,378.607117 111.029549,387.717560 
	C146.946838,410.125854 185.816910,421.151611 228.381897,418.767609 
	C243.053009,417.945892 257.347626,415.702545 271.465118,411.788147 
	C316.203369,399.383209 352.604645,374.440521 379.923218,337.196289 
	C408.920532,297.663330 422.447021,253.167999 418.775543,203.767471 
	C417.536133,187.090683 414.507874,170.840302 409.188751,155.114395 
	C396.976624,119.009247 376.212616,88.638542 346.924835,64.078148 
	C327.831543,48.066753 306.497925,36.255608 283.029419,28.030293 
	C253.952957,17.839468 224.050415,14.943607 193.659042,18.616941 
	C168.578613,21.648354 144.817184,29.372421 122.614029,41.755226 
	C103.172173,52.598053 85.810410,65.830849 70.759781,82.367050 
	C56.504902,98.028954 44.927063,115.280975 35.624908,135.049805 
z"
              />
              <path
                fill={isHovered ? white : color}
                opacity="1.000000"
                stroke="none"
                d="
M300.898560,201.432739 
	C277.477386,211.288147 254.634262,221.628204 231.231918,230.638321 
	C230.154984,231.052963 229.150269,231.685562 228.163849,232.300018 
	C222.003265,236.137665 215.859940,236.130920 209.216095,233.170059 
	C194.063065,226.417084 178.762756,219.992569 163.489166,213.512680 
	C143.014099,204.826019 122.520729,196.182114 102.003441,187.595764 
	C98.080452,185.954041 96.696136,183.185608 96.546783,179.038712 
	C96.389221,174.663574 98.761299,172.786514 102.245796,171.328491 
	C124.912567,161.843948 147.577560,152.354782 170.210770,142.790558 
	C182.448715,137.619141 194.709396,132.486359 206.793411,126.971497 
	C214.201050,123.590820 221.099792,123.248932 228.682724,126.726425 
	C243.600342,133.567566 258.770721,139.861725 273.880707,146.278183 
	C292.487915,154.179703 311.143860,161.966339 329.766602,169.831406 
	C331.449768,170.542297 333.036743,171.478409 334.681427,172.283310 
	C337.845123,173.831589 339.453278,176.345078 339.319305,179.874237 
	C339.173218,183.722931 337.485504,186.464127 333.688873,187.822952 
	C330.576752,188.936798 327.384247,189.786194 324.522156,191.657516 
	C322.311646,193.102844 321.334595,194.693466 321.345947,197.327759 
	C321.419952,214.493271 321.380737,231.659256 321.385773,248.825089 
	C321.386261,250.489151 321.401489,252.091476 322.656982,253.497513 
	C327.910309,259.380859 329.470459,266.237885 327.479218,273.811920 
	C326.348053,278.114441 327.424103,282.192444 328.896027,285.984436 
	C331.075592,291.599579 331.990753,297.514465 333.820404,303.192047 
	C334.609955,305.642120 335.101685,308.204498 332.898621,310.143555 
	C330.638062,312.133209 327.933167,311.639954 325.361450,310.723236 
	C323.955994,310.222229 322.624420,309.501587 321.285706,308.827972 
	C317.630524,306.988708 314.067993,306.751678 310.354126,308.861145 
	C308.497925,309.915436 306.395721,310.677094 304.309601,311.158752 
	C298.734985,312.445862 295.981201,309.637085 297.250977,304.085571 
	C298.837006,297.151276 300.492157,290.232758 302.120239,283.308075 
	C302.772064,280.535736 302.854675,277.822845 302.374634,274.942596 
	C300.978668,266.566833 300.271729,258.254181 308.923065,252.769379 
	C310.338867,251.871780 310.611908,250.384766 310.607758,248.817505 
	C310.564453,232.487549 310.529297,216.157562 310.470947,199.827682 
	C310.469879,199.530304 310.211334,199.233856 309.948883,198.670975 
	C306.777374,198.483078 304.264313,200.858856 300.898560,201.432739 
z"
              />
              <path
                fill={isHovered ? white : color}
                opacity="1.000000"
                stroke="none"
                d="
M213.678314,241.471603 
	C220.158432,243.471863 225.373535,240.435074 230.654175,238.242279 
	C246.753021,231.557144 262.747437,224.620285 278.856140,217.959473 
	C282.931213,216.274460 286.563477,213.393921 291.129578,212.923615 
	C292.948151,214.702347 292.321411,216.809036 292.331360,218.692505 
	C292.395782,230.850830 292.133362,243.015915 292.462128,255.165680 
	C292.626129,261.227417 290.062897,265.724823 285.437134,268.634125 
	C271.452972,277.429352 256.449951,283.630066 240.012161,286.648956 
	C231.392059,288.232056 222.876099,288.222382 214.267242,288.395874 
	C195.351273,288.777100 178.054657,282.685516 161.238785,274.937622 
	C158.454941,273.654968 155.927933,271.589539 153.149033,270.083374 
	C146.364609,266.406128 143.284744,260.836578 143.532150,252.939438 
	C143.891830,241.458267 143.610458,229.957413 143.640594,218.465057 
	C143.645248,216.690033 143.312820,214.822556 144.779877,212.576736 
	C167.641678,222.189987 190.490784,231.797897 213.678314,241.471603 
z"
              />
            </svg>
          </div>
          <h6 style={{ paddingTop: "12px" }}>{t("mita_academy")}</h6>
        </a>
      </li>
    </span>
  );
};

export function BasicHeader(props) {
  const [state, dispatch] = useContext(Context);
  const [cookies, setCookie, removeCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  // const [connectSocket, getNotificationCount, getInvitaionCount] = useContext(WebSocketContext)

  const { t } = useTranslation();
  //  var MyNav = withImportantStyle('nav');
  //    console.log("header props", props)
  const [activeBar, setActiveBar] = useState("home");

  const user = cookies["user"]; //getUser();
  //console.log(user)
  const user_type_id = user.user_type_id;

  //console.log("user_type_id", user_type_id)
  let globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  if (typeof globalLang == "string") {
    globalLang = JSON.parse(globalLang);
  }
  //console.log('getUserGlobalLanguage()', getUserGlobalLanguage(), typeof (getUserGlobalLanguage()))
  //console.log('globalLang', typeof (globalLang))
  let globalLangCode;
  if (!globalLang) {
    globalLangCode = i18next.language;
  } else {
    globalLangCode = globalLang.lang_code;
  }
  //console.log('Basic Header globalLangCode', globalLangCode)

  const token = cookies["access_token"]; //getToken()

  useEffect(() => {
    setActiveBar(props.activeBar);
  }, []);

  useEffect(() => {
    setMenufromState();
  }, [state]);

  const setMenufromState = () => {
    //anchor_id_elem.className = "nav-link active"
    if (state) {
      let anchor_id = state.CURRENT_MENU_ID; //getActiveAnchor()
      console.log("state.CURRENT_MENU_ID", state.CURRENT_MENU_ID);
      var anchor_id_elem = document.getElementById(anchor_id);
      if (anchor_id_elem) {
        inActiveAll();
        console.log("anchor_id==", anchor_id);
        //console.log('aaaa',document.getElementById("id_played_content").class)

        const skip_nav_link_cls = ["id_contents_page", "id_attributes_page"];
        if (!skip_nav_link_cls.includes(anchor_id))
          anchor_id_elem.classList.add("nav-link");
        anchor_id_elem.classList.add("active");
        if (anchor_id !== "id_home") {
          var anchor_id_home = document.getElementById("id_home");
          if (anchor_id_home) {
            anchor_id_home.class = "nav-link";
          }
        }
      }
    }
  };

  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState({});

  useEffect(() => {
    console.log("oolanguages", languages);
  }, [languages]);
  useEffect(() => {
    console.log("----globalLangCode", globalLangCode);
    updateMetaTags();
    axios({
      method: "get",
      url: "/api/language/list",
      headers: {
        locale: globalLangCode,
      },
    })
      .then((response) => {
        console.log("---languages", response.data.data);

        response.data.data.forEach((languageObj) => {
          console.log("i18next.language", i18next.language);
          if (languageObj.lang_code === i18next.language) {
            console.log("here");
            console.log("languageObj");
            //setUserGlobalLanguage(token, languageObj)
            //setUserLoginLanguage(languageObj)
            setLanguage(languageObj);
          }
        });
        setLanguages(response.data.data);

        console.log("state.CURRENT_MENU_ID", state.CURRENT_MENU_ID);
        // setTimeout(() => getNotificationCount(),2000)
      })
      .catch((error) => {});
  }, []);

  const goToGenrePage = () => {
    props.history.push("/home");
  };

  const goRepeatContentPage = () => {
    props.history.push("/repeatcontentpage");
  };

  const gotoNotificationsPage = () => {
    props.history.push("/notifications");
  };

  const gotoEmailPage = () => {
    props.history.push("/invitations");
  };

  const goToHomePage = () => {
    props.history.push("/myprofile");
  };

  const logoutSession = () => {
    setCookie("access_token", "", { path: "/" });
    setCookie("user", "", { path: "/" });
    axios({
      method: "get",
      url: "/api/user/logout/" + getDeviceUUID(),
      headers: {
        locale: "en",
        "x-access-token": "Memo " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log("logout response", response);
      })
      .catch((error) => {
        //   removeUserSession();
      });
    removeAllCookies();
    removeUserSession();
    props.history.push("/login");
  };
  const removeAllCookies = () => {
    console.log("removeCookie:::");
    removeCookie("memorizeitallCookie");
    removeCookie("access_token");
    removeCookie("user");
    removeCookie("expiry_time");
    removeCookie("global_language");
    removeCookie("local_language");
  };
  const inActiveAll = () => {
    console.log("here");
    var anchor_id_home = document.getElementById("id_home");
    if (anchor_id_home) {
      anchor_id_home.classList.remove("active");
      anchor_id_home.class = "nav-link";
    }
    var anchor_id_played_content = document.getElementById("id_played_content");
    if (anchor_id_played_content) {
      anchor_id_played_content.classList.remove("active");
      anchor_id_played_content.class = "nav-link";
    }
    var anchor_id_my_contents = document.getElementById("id_my_contents");
    if (anchor_id_my_contents) {
      anchor_id_my_contents.classList.remove("active");
      anchor_id_my_contents.class = "nav-link";
    }
    var anchor_id_genre_page = document.getElementById("id_genre_page");
    if (anchor_id_genre_page) {
      anchor_id_genre_page.classList.remove("active");
      anchor_id_genre_page.class = "nav-link";
    }
    var anchor_id_attributes_page =
      document.getElementById("id_attributes_page");
    if (anchor_id_attributes_page) {
      anchor_id_attributes_page.classList.remove("active");
      anchor_id_attributes_page.class = "nav-link";
    }
    var anchor_id_my_groups = document.getElementById("id_my_groups");
    if (anchor_id_my_groups) {
      anchor_id_my_groups.classList.remove("active");
      anchor_id_my_groups.class = "nav-link";
    }
    var anchor_id_contents_page = document.getElementById("id_contents_page");
    if (anchor_id_contents_page) {
      anchor_id_contents_page.classList.remove("active");
      anchor_id_contents_page.class = "nav-link";
    }
    var anchor_id_invite_page = document.getElementById("id_invite_page");
    if (anchor_id_invite_page) {
      anchor_id_invite_page.classList.remove("active");
      anchor_id_invite_page.class = "nav-link";
    }
    var anchor_id_search_page = document.getElementById("id_search_page");
    if (anchor_id_search_page) {
      anchor_id_search_page.classList.remove("active");
      anchor_id_search_page.class = "nav-link";
    }
    var anchor_id_profile = document.getElementById("id_profile");
    if (anchor_id_profile) {
      anchor_id_profile.classList.remove("active");
      anchor_id_profile.class = "nav-link";
    }
    var anchor_id_repeat_contents_page = document.getElementById(
      "id_repeat_contents_page"
    );
    if (anchor_id_repeat_contents_page) {
      anchor_id_repeat_contents_page.classList.remove("active");
      anchor_id_repeat_contents_page.class = "nav-link";
    }
  };

  const gotoSearchPage = () => {
    props.history.push("/search");
  };

  const updateMetaTags = () => {
    // Update title
    console.log("meta_title", t("meta_description"));
    document.title = t("meta_title");

    // Update meta tags
    const metaTags = [
      { name: "description", content: t("meta_description") },
      { name: "keywords", content: t("meta_keywords") },
      { property: "og:title", content: t("meta_ogTitle") },
      { property: "og:description", content: t("meta_ogDescription") },
    ];

    metaTags.forEach((meta) => {
      let element = document.querySelector(
        `meta[${meta.name ? "name" : "property"}="${
          meta.name || meta.property
        }"]`
      );
      if (!element) {
        element = document.createElement("meta");
        if (meta.name) {
          element.setAttribute("name", meta.name);
        } else {
          element.setAttribute("property", meta.property);
        }
        document.head.appendChild(element);
      }
      element.setAttribute("content", meta.content);
    });
  };

  const handelLanguageChange = (language) => {
    console.log("language-metadata");
    //setUserGlobalLanguage(token, language)
    //setUserLoginLanguage(language)
    setLanguage(language);
    updateMetaTags();

    window.location.reload();
  };
  const gotoMyContents = () => {
    props.history.push("/createcontentpage");
  };
  const goToMyFolders = () => {
    props.history.push("/myfolders");
  };
  const showRestrictedMessage = () => {
    console.log("showRestrictedMessage");
    document.getElementById("id-restrictedModal").click();
  };
  const gotoMyPrivateContents = () => {
    console.log("going to my private content");
    props.history.push("/mycontentsprivate");
  };
  const gotoMyGroupContents = () => {
    props.history.push("/mycontentsgroup");
  };
  const gotoMyRepeatContents = () => {
    props.history.push("/repeatcontentpage");
  };
  const gotoAttributesPage = () => {
    axios({
      method: "post",
      url: "/api/quiz_deck/createcheck",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: "en",
      },
    })
      .then((response) => {
        console.log("can create content success", response);
        props.history.push("/createcontent");
        //setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
        console.log("can create content error", error.response);
        document.getElementById("freeCheckModal").click();
      });
  };
  const goToEditContent = () => {
    props.history.push("/mycontentsbuttons");
  };
  const goToMyGroups = () => {
    props.history.push("/mygroups");
  };
  const goToUpdateProfile = () => {
    props.history.push("/updateprofile");
  };
  const gotoMyPlayedContentsPage = (e) => {
    if (e) {
      e.preventDefault();
    }
    axios({
      method: "post",
      url: "/api/my/played/quiz/recentlyplayed",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: "en",
      },
      data: qs.stringify({
        page: "1",
      }),
    })
      .then((response) => {
        let quizzesList = response.data.data;
        console.log("quizzes", quizzesList);

        props.history.push(
          "/contentdetails/" + response.data.data.quiz_deck_id
        );
        //setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
      });
  };

  const goToMyBecomeAPremiumUser = () => {
    props.history.push("/managesubscriptions/" + 3); //3 for premium user
  };

  return (
    <React.Fragment>
      {/* ends here */}

      <section class="navtop animated fadeInDown">
        <div class="container">
          <div class="row">
            <div class="col-6">
              <div class="social-icons">
                <a
                  href="https://www.facebook.com/memorizeitall"
                  target="_blank"
                  class="facebook"
                >
                  <span class="icon-facebook"></span>
                </a>
                <a
                  href="https://twitter.com/memorizeitall"
                  target="_blank"
                  class="twitter"
                >
                  <span class="icon-twitter"></span>
                </a>
                <a
                  href="https://linkedin.com/company/memorizeitall"
                  target="_blank"
                  class="linkedin"
                >
                  <span class="icon-linkedin"></span>
                </a>
                {/* <a href="javascript:void(0)" class="instagram"><span class="icon-instagram"></span></a> */}
              </div>
            </div>
            <div class="col-6 right-drop">
              <div class="language mob_disp">
                {/* <div class="dropdown">
                              <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
                            
                                  {language ?
                                      (
                                          <React.Fragment>

                                              <img  height='30%'  width='30%' src={(COUNTRY_FLAG + language.flag)} alt="Img"></img> 
                                              <span class="marR02">{language.name}</span>
                                          </React.Fragment>)
                                  : null}
                              </a>
                              <Languages languages={languages} onSelectLanguage={handelLanguageChange}/>
                          </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <nav class="navbar navbar-expand-lg navbar-dark memorize-navbar animated fadeInDown">
        <div class="container listItemContainer">
          <a class="navbar-brand" href="" onClick={gotoMyPlayedContentsPage}>
            <img src={dashlogo} />
            {/* <p>{t('app_name')}</p> */}
            <p>
              Memorize<span>it</span>all
            </p>
          </a>

          <div class="language">
            <div class="dropdown">
              <a
                href=""
                onClick={(e) => e.preventDefault()}
                class="dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="true"
              >
                {language ? (
                  <React.Fragment>
                    <img
                      height="30%"
                      width="30%"
                      src={COUNTRY_FLAG + language.flag}
                      alt="Img"
                    ></img>
                    <span class="marR02">{language.name}</span>
                  </React.Fragment>
                ) : null}
              </a>
              <Languages
                languages={languages}
                onSelectLanguage={handelLanguageChange}
              />
            </div>
          </div>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#collapsibleNavbar"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="form-inline">
            <div
              class="collapse navbar-collapse mobile-menu"
              id="collapsibleNavbar"
            >
              <ul class="navbar-nav">
                <li class="nav-item" onClick={goToGenrePage}>
                  <a
                    id="id_genre_page"
                    className="nav-link"
                    href="javascript:void(0)"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t("genere")}
                  >
                    <span class="icon-m-home navLinkItem"></span>
                    <h6>{t("ad_genre")}</h6>
                  </a>
                </li>
                <li class="nav-item" onClick={gotoMyPlayedContentsPage}>
                  <a
                    id="id_played_content"
                    className="nav-link"
                    href="javascript:void(0)"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t("start_memorizing")}
                  >
                    <span class="icon-m-bulb navLinkItem"></span>
                    <h6>{t("start_memorizing")}</h6>
                  </a>
                </li>
                {/* <li class="nav-item"
                                                  onClick={gotoMyContents}
                                              >
                                                  <a id="id_my_contents" className="nav-link" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" title={t('myquiz')}>
                                                      <span class="icon-m-content"></span>
                                                      <h6>My contents</h6>
                                                  </a>
                                              </li> */}
                <div class="login-and-menu">
                  <ul>
                    <li class="dropdown">
                      <a
                        href="javascript:void(0)"
                        id="id_contents_page"
                        class="circle-link logged-in-user-btn dropdown-toggle contentBtn folderGroup"
                        data-toggle="dropdown"
                        aria-expanded="true"
                        title={t("contents")}
                      >
                        <span class="icon-images headerContentIcon navLinkItem"></span>
                        <h6 class="pl-20px">{t("contents")}</h6>
                      </a>
                      <div class="dropdown-menu dropdown-menu-left arrow-top left blue">
                        <ul>
                          <li onClick={gotoMyContents}>
                            <a href="javascript:void(0)">
                              <span class="icon icon-images"></span>
                              {t("quizes")}
                            </a>
                          </li>
                          {user_type_id == 2 ? (
                            <li onClick={showRestrictedMessage}>
                              <a
                                href="javascript:void(0)"
                                data-toggle="modal"
                                data-backdrop="static"
                                data-target="#restrictedModal"
                                id={"id-restrictedModal"}
                              >
                                <span class="icon icon-folder1"></span>
                                {t("my_folders")}
                                <span class="icon-circle-minus1 restrictIcon"></span>
                              </a>
                            </li>
                          ) : (
                            <li onClick={goToMyFolders}>
                              <a href="javascript:void(0)">
                                <span class="icon icon-folder1"></span>
                                {t("my_folders")}
                              </a>
                            </li>
                          )}

                          <li onClick={gotoMyPrivateContents}>
                            <a href="javascript:void(0)">
                              <span class="icon icon-lock"></span>
                              {t("privatequiz")}
                            </a>
                          </li>
                          {user_type_id == 2 ? (
                            <li onClick={showRestrictedMessage}>
                              <a
                                href="javascript:void(0)"
                                data-toggle="modal"
                                data-backdrop="static"
                                data-target="#restrictedModal"
                                id={"id-restrictedModal"}
                              >
                                <span class="icon icon-m-group"></span>
                                {t("group_quiz")}
                                <span class="icon-circle-minus1 restrictIcon"></span>
                              </a>
                            </li>
                          ) : (
                            <li onClick={gotoMyGroupContents}>
                              <a href="javascript:void(0)">
                                <span class="icon icon-m-group"></span>
                                {t("group_quiz")}
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
                <li class="nav-item" onClick={goRepeatContentPage}>
                  <a
                    id="id_repeat_contents_page"
                    className="nav-link"
                    href="javascript:void(0)"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t("repeat")}
                  >
                    <span class="icon-repeating1 navLinkItem"></span>
                    <h6>{t("repeat")}</h6>
                  </a>
                </li>
                {1 == 0 ? (
                  <li class="nav-item" onClick={goToGenrePage}>
                    <a
                      id="id_genre_page"
                      className="nav-link"
                      href="javascript:void(0)"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={t("genere")}
                    >
                      <span class="icon-m-genre navLinkItem"></span>
                      <h6>{t("ad_genre")}</h6>
                    </a>
                  </li>
                ) : null}
                <li class="nav-item" onClick={gotoEmailPage}>
                  <a
                    id="id_invite_page"
                    className="nav-link inboxLink p-relative"
                    href="javascript:void(0)"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t("invitation")}
                  >
                    <span class="icon-inbox1 navLinkItem"></span>
                    {state.CURRENT_INVITATION_COUNT > 0 ? (
                      <span class="notifyNumber">
                        {state.CURRENT_INVITATION_COUNT}
                      </span>
                    ) : null}
                    <h6>{t("invitation")}</h6>
                  </a>
                </li>
                <li class="nav-item" onClick={gotoSearchPage}>
                  <a
                    id="id_search_page"
                    className="nav-link"
                    href="javascript:void(0)"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t("search")}
                  >
                    <span class="icon-m-search navLinkItem"></span>
                    <h6>{t("how_search")}</h6>
                  </a>
                </li>
                {/* <li class="nav-item"
                                                  onClick={gotoAttributesPage}
                                              >
                                                  <a id="id_attributes_page" className="nav-link" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" title={t('createquiz')}>
                                                      <span class="icon-m-create"></span>
                                                      <h6>Create contents</h6>
                                                  </a>
                                              </li> */}
                {/* <li className="nav-item" onClick={gotoSearchPage}>
                                                  <div class="serach-icon">
                                                      <a href="javascript:void(0)" class="top-search-icon"><span class="icon-search"></span></a>
                                                  </div>
                                              </li> */}
                {/* <li class="nav-item" onClick={gotoSearchPage}>
                                                  <a id="id_search_page" className="nav-link top-search-icon icon-search" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" title={t('search')}>
                                                      <span class="icon-m-search"></span>
                                                      <h6>Search</h6>
                                                  </a>
                                              </li> */}
                <div class="login-and-menu">
                  <ul>
                    <li class="dropdown ">
                      <a
                        href="javascript:void(0)"
                        id="id_attributes_page"
                        class="circle-link logged-in-user-btn dropdown-toggle contentBtn"
                        data-toggle="dropdown"
                        aria-expanded="true"
                        title={t("modify_contents")}
                      >
                        <span class="icon-m-create navLinkItem createContentIcon"></span>
                        <h6 class="pl-20px">{t("modify_contents")}</h6>
                      </a>
                      <div class="dropdown-menu dropdown-menu-left arrow-top left blue">
                        <ul>
                          {/* <li class="usernameItem">
                                                                      <a href="javascript:void(0)">
                                                                          <span class="contentUser">
                                                                          <span class="icon-user2"></span>
                                                                          </span>
                                                                  <span class="user">{user.name}</span>
                                                                  
                                                                      </a>
                                                                  </li> */}
                          <li onClick={gotoAttributesPage}>
                            <a href="javascript:void(0)">
                              <span class="icon icon-m-create"></span>
                              {t("createquiz")}
                            </a>
                          </li>
                          <li onClick={goToEditContent}>
                            <a href="javascript:void(0)">
                              <span class="icon icon-m-edit-content"></span>
                              {t("edit_contents")}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
                {user_type_id == 2 ? (
                  <li class="nav-item" onClick={showRestrictedMessage}>
                    <a
                      className="nav-link"
                      href="javascript:void(0)"
                      data-placement="top"
                      title={t("group")}
                      data-toggle="modal"
                      data-backdrop="static"
                      data-target="#restrictedModal"
                      id="id_my_groups"
                    >
                      <div class="position-relative d-inline-block">
                        <span class="icon-m-group navLinkItem"></span>
                        <span class="icon-circle-minus1 restrictIcon"></span>
                      </div>

                      <h6>{t("group")}</h6>
                    </a>
                  </li>
                ) : (
                  <li class="nav-item" onClick={goToMyGroups}>
                    <a
                      id="id_my_groups"
                      className="nav-link"
                      href="javascript:void(0)"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={t("group")}
                    >
                      <div class="position-relative d-inline-block">
                        <span class="icon-m-group navLinkItem"></span>
                      </div>

                      <h6>{t("group")}</h6>
                    </a>
                  </li>
                )}
                <li class="nav-item" onClick={goToHomePage}>
                  {/* <a class="nav-link active" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" title="Home"><span class="icon-m-home"></span></a> */}
                  <a
                    class="nav-link"
                    id="id_profile"
                    href="javascript:void(0)"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t("Profile")}
                  >
                    <span class="icon icon-user headerUserIcon navLinkItem"></span>
                    <h6>{t("Profile")}</h6>
                  </a>
                </li>
                <GraduationIcon />
                <li class="nav-item" onClick={logoutSession}>
                  {/* <a class="nav-link active" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" title="Home"><span class="icon-m-home"></span></a> */}
                  <a
                    class="nav-link"
                    id="id_profile"
                    href="javascript:void(0)"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t("sign_out")}
                  >
                    <span class="icon icon-logout headerUserIcon navLinkItem"></span>
                    <h6>{t("sign_out")}</h6>
                  </a>
                </li>
              </ul>
            </div>
            {/* <div class="login-and-menu">
                                          <ul>
                                              <li style={{ display: "none" }}>
                                                  <a href="javascript:void(0)" class="circle-link login-btn"><span class="icon-login icon"></span><span class="login-txt">Login</span></a>
                                              </li>
                                              <li class="dropdown ">
                                                  <a href="javascript:void(0)" class="circle-link logged-in-user-btn dropdown-toggle" data-toggle="dropdown" aria-expanded="true"><span class="icon-user"></span></a>
                                                  <div class="dropdown-menu dropdown-menu-right arrow-top right blue">
                                                      <div class="user-name">
                                                          <div class="user-icon"><span class="icon-user"></span></div>
                                                          <div>{user_name}</div>
                                                      </div>
                                                      <ul>
                                                          <li onClick={gotoNotificationsPage}><a href="javascript:void(0)"><span class="icon icon-m-notifications"></span>{t("notifications")}</a></li>
                                                          <li onClick={gotoEmailPage}><a href="javascript:void(0)"><span class="icon icon-m-mail"></span>{t("invitation")}</a></li>
                                                          <li onClick={goToHomePage}><a href="javascript:void(0)"><span class="icon icon-m-profile"></span>{t("Profile")}</a></li>
                                                          <li onClick={goToUpdateProfile}><a href="javascript:void(0)"><span class="icon icon-m-profile"></span>{t('editProfile')}</a></li>
                                                          <li onClick={logoutSession}><a href="javascript:void(0)"><span class="icon icon-logout"></span>{t('logout')}</a></li>
                                                      </ul>
                                                  </div>
                                              </li>
                                              
                                              <li style={{ position: "relative" }}>
                                                  <div class="serach-icon"
                                                      onClick={gotoSearchPage}
                                                  >
                                                      <a href="javascript:void(0)" class="top-search-icon"><span class="icon-search"></span></a>
                                                  </div>
                                              </li>

                                          </ul>
                                      </div> */}
          </div>
        </div>
      </nav>

      <p
        class="details-play-quiz"
        id="freeCheckModal"
        data-toggle="modal"
        data-target="#freeCheck"
        style={{ display: "none" }}
      >
        Open Modal
      </p>

      <div
        class="modal fade add-rate-review-info"
        id="freeCheck"
        tabindex="-1"
        role="dialog"
        aria-labelledby="freeCheck"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {t("access_rights")}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="add-rate-review-info">
                <p>
                  <strong>{t("free_user_cannot_create_5_contents")}</strong>
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <div class="save-cancel-btn">
                <a
                  data-dismiss="modal"
                  onClick={goToMyBecomeAPremiumUser}
                  href="javascript:void(0)"
                  class="btn-border blue-bg-btn"
                >
                  {t("become_a_premium_member")}
                </a>
                {/* 
                                  <a
                                      data-dismiss="modal"
                                      // onClick={skipReview} 
                                      href="javascript:void(0)" class="btn-background blue-bg-btn">
                                      {t("skip")}
                                  </a>
                                  */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="restrictedModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Access Restricted
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>{t("upgrade_your_membership")}</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                {t("close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function Languages(props) {
  const [cookies, setCookie, removeCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);
  const setCookieLoginLanguage = (loginLanguage) => {
    let exp = 30 * 24 * 60 * 60;
    var expires = new Date();
    expires.setTime(expires.getTime() + exp * 1000);
    setCookie("login_language", JSON.stringify(loginLanguage), {
      path: "/",
      expires,
    });
  };
  const setCookieGlobalLanguage = (globalLanguage) => {
    let exp = 30 * 24 * 60 * 60;
    var expires = new Date();
    expires.setTime(expires.getTime() + exp * 1000);
    setCookie("global_language", JSON.stringify(globalLanguage), {
      path: "/",
      expires,
    });
  };
  function setLanguage(language) {
    setCookieLoginLanguage(language);
    setCookieGlobalLanguage(language);
    //setUserGlobalLanguage(token, language)
    //setUserLoginLanguage(language)

    i18next.changeLanguage(language.lang_code).then((t) => {});

    props.onSelectLanguage(language);
  }
  return (
    <ul className="dropdown-menu dropdown-menu-right arrow_top">
      {/*
              <li><a href="javascript:void(0)"><span className="sprite-flags china"></span> <span>English</span></a></li>
              <li><a href="javascript:void(0)"><span className="sprite-flags Italy"></span> <span>Italian</span></a></li>
              */}
      {props.languages && props.languages.length > 0
        ? props.languages.map((language, i) => {
            return (
              <li key={i}>
                <a
                  onClick={() => setLanguage(language)}
                  href="javascript:void(0)"
                >
                  <img
                    height="30%"
                    width="30%"
                    src={COUNTRY_FLAG + language.flag}
                    alt="Img"
                  />
                  &nbsp;<span> {language.name}</span>
                </a>
              </li>
            );
          })
        : null}
    </ul>
  );
}

export default BasicHeader;
