/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import qs from "qs";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import BasicFooter from "./BasicFooter";
import BasicHeader from "./BasicHeader";
import Pagination from "./Pagination";

import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { Context } from "../Reducers/Store";

const PAGE_LIMIT = 10;

function MyContentsGroup(props) {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const [state, dispatch] = useContext(Context);
  const [publishedPageShow, setPublishedPageShow] = useState(1);

  const token = cookies["access_token"]; //getToken();
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;
  const [quizzes, setQuizzes] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState();
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [total, setTotal] = useState(null);
  const [current, setCurrent] = useState(1);

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let COUNTRY_FLAG = awsImageUrl + "/uploads/languages/";
  let GROUPS_PATH = awsImageUrl + "/uploads/groups/";

  useEffect(() => {
    dispatch({ type: "UPDATE_CURRENT_MENU", payload: "id_contents_page" });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "get",
      url: "/api/language/list",
      headers: {
        locale: globalLangCode,
      },
    })
      .then((response) => {
        console.log("languages", response.data.data);
        setLanguages(response.data.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (languages.length > 0) {
      setCurrentLanguage(languages[1]);
    }
  }, [languages]);

  useEffect(() => {
    setLoading(true);
    if (currentLanguage) {
      console.log("current language code is", currentLanguage);
      fetchQuizzes();
    }
  }, [currentLanguage]);

  const fetchQuizzes = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/my/groups/quiz/list",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-access-token": "Memo " + token,
        locale: currentLanguage.lang_code,
      },
      data: qs.stringify({
        page: currentPage.toString(),
        limit: PAGE_LIMIT.toString(),
      }),
    })
      .then((response) => {
        let quizzesList = response.data.data;
        setQuizzes(quizzesList);
        setTotalPages(response.data.total_pages);
        setTotal(response.data.total);
        setLoading(false);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (quizzes) {
    }
  }, [quizzes]);

  const goToDashboardPage = () => {
    props.history.push("/myprofile");
  };
  const gotoMyContents = () => {
    console.log("going to mycontent");
    props.history.push("/mycontents");
  };
  const goToContentDetailsPage = (quizdeckid) => {
    props.history.push("/contentdetails/" + quizdeckid);
  };
  const goToGroupDetailsPage = (group_id) => {
    props.history.push("/groupdetails/" + group_id);
  };
  const onPageChanged = (data) => {
    setLoading(true);
    fetchQuizzes(data.currentPage);
    setCurrent(data.currentPage);
  };

  return (
    <React.Fragment>
      <BasicHeader {...props} />
      {loading === false ? (
        <>
          <div class="inner-header">
            <div class="container">
              <div class="row">
                <div class="col-sm-5 col-md-6 col-lg-6">
                  <h2 class="animated fadeInLeft">{t("group_quiz")}</h2>
                </div>
                <div class="col-sm-7 col-md-6 col-lg-6">
                  <div class="breadcrumb-item animated fadeInRight">
                    <ul>
                      <li onClick={goToDashboardPage}>
                        <a href="javascript:void(0)">
                          <span class="icon-home1"></span> {t("home")}
                        </a>
                      </li>
                      <li>
                        <span class="icon-next"></span>
                      </li>
                      <li>
                        <a href="javascript:void(0)" class="active">
                          {t("group_quiz")}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="create-content-wrapper">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-sm-12 col-md-10 col-lg-7">
                  <div class="create-content-tabs animated fadeInRight">
                    {/* <!-- Tab panes --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />

          <div class="create-content-wrapper">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-sm-12 col-md-10 col-lg-7">
                  <div class="create-content-tabs animated fadeInRight">
                    <ul class="nav nav-tabs nav-justified">
                      {languages && currentLanguage
                        ? languages.map((language) => (
                            <li
                              class="nav-item"
                              onClick={() => setCurrentLanguage(language)}
                            >
                              <a
                                href=""
                                data-toggle="tab"
                                class={
                                  language.id == currentLanguage.id
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                              >
                                <img src={COUNTRY_FLAG + language.flag} />
                                <span>{t(language.name)}</span>
                              </a>
                            </li>
                          ))
                        : null}
                    </ul>
                    <div class="mt-4 groupAppetizerList">
                      {quizzes.length > 0
                        ? quizzes.map((quiz) => (
                            <div class="groupAppetizerBox position-relative">
                              <div class="groupDesc">
                                <h4 class="groupName">{quiz.group_name}</h4>
                                <h4 class="groupName">{quiz.name}</h4>
                                <div
                                  class="mb-1"
                                  onClick={() =>
                                    goToContentDetailsPage(quiz.id)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: quiz.description,
                                    }}
                                  ></span>
                                </div>
                              </div>
                              <div class="groupStatus">
                                <div class="d-flex">
                                  <div class="statusLogo ml-1">
                                    <span
                                      class="groupLogo"
                                      onClick={() =>
                                        goToGroupDetailsPage(quiz.group_id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        src={
                                          GROUPS_PATH + quiz.group_profile_pic
                                        }
                                        alt=""
                                        class="img-fluid"
                                      />
                                    </span>
                                    <div class="groupStatusIcon">
                                      <span
                                        class={
                                          quiz.open_close
                                            ? "icon icon-unlock"
                                            : "icon icon-lock"
                                        }
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                  <div class="text-center pt-4">
                    {total > PAGE_LIMIT ? (
                      <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                        <Pagination
                          totalRecords={total}
                          totalPages={totalPages}
                          currentPage={current}
                          pageLimit={PAGE_LIMIT}
                          pageNeighbours={1}
                          onPageChanged={onPageChanged}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          type="ThreeDots"
          color="#294177"
          height={100}
          width={100}
          // timeout={3000} //3 secs
        />
      )}
      <BasicFooter {...props} />
    </React.Fragment>
  );
}

export default MyContentsGroup;
