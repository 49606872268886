/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useEffect, useState } from "react";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import moment from "moment";
import DatePicker from "react-date-picker";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/inner-pages.css";
import "../less/releway-font.css";
import Pagination from "./Pagination";

import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SalesReport(props) {
  const PAGE_LIMIT = 10;

  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const token = cookies["access_token"]; //getToken();

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;

  const user = cookies["user"]; //getUser();
  //console.log('user',user)
  const user_type = user.user_type_id;
  const user_name = user.name;
  const user_id = user.id;

  const [totalPagesInDateWise, setTotalPageInDateWise] = useState();
  const [totalSalesInDateWise, setTotalSalesInDateWise] = useState();
  const [totalSalesDateData, setTotalSalesDateData] = useState([]);
  const [totalAmountInDateWiseData, setTotalAmountInDateWiseData] = useState();
  const [chosenFromDate, setChosenFromDate] = useState(new Date());
  const [chosenToDate, setChosenToDate] = useState(new Date());

  //---------------------------- datewiseContent tab state variables ----------------------------
  //State variables for datewiseContent request main variables
  const [grandAmount, setGrandAmount] = useState(0);
  const [grandProfitAmount, setGrandProfitOnContent] = useState(0);

  //State variables for sales datewiseContent
  const [chosenToDateDatewiseContent, setChosenToDateDatewiseContent] =
    useState(new Date());
  const [chosenFromDateDatewiseContent, setChosenFromDateDatewiseContent] =
    useState(new Date());
  const [totalDatewiseContentResults, setTotalDatewiseContentResults] =
    useState(null);
  const [totalDatewiseContentPages, setDatewiseContentPages] = useState(null);
  const [currentDatewiseContentPage, setCurrentDatewiseContentPage] =
    useState(1);
  const [datewiseContentResults, setDatewiseContentResults] = useState([]);

  //---------------------------- By Date Sales tab state variables ----------------------------
  //State variables for datewiseContent request main variables
  const [byDateGrandAmount, setByDateGrandAmount] = useState(0);
  const [byDateGrandProfitAmount, setByDateGrandProfitAmount] = useState(0);

  //State variables for sales byDate
  const [chosenToDateByDate, setChosenToDateByDate] = useState(new Date());
  const [chosenFromDateByDate, setChosenFromDateByDate] = useState(new Date());

  const [totalByDateSalesResults, setTotalByDateSalesResults] = useState(null);
  const [totalByDateSalesPages, setByDateSalesPages] = useState(null);
  const [currentByDatePage, setCurrentByDatePage] = useState(1);
  const [byDateSalesResults, setByDateSalesResults] = useState([]);

  //--------------------------- By Balance Sales tab state variables --------------------------
  const [totalByBalanceResults, setTotalByBalanceResults] = useState(null);
  const [totalByBalancePages, setByBalancePages] = useState(null);
  const [currentByBalancePage, setCurrentByBalancePage] = useState(1);
  const [byBalanceResults, setByBalanceResults] = useState([]);
  const [byBalanceDate, setByBalanceDate] = useState(null);
  const [byTransferBalance, setByTransferBalance] = useState(null);
  const [transferCurrencySymbol, setTransferCurrencySymbol] = useState(null);
  const [canCreateTransfer, setCanCreateTransfer] = useState(false);

  //transfer
  const [transferClicked, setTransferClicked] = useState(0);
  const [transfer_id, setTransfer_id] = useState(0);

  const showErrorMessageToUser = (msg) => {
    console.log("here", msg);
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const changeFromDateDatewiseContent = (date) => {
    setChosenFromDateDatewiseContent(date);
  };

  const changeToDateDatewiseContent = (date) => {
    setChosenToDateDatewiseContent(date);
  };
  const fetchDateWiseContent = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/creators/sales/datewise/content",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        seller_id: user_id,
        from_date: moment(chosenFromDateDatewiseContent)
          .format("YYYY-MM-DD")
          .toString(),
        to_date: moment(chosenToDateDatewiseContent)
          .format("YYYY-MM-DD")
          .toString(),
        page: currentPage.toString(),
      }),
    })
      .then((response) => {
        console.log("content wise response", response);
        setDatewiseContentResults([]);
        setDatewiseContentResults((datewiseContentResults) =>
          datewiseContentResults.concat(response.data.data)
        );
        setGrandAmount(response.data.grand_amount);
        setGrandProfitOnContent(response.data.grand_profit_on_content);
        setTotalDatewiseContentResults(response.data.total);
        setDatewiseContentPages(response.data.total_pages);
        setCurrentDatewiseContentPage(response.data.current_page);

        console.log("DatewiseContentResults", response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  useEffect(() => {
    console.log("totalDatewiseContentPages", totalDatewiseContentPages);
  }, [totalDatewiseContentPages, currentDatewiseContentPage]);

  const onDateWiseContentPageChanged = (data) => {
    fetchDateWiseContent(data.currentPage);
    setCurrentDatewiseContentPage(data.currentPage);
  };

  const applyByContent = () => {
    fetchDateWiseContent();
  };

  const changeFromDate = (date) => {
    setChosenFromDate(date);
  };

  const changeToDate = (date) => {
    setChosenToDate(date);
  };

  const fetchByDateSales = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/creators/sales/bydate",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        seller_id: user_id,
        from_date: moment(chosenFromDateByDate).format("YYYY-MM-DD").toString(),
        to_date: moment(chosenToDateByDate).format("YYYY-MM-DD").toString(),
        page: currentPage.toString(),
      }),
    })
      .then((response) => {
        console.log("content wise response", response);
        setByDateGrandAmount(response.data.grand_amount);
        setByDateGrandProfitAmount(response.data.grand_profit_on_content);
        setTotalByDateSalesResults(response.data.total);
        setByDateSalesPages(response.data.total_pages);

        setByDateSalesResults([]);
        setByDateSalesResults((byDateSalesResults) =>
          byDateSalesResults.concat(response.data.data)
        );
        console.log("byDateSalesResults", response.data.data);
        //setCurrentDatewiseContentPage(currentPage)
      })
      .catch((error) => {
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  const onByDatePageChanged = (data) => {
    fetchByDateSales(data.currentPage);
    setCurrentByDatePage(data.currentPage);
  };

  const applyByDate = () => {
    fetchByDateSales();
  };

  const changeFromDateByDate = (date) => {
    setChosenFromDateByDate(date);
  };

  const changeToDateByDate = (date) => {
    setChosenToDateByDate(date);
  };
  //byBalance Sales Report functions
  const fetchByBalanceSales = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/creators/sales/bybalance",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        seller_id: user_id,
        page: currentPage.toString(),
      }),
    })
      .then((response) => {
        console.log("content wise response", response);
        setTotalByBalanceResults(response.data.total);
        setByBalancePages(response.data.total_pages);
        setByBalanceResults([]);
        setByBalanceResults((byBalanceResults) =>
          byBalanceResults.concat(response.data.data)
        );
        setByBalanceDate(response.data.current_date);
        setByTransferBalance(response.data.transfer_balance);
        setTransferCurrencySymbol(response.data.currency_symbol);
        setCanCreateTransfer(response.data.can_create_transfer);
        console.log("byBalanceResults", response.data.data);
      })
      .catch((error) => {
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };
  useEffect(() => {
    fetchByBalanceSales();
  }, []);

  const onByBalancePageChanged = (data) => {
    fetchByBalanceSales(data.currentPage);
    setCurrentByBalancePage(data.currentPage);
  };

  const onTransferConformClick = async (transfer_id) => {
    console.log("onTransferConformClick", transfer_id);
    //1. API call to onPayoutConformClick
    axios({
      method: "post",
      url: "/api/transfer/confirm",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        transfer_id: transfer_id,
      }),
    })
      .then((response) => {
        fetchByBalanceSales();
        setTransferClicked(0);
        console.log("transfer/confirm response", response);
      })
      .catch((error) => {
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  const onTransferCancelClick = async (transfer_id) => {
    console.log("onTransferCancelClick", transfer_id);
    //1. API call to cancelPayout
    axios({
      method: "post",
      url: "/api/transfer/cancel",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
      data: qs.stringify({
        transfer_id: transfer_id,
      }),
    })
      .then((response) => {
        fetchByBalanceSales();
        setTransferClicked(0);
        console.log("transfer/cancel response", response);
      })
      .catch((error) => {
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  const onTransferClick = async () => {
    //1. API call to CreateTransfer
    axios({
      method: "post",
      url: "/api/transfer/create",
      headers: {
        locale: globalLangCode,
        "x-access-token": "Memo " + token,
      },
    })
      .then((response) => {
        console.log("transfer/create response", response);
        //2. Set the transfer_id
        setTransfer_id(response.data.transfer_id);
        setTransferClicked(1);
      })
      .catch((error) => {
        showErrorMessageToUser(error.response.data[0].msg);
      });
  };

  const quizDetails = (quizdeckid) => {
    props.history.push("/contentdetails/" + quizdeckid);
  };

  const goToDashboardPage = () => {
    props.history.push("/myprofile");
  };

  const getDate = (dateInISO) => {
    let date = new Date(dateInISO);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    return year + "/" + month + "/" + dt;
  };

  return (
    <React.Fragment>
      {/* <BasicHeader 
                {...props}
           /> */}
      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("sales_report")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li onClick={goToDashboardPage}>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span> {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("sales_report")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="innerpage-wrapper">
        <div class="container salesTab my-4">
          <div class="row justify-content-center">
            <div class="col-sm-12">
              <div class="row justify-content-center">
                <div class="col-sm-12 col-md-10 col-lg-7">
                  <ul
                    class="nav nav-tabs nav-tabs-dropdown responsive-tabs"
                    id="navbarSupportedContent"
                  >
                    <li class="nav-item">
                      <a
                        href="#contentTab"
                        data-toggle="tab"
                        class="nav-link active"
                      >
                        {t("by_content")}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#dateTab" data-toggle="tab" class="nav-link">
                        {t("by_date")}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#balanceTab" data-toggle="tab" class="nav-link">
                        {t("by_balance")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="tab-content">
                <div class="tab-pane active" id="contentTab">
                  <div class="salesInfo animated fadeInRight">
                    <div class="salesDateSelection mb-4">
                      <div class="salesHeading">
                        <h3>{t("sales_report_by_content")}</h3>
                      </div>
                      <div class="dateSelectionBox">
                        <p class="salesBlueText">{t("date")}</p>
                        <div class="mb-1">
                          <span class="salesBlueText d-inline-block pr-1 mw-48px">
                            {t("date_from")}
                          </span>
                          <span>
                            <DatePicker
                              onChange={changeFromDateDatewiseContent}
                              value={chosenFromDateDatewiseContent}
                              maxDate={new Date()}
                            />
                          </span>
                        </div>
                        <div class="mb-1">
                          <span class="salesBlueText d-inline-block pr-1 mw-48px">
                            {t("date_to")}
                          </span>
                          <span>
                            <DatePicker
                              onChange={changeToDateDatewiseContent}
                              value={chosenToDateDatewiseContent}
                              maxDate={new Date()}
                            />
                          </span>
                        </div>
                        <div class="my-3">
                          <button
                            class="blueBgBtn w-100"
                            onClick={applyByContent}
                          >
                            {t("apply")}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="salesProfit mb-4">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row mb-2">
                            <div class="col-8 col-sm-8">
                              <h3 class="salesBlueText">{t("total_sales")}</h3>
                            </div>
                            <div class="col-4 col-sm-4 balanceColumn">
                              <h3 class="salesBlueText">{grandAmount}</h3>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-8 col-sm-8 balanceNum">
                              <h3 class="salesBlueText">
                                {t("total_earnings")}
                              </h3>
                            </div>
                            <div class="col-4 col-sm-4 payoutBtn">
                              <h3 class="salesBlueText">{grandProfitAmount}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="salesContentBox">
                      <table class="mainHeaderTable">
                        <thead>
                          <tr>
                            <th>{t("content_id_and_title")}</th>
                            <th class="text-right">{t("quantity")}</th>
                            <th class="text-right">{t("price_credits")}</th>
                            <th class="text-right">{t("your_earnings")}</th>
                          </tr>
                        </thead>
                      </table>
                      {datewiseContentResults ? (
                        <table class="mainTable">
                          {datewiseContentResults.map((item, index) => (
                            <React.Fragment>
                              <tr>
                                <td class="pa-sm-0">
                                  <table class="contentTotalTable">
                                    <tr>
                                      <td
                                        data-label="Content ID"
                                        colspan="6"
                                        class="contentIdCell font-weight-500"
                                      >
                                        {item.quiz_deck_id}
                                      </td>
                                    </tr>
                                    <tr class="contentTotalRow">
                                      <td data-label="Title">
                                        {item.name_translated
                                          ? item.name_translated
                                          : item.name}
                                      </td>
                                      <td
                                        data-label="Quantity"
                                        class="text-right"
                                      >
                                        {item.total_count_of_content}
                                      </td>
                                      <td
                                        data-label="Price(Credits)"
                                        class="text-right"
                                      >
                                        {item.total_amount_of_content}
                                      </td>
                                      <td
                                        data-label="Earnings"
                                        class="text-right"
                                      >
                                        {item.total_profit_of_content}
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td class="pa-sm-6px">
                                  <table class="purchaseOuterTable">
                                    <tr>
                                      <td class="d-noneCell"></td>
                                      <td class="pa-sm-0">
                                        <table class="purchaseInnerTable">
                                          <thead>
                                            <tr>
                                              <th>{t("date")}</th>
                                              <th>{t("purchaser_id")}</th>
                                              <th class="text-right"></th>
                                              <th class="text-right"></th>
                                              <th class="text-right"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {item.contentWiseData.length
                                              ? item.contentWiseData.map(
                                                  (content, index) => (
                                                    <tr>
                                                      <td data-label="Date">
                                                        {getDate(
                                                          content.purchase_date
                                                        )}
                                                      </td>
                                                      <td data-label="Purchaser ID">
                                                        {content.purchaser_id}
                                                      </td>
                                                      <td
                                                        data-label="Quantity"
                                                        class="text-right"
                                                      >
                                                        1
                                                      </td>
                                                      <td
                                                        data-label="Price(Credits)"
                                                        class="text-right"
                                                      >
                                                        {content.amount}
                                                      </td>
                                                      <td
                                                        data-label="Earnings"
                                                        class="text-right"
                                                      >
                                                        {
                                                          content.creators_profit
                                                        }
                                                      </td>
                                                    </tr>
                                                  )
                                                )
                                              : null}
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </table>
                      ) : null}
                      <div class="nav-direction">
                        {totalDatewiseContentResults > PAGE_LIMIT &&
                        totalDatewiseContentPages ? (
                          <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                            {/*<Pagination totalRecords={totalDatewiseContentResults} totalPages={totalDatewiseContentPages} currentPage={currentDatewiseContentPage} pageLimit={PAGE_LIMIT} pageNeighbours={1} onPageChanged={onDateWiseContentPageChanged} /> */}
                            <Pagination
                              totalRecords={totalDatewiseContentResults}
                              totalPages={totalDatewiseContentPages}
                              currentPage={currentDatewiseContentPage}
                              pageLimit={PAGE_LIMIT}
                              pageNeighbours={1}
                              onPageChanged={onDateWiseContentPageChanged}
                            />
                          </div>
                        ) : null}
                      </div>
                      {/* 
                                <table class="mainTable">
                                    
                                    <tr>
                                        <td class="pa-sm-0">
                                            <table class="contentTotalTable">
                                                <tr>
                                                    <td data-label="Content ID" colspan="6" class="contentIdCell">23453</td>
                                                </tr>
                                                <tr class="contentTotalRow">
                                                    <td data-label="Title">Memorize Drivers Licence content</td>
                                                    <td data-label="Quantity" class="text-right">3</td>
                                                    <td data-label="Price(Credits)" class="text-right">30</td>
                                                    <td data-label="Earnings" class="text-right">9</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pa-sm-6px">
                                            <table class="purchaseOuterTable">
                                                <tr>
                                                    <td class="d-noneCell"></td>
                                                    <td class="pa-sm-0">
                                                        <table class="purchaseInnerTable">
                                                            <thead>
                                                                <tr>
                                                                    <th>Date</th>
                                                                    <th>Purchaser ID</th>
                                                                    <th class="text-right"></th>
                                                                    <th class="text-right"></th>
                                                                    <th class="text-right"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td data-label="Date">2021/1/2</td>
                                                                    <td data-label="Purchaser ID">45678</td>
                                                                    <td data-label="Quantity" class="text-right">1</td>
                                                                    <td data-label="Price(Credits)" class="text-right">10</td>
                                                                    <td data-label="Earnings" class="text-right">3</td>
                                                                </tr>
                                                                <tr>
                                                                    <td data-label="Date">2021/1/2</td>
                                                                    <td data-label="Purchaser ID">45678</td>
                                                                    <td data-label="Quantity" class="text-right">1</td>
                                                                    <td data-label="Price(Credits)" class="text-right">10</td>
                                                                    <td data-label="Earnings" class="text-right">3</td>
                                                                </tr>
                                                                <tr>
                                                                    <td data-label="Date">2021/1/2</td>
                                                                    <td data-label="Purchaser ID">45678</td>
                                                                    <td data-label="Quantity" class="text-right">1</td>
                                                                    <td data-label="Price(Credits)" class="text-right">10</td>
                                                                    <td data-label="Earnings" class="text-right">3</td>
                                                                </tr>
                                                            </tbody>
                                                        
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                        
                                    </tr>
                                </table>
                            */}
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="dateTab">
                  <div class="salesInfo animated fadeInRight">
                    <div class="salesDateSelection mb-4">
                      <div class="salesHeading">
                        <h3>{t("sales_report_by_date")}</h3>
                      </div>
                      <div class="dateSelectionBox">
                        <p class="salesBlueText">{t("date")}</p>
                        <div class="mb-1">
                          <span class="salesBlueText d-inline-block pr-1 mw-48px">
                            {t("date_from")}
                          </span>
                          <span>
                            <DatePicker
                              onChange={changeFromDateByDate}
                              value={chosenFromDateByDate}
                              maxDate={new Date()}
                            />
                          </span>
                        </div>
                        <div class="mb-1">
                          <span class="salesBlueText d-inline-block pr-1 mw-48px">
                            {t("date_to")}
                          </span>
                          <span>
                            <DatePicker
                              onChange={changeToDateByDate}
                              value={chosenToDateByDate}
                              maxDate={new Date()}
                            />
                          </span>
                        </div>
                        <div class="my-3">
                          <button class="blueBgBtn w-100" onClick={applyByDate}>
                            {t("apply")}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="salesProfit mb-4">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row mb-2">
                            <div class="col-8 col-sm-8">
                              <h3 class="salesBlueText">{t("total_sales")}</h3>
                            </div>
                            <div class="col-4 col-sm-4 balanceColumn">
                              <h3 class="salesBlueText">{byDateGrandAmount}</h3>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-8 col-sm-8 balanceNum">
                              <h3 class="salesBlueText">{t("total_profit")}</h3>
                            </div>
                            <div class="col-4 col-sm-4 payoutBtn">
                              <h3 class="salesBlueText">
                                {byDateGrandProfitAmount}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <table class="table saleCreditTable salesDateTable">
                      <thead>
                        <tr>
                          <th>{t("date")}</th>
                          <th>{t("content_id")}</th>
                          <th>{t("content_title")}</th>
                          <th class="text-right">{t("quantity")}</th>
                          <th class="text-right">{t("price")}</th>
                          <th class="text-right">{t("profit")}</th>
                        </tr>
                      </thead>
                      {byDateSalesResults ? (
                        <tbody>
                          {byDateSalesResults.map((item, index) => (
                            <tr>
                              <td data-label="Date" class="dateCell">
                                {item.purchase_date
                                  ? getDate(item.purchase_date)
                                  : null}
                              </td>
                              <td id="dynamicCell" colspan="5">
                                <table class="dynamicTable w-100">
                                  {item.dateWiseData.length
                                    ? item.dateWiseData.map(
                                        (datewise, index) => (
                                          <tr>
                                            <td data-label="Content ID">
                                              {datewise.quiz_deck_id}
                                            </td>
                                            <td data-label="Content Title">
                                              {datewise.name_translated
                                                ? datewise.name_translated
                                                : datewise.name}
                                            </td>
                                            <td
                                              data-label="Quantity"
                                              class="text-right"
                                            >
                                              1
                                            </td>
                                            <td
                                              data-label="Price"
                                              class="text-right"
                                            >
                                              {datewise.amount}
                                            </td>
                                            <td
                                              data-label="Profit"
                                              class="text-right"
                                            >
                                              {datewise.creators_profit}
                                            </td>
                                          </tr>
                                        )
                                      )
                                    : null}
                                  {/* 
                                            <tr>
                                                <td data-label="Content ID">8777</td>
                                                <td data-label="Content Title">Knowledge Hunt</td>
                                                <td data-label="Quantity" class="text-right">2</td>
                                                <td data-label="Price" class="text-right">9</td>
                                                <td data-label="Profit" class="text-right">2.7</td>
                                            </tr>
                                            */}
                                </table>
                              </td>
                            </tr>
                          ))}

                          {/*
                                <tr>
                                    <td data-label="Date" class="dateCell">
                                        2021/1/2
                                    </td>
                                    <td id="dynamicCell" colspan="5">
                                        <table class="dynamicTable w-100">
                                            <tr>
                                                <td data-label="Content ID">4567</td>
                                                <td data-label="Content Title">Memorize the periodic table</td>
                                                <td data-label="Quantity" class="text-right">1</td>
                                                <td data-label="Price" class="text-right">9</td>
                                                <td data-label="Profit" class="text-right">2.7</td>
                                            </tr>
                                            <tr>
                                                <td data-label="Content ID">8777</td>
                                                <td data-label="Content Title">Knowledge Hunt</td>
                                                <td data-label="Quantity" class="text-right">2</td>
                                                <td data-label="Price" class="text-right">9</td>
                                                <td data-label="Profit" class="text-right">2.7</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                */}
                        </tbody>
                      ) : null}
                    </table>
                    <div class="nav-direction">
                      {totalByDateSalesResults > PAGE_LIMIT &&
                      totalByDateSalesPages ? (
                        <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                          <Pagination
                            totalRecords={totalByDateSalesResults}
                            totalPages={totalByDateSalesPages}
                            currentPage={currentByDatePage}
                            pageLimit={PAGE_LIMIT}
                            pageNeighbours={1}
                            onPageChanged={onByDatePageChanged}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div class="tab-pane" id="balanceTab">
                  <div class="salesInfo animated fadeInRight">
                    <div class="row mb-3">
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="salesCard">
                          <div class="creatorDetails">
                            <div class="creatorIconArea">
                              <span class="creatorIcon icon-brain"></span>
                              <div class="reportId">
                                <span>ID:</span>
                                <span>{user.id}</span>
                              </div>
                            </div>
                            <div class="creatorInfo">
                              <h6 class="salesBlueText">{t("creator")}:</h6>
                              <span class="d-block">{user.name} </span>
                              <span class="d-block">
                                {user.kyc_bank_details
                                  ? user.kyc_bank_details.address
                                  : null}
                              </span>
                              <span class="d-block">
                                {user.kyc_bank_details
                                  ? user.kyc_bank_details.bank_country
                                  : null}{" "}
                                {user.kyc_bank_details
                                  ? user.kyc_bank_details.zip_code
                                  : null}
                              </span>
                              <span class="d-block">
                                Tel:+{user.dial_code} {user.phone_no}
                              </span>
                              <span>
                                {t("email")}:{user.email_id}
                              </span>
                            </div>
                            {/*
                                                
                                            <div class="creatorInfo">
                                                <h6 class="salesBlueText">Creator:</h6>
                                                <span class="d-block">Kim Pederson</span>
                                                <span class="d-block">2-3-2-4416 Harumi,Chuo-ku</span>
                                                <span class="d-block">Tokyo 104-0053, Japan</span>
                                                <span class="d-block">Tel:+81 080 7797 5296</span>
                                                <span>Email:info@japanexpert.eu</span>
                                            </div>
                                             */}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="salesCard">
                          <h6 class="salesBlueText">
                            {t("to_be_paid_to_below_bank_account")}:
                          </h6>
                          <div>
                            <span class="pr-1">{t("bank_name")}:</span>
                            <span>
                              {user.kyc_bank_details
                                ? user.kyc_bank_details.bank_name
                                : null}
                            </span>
                          </div>
                          <div>
                            <span class="pr-1">{t("branch_name")}:</span>
                            <span>
                              {user.kyc_bank_details
                                ? user.kyc_bank_details.branch_name
                                : null}
                            </span>
                          </div>
                          <div>
                            <span class="pr-1">{t("account_number")}:</span>
                            <span>
                              {user.kyc_bank_details
                                ? user.kyc_bank_details.account_number
                                : null}
                            </span>
                          </div>
                          <div>
                            <span class="pr-1">{t("iban")}:</span>
                            <span>
                              {user.kyc_bank_details
                                ? user.kyc_bank_details.iban
                                : null}
                            </span>
                          </div>
                          <div>
                            <span class="pr-1">{t("swift_number")}:</span>
                            <span>
                              {user.kyc_bank_details
                                ? user.kyc_bank_details.swift_code
                                : null}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="balanceData">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row mb-2">
                            <div class="col-6 col-sm-6">
                              <h3 class="salesBlueText">{t("by_balance")}</h3>
                            </div>
                            <div class="col-6 col-sm-6 balanceColumn">
                              <h3 class="salesBlueText">
                                {byBalanceDate ? getDate(byBalanceDate) : null}
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-6 col-sm-6 balanceNum">
                              <h3 class="salesBlueText">
                                {byTransferBalance} {transferCurrencySymbol}
                              </h3>
                            </div>
                            <div class="col-6 col-sm-6 payoutBtn">
                              {parseFloat(byTransferBalance) > 0 &&
                              transferClicked == 0 &&
                              canCreateTransfer == true ? (
                                <button
                                  class="payoutRedBgButton"
                                  onClick={onTransferClick}
                                >
                                  {t("transfer")}
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="paymentRequestInfo"
                      style={{ display: transferClicked ? "block" : "none" }}
                    >
                      <div class="mt-2">
                        <h6 class="pr-1 d-inline-block salesBlueText font-weight-bold">
                          {t("your_payout_request_no")}:
                        </h6>
                        <span>{transfer_id}</span>
                      </div>
                      <div class="my-4">
                        {t("you_have_requested_payout_of_the_above_credits")}
                      </div>
                      <div class="my-4">{t("if_you_have_any_questions")}</div>
                    </div>
                    <div
                      class="payoutBtns"
                      style={{ display: transferClicked ? "block" : "none" }}
                    >
                      <button
                        class="blue-bg-btn"
                        onClick={() => onTransferCancelClick(transfer_id)}
                      >
                        {t("cancel_transfer_request")}
                      </button>
                      <button
                        class="red-bg-btn ml-4"
                        onClick={() => onTransferConformClick(transfer_id)}
                      >
                        {t("confirm_transfer_request")}
                      </button>
                    </div>

                    {byBalanceResults && transferClicked == 0 ? (
                      <div class="salesBalanceTable mt-4">
                        <table class="table saleCreditTable">
                          <thead>
                            <tr>
                              <th>{t("date")}</th>
                              <th>{t("product")}</th>
                              <th>{t("transfer_id")}</th>
                              <th>{t("transfer_amount")}</th>
                              <th>{t("transfer_status")}</th>
                              <th>{t("action")}</th>
                              <th class="text-right">{t("transaction")}</th>
                              <th class="text-right">{t("by_balance")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {byBalanceResults.map((item, index) => (
                              <React.Fragment>
                                <tr>
                                  <td data-label="Date">
                                    <span class="d-inline-block pr-1">
                                      {item.date_of_transaction
                                        ? getDate(item.date_of_transaction)
                                        : null}
                                    </span>
                                  </td>
                                  <td data-label="Product">
                                    {item.sales_or_transfer}
                                  </td>
                                  <td data-label="Product">
                                    {item.transfer_id}
                                  </td>
                                  <td data-label="Product">
                                    {item.amount} {item.currency_symbol}
                                  </td>
                                  <td data-label="Product">
                                    {item.status_string}
                                  </td>
                                  <td data-label="Product">
                                    {/* { item.status == 1   
                                                            ? <button class="payoutRedBgButton" onClick={()=>onTransferCancelClick(item.transfer_id)}>Cancel</button> 
                                                            : null} */}
                                  </td>
                                  <td
                                    data-label="Transaction"
                                    class="text-right"
                                  >
                                    {item.trxn_type == 1 ? " " : "-"}{" "}
                                    {item.credits}
                                  </td>
                                  <td data-label="Balance" class="text-right">
                                    {item.updated_balance}
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>

                          {/*
                                    <tbody>
                                        <tr>
                                            <td data-label="Date">
                                                <span class="d-inline-block pr-1">2021/3/21</span>
                                            </td>
                                            <td data-label="Product">
                                                Sales
                                            </td>
                                            <td data-label="Transaction" class="text-right">
                                                195
                                            </td>
                                            <td data-label="Balance" class="text-right">195</td>
                                        </tr>
                                        <tr>
                                            <td data-label="Date">
                                                <span class="d-inline-block pr-1">2021/2/1</span>
                                            </td>
                                            <td data-label="Product">
                                                Payout
                                            </td>
                                            <td data-label="Transaction" class="text-right">
                                                -100
                                            </td>
                                            <td data-label="Balance" class="text-right">0</td>
                                        </tr>
                                        <tr>
                                            <td data-label="Date">
                                                <span class="d-inline-block pr-1">2021/1/29</span>
                                            </td>
                                            <td data-label="Product">
                                                Sales
                                            </td>
                                            <td data-label="Transaction" class="text-right">
                                                26
                                            </td>
                                            <td data-label="Balance" class="text-right">100</td>
                                        </tr>
                                        <tr>
                                            <td data-label="Date">
                                                <span class="d-inline-block pr-1">2021/1/11</span>
                                            </td>
                                            <td data-label="Product">
                                                Sales
                                            </td>
                                            <td data-label="Transaction" class="text-right">
                                                53
                                            </td>
                                            <td data-label="Balance" class="text-right">74</td>
                                        </tr>
                                        <tr>
                                            <td data-label="Date">
                                                <span class="d-inline-block pr-1">2021/1/8</span>
                                            </td>
                                            <td data-label="Product">
                                                Payout
                                            </td>
                                            <td data-label="Transaction"class="text-right">
                                                -44
                                            </td>
                                            <td data-label="Balance" class="text-right">0</td>
                                        </tr>
                                        <tr>
                                            <td data-label="Date">
                                                <span class="d-inline-block pr-1">2021/1/8</span>
                                            </td>
                                            <td data-label="Product">
                                                Sales
                                            </td>
                                            <td data-label="Transaction"class="text-right">
                                                20
                                            </td>
                                            <td data-label="Balance" class="text-right">20</td>
                                        </tr>
                                       
                                    </tbody>
                                     */}
                        </table>
                        <div class="nav-direction">
                          {totalByBalanceResults > PAGE_LIMIT &&
                          totalByBalancePages ? (
                            <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                              <Pagination
                                totalRecords={totalByBalanceResults}
                                totalPages={totalByBalancePages}
                                currentPage={currentByBalancePage}
                                pageLimit={PAGE_LIMIT}
                                pageNeighbours={1}
                                onPageChanged={onByBalancePageChanged}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default SalesReport;
