/* eslint-disable */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
import UserTypesAndPricingComponent from "./UserTypesAndPricingComponent";

import i18next from "i18next";
import { useCookies } from "react-cookie";
import "../less/pricing.css";
import "../less/test.css";
function UserTypesAndPricing(props) {
  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let BANNER_PATH = awsImageUrl + "/uploads/banners/";
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode;
  if (!globalLang) {
    globalLangCode = i18next.language;
  } else {
    globalLangCode = globalLang.lang_code;
  }

  console.log("UserTypesAndPricing");
  const [banners, setBanners] = useState();
  useEffect(() => {
    axios({
      method: "post",
      url: "/api/banners/list",
      headers: {
        locale: globalLangCode,
        // 'x-access-token': "Memo "+token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log(response);
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].banner_type == 2) {
            setBanners(response.data.data[i]);
            break;
          }
        }
      })
      .catch((error) => {
        // removeUserSession();
        console.log("error", error);
      });
  }, []);

  const goToDashboard = () => {
    props.history.push("/myprofile");
  };

  return (
    <React.Fragment>
      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("types_price")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li onClick={goToDashboard}>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span> {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("types_price")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {banners ? (
        <div className="banner_image">
          <a class="item" href={banners.link}>
            <img src={BANNER_PATH + banners.image} class="img_banner"></img>
          </a>
        </div>
      ) : null}
      <UserTypesAndPricingComponent {...props} />
    </React.Fragment>
  );
}

export default UserTypesAndPricing;
