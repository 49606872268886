/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Context } from "../Reducers/Store";
import MyContents from "./MyContents";
import MyContentsUnpublished from "./MyContentsUnpublished";

function MyContentsButtons(props) {
  const { t } = useTranslation();

  const [publishedPageShow, setPublishedPageShow] = useState(1);

  const [state, dispatch] = useContext(Context);

  const goToDashboardPage = () => {
    props.history.push("/myprofile");
  };

  const gotoMyContents = () => {
    console.log("going to mycontent");
    props.history.push("/mycontents");
  };

  const gotoMyContentsUnpublished = () => {
    props.history.push("/mycontentsunpublished");
  };

  useEffect(() => {
    dispatch({ type: "UPDATE_CURRENT_MENU", payload: "id_attributes_page" });
  }, []);

  return (
    <React.Fragment>
      {/* <BasicHeader 
                {...props}
           /> */}
      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("contents_list")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li onClick={goToDashboardPage}>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span> {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("contents_list")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="create-content-wrapper">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-12 col-md-10 col-lg-7">
              <div class="create-content-tabs animated fadeInRight">
                <ul class="nav nav-tabs nav-justified">
                  <li class="nav-item" onClick={() => setPublishedPageShow(1)}>
                    <a
                      href=""
                      data-toggle="tab"
                      style={{ width: "80%" }}
                      class={
                        publishedPageShow === 1 ? "nav-link active" : "nav-link"
                      }
                    >
                      <span>{t("published")}</span>
                    </a>
                  </li>
                  <li class="nav-item" onClick={() => setPublishedPageShow(0)}>
                    <a
                      href=""
                      data-toggle="tab"
                      style={{ width: "80%" }}
                      class={
                        publishedPageShow === 0 ? "nav-link active" : "nav-link"
                      }
                    >
                      <span>{t("draft")}</span>
                    </a>
                  </li>
                </ul>
                {/* <!-- Tab panes --> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      {publishedPageShow === 1 ? (
        <MyContents {...props} />
      ) : (
        <MyContentsUnpublished {...props} />
      )}

      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default MyContentsButtons;
