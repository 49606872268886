/* eslint-disable */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";

import "../less/advertisement.css";
import "../less/contact-us.css";
import "../less/test.css";

import i18next from "i18next";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";

function ReportAbuse(props) {
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const token = cookies["access_token"];
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode;
  if (!globalLang) {
    globalLangCode = i18next.language;
  } else {
    globalLangCode = globalLang.lang_code;
  }
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState();

  const user = cookies["user"]; //getUser();
  console.log("user", user);
  const [name, setName] = useState("");
  const [email_id, setEmail_id] = useState("");

  const [contentId, setContentId] = useState("");
  const [creator_name, setCreator_name] = useState("");

  useEffect(() => {
    setName(user.name);
    setEmail_id(user.email_id);
    if (props.location && props.location.state) {
      setContentId(props.location.state.quiz_deck_id);
      setCreator_name(props.location.state.creator_name);
    }
  }, []);

  const goToDashboard = () => {
    props.history.push("/myprofile");
  };
  const showErrorMessageToUser = (msg) => {
    console.log("here", msg);
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const submitForm = () => {
    if (!submitted) {
      const f = document.getElementById("avForm");
      const data = {
        name: "",
        email_id: "",
        type_of_violation: "",
        //violating_content: '',
        creator_name: "",
        quiz_deck_id: "",
        violation_details: "",
        comments: "",
        action: "",
      };
      let valid = true;
      for (const name in data) {
        const val = f.elements.namedItem(name).value;
        if (val) {
          document.getElementById("e_" + name).style.display = "none";
          data[name] = val;
        } else if (name !== "comments") {
          valid = false;
          document.getElementById("e_" + name).style.display = "block";
        }
      }
      if (valid === true) {
        axios({
          method: "post",
          url: "/api/violations/create",
          headers: {
            locale: globalLangCode,
            "x-access-token": "Memo " + token, //getToken(),
            "Content-Type": "application/json",
          },
          data: data,
        })
          .then((response) => {
            setSubmitted(true);
          })
          .catch((error) => {
            console.log("error", error.response);
            showErrorMessageToUser(error.response.data[0].msg);
            setSubmitted(null);
          });
      }
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">
                {t("report_abuse_violations")}
              </h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li onClick={goToDashboard}>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span> {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("report_abuse_violations")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="campaignList">
        <div class="container">
          <div class="custList contact-us-wrapper">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="imageText animated fadeInLeft">
                  <h3>{t("report_abuse_violations")}</h3>
                </div>
                <div class="description animated fadeInRight">
                  <p>{t("report_abuse_violations_desc")}</p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="imageText animated fadeInLeft">
                  <h3>{t("abuse_list")}</h3>
                </div>
                <div class="description animated fadeInRight">
                  <ol>
                    <li>
                      <p>{t("abuse_list_1")}</p>
                    </li>
                    <li>
                      <p>{t("abuse_list_2")}</p>
                    </li>
                    <li>
                      <p>{t("abuse_list_3")}</p>
                    </li>
                    <li>
                      <p>{t("abuse_list_4")}</p>
                    </li>
                    <li>
                      <p>{t("abuse_list_5")}</p>
                    </li>
                    <li>
                      <p>{t("abuse_list_6")}</p>
                    </li>
                    <li>
                      <p>{t("abuse_list_7")}</p>
                    </li>
                    <li>
                      <p>{t("abuse_list_8")}</p>
                    </li>
                    <li>
                      <p>{t("abuse_list_9")}</p>
                    </li>
                    <li>
                      <p>{t("abuse_list_10")}</p>
                    </li>
                    <li>
                      <p>{t("abuse_list_11")}</p>
                    </li>
                  </ol>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="description animated fadeInRight">
                  <p>{t("report_abuse_violations_desc_1")}</p>
                  <br></br>
                  <p>{t("report_abuse_violations_desc_2")}</p>
                  <br></br>
                  <p>{t("report_abuse_violations_desc_3")}</p>
                </div>
              </div>
              {submitted === true ? (
                <div class="col-lg-12 col-md-12 col-sm-12 row justify-content-center">
                  <div class="col-sm-12 col-md-12 col-lg-8">
                    <div class="success-message animated fadeInRight">
                      {/* <div><img src={thankyou} /></div> */}
                      <h1 style={{ color: "#294177" }}>
                        {t("thank_you_for_reporting")}
                      </h1>
                      <h4 style={{ color: "#294177" }}>
                        {t("we_will_do_our_best")}
                      </h4>
                      {/* <div class="home-btn"><a href="javascript:void(0)" class="btn-background">{t("return_to_home")}</a></div> */}
                    </div>
                  </div>
                </div>
              ) : (
                <div class="col-sm-12 col-md-12 col-lg-8">
                  <div class="contact-form animated fadeInRight">
                    <form id="avForm">
                      <input
                        type="text"
                        name="name"
                        placeholder={t("abuse_name")}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <div
                        id="e_name"
                        style={{
                          display: "none",
                          backgroundColor: "red",
                          width: "400px",
                          "margin-top": "-15px",
                        }}
                      >
                        {t("abuse_name_required")}
                      </div>
                      <input
                        type="text"
                        name="email_id"
                        placeholder={t("abuse_email")}
                        value={email_id}
                        onChange={(e) => setEmail_id(e.target.value)}
                      />
                      <div
                        id="e_email_id"
                        style={{
                          display: "none",
                          backgroundColor: "red",
                          width: "400px",
                          "margin-top": "-15px",
                        }}
                      >
                        {t("abuse_email_required")}
                      </div>
                      <select name="type_of_violation">
                        <option value="" selected disabled hidden>
                          {t("abuse_violation")}
                        </option>
                        <option>{t("abuse_violation_1")}</option>
                        <option>{t("abuse_violation_2")}</option>
                        <option>{t("abuse_violation_3")}</option>
                        <option>{t("abuse_violation_4")}</option>
                        <option>{t("abuse_violation_5")}</option>
                        <option>{t("abuse_violation_6")}</option>
                        <option>{t("abuse_violation_7")}</option>
                        <option>{t("abuse_violation_8")}</option>
                      </select>
                      <div
                        id="e_type_of_violation"
                        style={{
                          display: "none",
                          backgroundColor: "red",
                          width: "400px",
                          "margin-top": "-15px",
                        }}
                      >
                        {t("abuse_violation_required")}
                      </div>
                      <input
                        type="text"
                        name="creator_name"
                        placeholder={t("abuse_violate_content_1")}
                        value={creator_name}
                        onChange={(e) => setCreator_name(e.target.value)}
                      />
                      <div
                        id="e_creator_name"
                        style={{
                          display: "none",
                          backgroundColor: "red",
                          width: "400px",
                          "margin-top": "-15px",
                        }}
                      >
                        {t("abuse_creator_name_required")}
                      </div>
                      <input
                        type="text"
                        name="quiz_deck_id"
                        placeholder={t("content_id")}
                        value={contentId}
                        onChange={(e) => setContentId(e.target.value)}
                      />
                      <div
                        id="e_quiz_deck_id"
                        style={{
                          display: "none",
                          backgroundColor: "red",
                          width: "400px",
                          "margin-top": "-15px",
                        }}
                      >
                        {t("abuse_content_id_required")}
                      </div>
                      {/* 
                                            <select name="violating_content">
                                                <option value='' selected disabled hidden>{t("abuse_violate_content")}</option>
                                                <option>{t("abuse_violate_content_1")}</option>
                                                <option>{t("abuse_violate_content_2")}</option>
                                            </select>
                                            */}
                      <div
                        id="e_violating_content"
                        style={{
                          display: "none",
                          backgroundColor: "red",
                          width: "400px",
                          "margin-top": "-15px",
                        }}
                      >
                        {t("abuse_violate_content_required")}
                      </div>
                      <textarea
                        name="violation_details"
                        placeholder={t("abuse_violation_details")}
                      ></textarea>
                      <div
                        id="e_violation_details"
                        style={{
                          display: "none",
                          backgroundColor: "red",
                          width: "400px",
                          "margin-top": "-15px",
                        }}
                      >
                        {t("abuse_violation_details_required")}
                      </div>
                      <textarea
                        name="comments"
                        placeholder={t("abuse_violation_comments")}
                      ></textarea>
                      <div
                        id="e_comments"
                        style={{
                          display: "none",
                          backgroundColor: "red",
                          width: "400px",
                          "margin-top": "-15px",
                        }}
                      >
                        {t("abuse_violation_comments_required")}
                      </div>
                      <textarea
                        name="action"
                        placeholder={t("abuse_violation_want")}
                      ></textarea>
                      <div
                        id="e_action"
                        style={{
                          display: "none",
                          backgroundColor: "red",
                          width: "400px",
                          margin: "-15px 0 15px 0",
                        }}
                      >
                        {t("abuse_violation_wants_required")}
                      </div>
                    </form>
                    <div class="submit-btn">
                      <a
                        href="javascript:void(0)"
                        onClick={() => submitForm()}
                        class="btn-background blue-bg-btn"
                      >
                        {t("contact_submit")}
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ReportAbuse;
