/* eslint-disable */

import React from "react";
import { useTranslation } from "react-i18next";
import OwlCarousel from "react-owl-carousel";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";

import "../less/advertisement.css";
import "../less/test.css";

import chinaJapanFlag from "../images/advertisement/china-japan.jpg";
import chinaVerticalFlag from "../images/advertisement/china-vertical.jpg";
import englandFlag from "../images/advertisement/england.jpg";
import englishJapanFlag from "../images/advertisement/english-japan.jpg";
import englishVerticalFlag from "../images/advertisement/english-vertical.png";
import hindiJapanFlag from "../images/advertisement/hindi-japan.jpg";
import hindiVerticalFlag from "../images/advertisement/hindi-vertical.jpg";
import hozitonalJapanFlag from "../images/advertisement/hozitonal-japan.jpg";
import japanVerticalFlag from "../images/advertisement/japan-vertical.jpg";
import japaneseFlag from "../images/advertisement/Japanese.jpg";

function Advertisement(props) {
  const { t } = useTranslation();
  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  let CATEGORY_PATH = awsImageUrl + "/uploads/categories/";

  const goToDashboard = () => {
    props.history.push("/myprofile");
  };

  return (
    <React.Fragment>
      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("advertisement")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li onClick={goToDashboard}>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span> {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("advertisement")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="campaignList">
        <div class="container">
          <div class="custList">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="imageText animated fadeInLeft">
                  <h3>{t("why_advertise_on_memorizeitall")}</h3>
                </div>
                <div class="description animated fadeInRight">
                  <p>
                    {t("why_advertise_1")}
                    <br></br>
                    {t("why_advertise_2")}
                    <br></br>
                  </p>
                </div>
                <div class="imageText animated fadeInLeft">
                  <h3>{t("early_stage")}</h3>
                </div>
                <div class="description animated fadeInRight">
                  <p> {t("early_stage_1")}</p>
                </div>
                <div class="imageText animated fadeInLeft">
                  <h3>{t("the_system")}</h3>
                </div>
                <div class="description animated fadeInRight">
                  <p> {t("the_system_1")}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="imageText animated fadeInLeft">
                  <h3>{t("below_examples_ad")}</h3>
                  {/* <p>Examples</p> */}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-2 col-md-1 col-sm-1">
                <div class="imageText listItems animated fadeInLeft">
                  <h2>{t("ad_language")}</h2>
                  <h2 class="icns">
                    <span class="icon icon-language"></span>
                  </h2>
                  <h2>{t("ad_genre")}</h2>
                  <h2 class="icns">
                    <span class="icon icon-language"></span>
                  </h2>
                  <h2>{t("ad_category")}</h2>
                  <h2 class="icns">
                    <span class="icon icon-language"></span>
                  </h2>
                  <h2>{t("ad_price")}</h2>
                  <h2 class="icns">
                    <span class="icon icon-language"></span>
                  </h2>
                </div>
              </div>
              <div class="col-lg-10 col-md-11 col-sm-11">
                {/* <div class="owl-carousel owl-theme animated fadeInRight" id="owl-carousel5">
                            
                        </div> */}
                <OwlCarousel
                  className="owl-theme owl-carousel"
                  id="owl-carousel5"
                  items="3"
                  // autoplay
                  nav
                  dots
                  // loop
                  margin={10}
                >
                  {/*
                        language - d25eb42d7351f9e394caff499cd3d4a181a4.png
                        Genre Business - 3edc6a9282909ff8a464c1abc2b829e31e05.png
                        Catagory Accounting - 18b0822f3a7e74e914dc229c6a10690d36c0.png
                        */}
                  <div class="item">
                    <div class="custExample">
                      <img src={englandFlag} />
                      <h3>{t("ad_english")}</h3>
                      <h2 class="brd"></h2>
                      <img
                        src={
                          GENER_PATH +
                          "3edc6a9282909ff8a464c1abc2b829e31e05.png"
                        }
                      />
                      <h3>{t("ad_business")}</h3>
                      <h2 class="brd"></h2>
                      <img
                        src={
                          CATEGORY_PATH +
                          "18b0822f3a7e74e914dc229c6a10690d36c0.png"
                        }
                      />
                      <h3>{t("ad_accounting")}</h3>
                      <h2 class="brd"></h2>
                      <h4 class="pricing">{t("ad_price_1")}</h4>
                    </div>
                  </div>
                  {/*
                                Genre languages - d25eb42d7351f9e394caff499cd3d4a181a4.png
                                Category japanese - e8e5f7e8c214dae1e613d9739c72106573f7.png

                                */}
                  <div class="item">
                    <div class="custExample">
                      <img src={japaneseFlag} />
                      <h3>{t("ad_japanese")}</h3>
                      <h2 class="brd"></h2>
                      <img
                        src={
                          GENER_PATH +
                          "d25eb42d7351f9e394caff499cd3d4a181a4.png"
                        }
                      />
                      <h3>{t("ad_languages")}</h3>
                      <h2 class="brd"></h2>
                      <img
                        src={
                          CATEGORY_PATH +
                          "e8e5f7e8c214dae1e613d9739c72106573f7.png"
                        }
                      />
                      <h3>{t("ad_japanese")}</h3>
                      <h2 class="brd"></h2>
                      <h4 class="pricing">{t("ad_price_1")}</h4>
                    </div>
                  </div>
                  {/*
                               Genre Business - 3edc6a9282909ff8a464c1abc2b829e31e05.png
                                Catagory Accounting - 18b0822f3a7e74e914dc229c6a10690d36c0.png
                                */}
                  <div class="item">
                    <div class="custExample">
                      <div class="duoImg d-flex justify-content-center align-items-center">
                        <img src={englandFlag} />+<img src={japaneseFlag} />
                      </div>
                      <h3>{t("ad_english_japanese")}</h3>
                      <h2 class="brd"></h2>
                      <img
                        src={
                          GENER_PATH +
                          "3edc6a9282909ff8a464c1abc2b829e31e05.png"
                        }
                      />
                      <h3>{t("ad_business")}</h3>
                      <h2 class="brd"></h2>
                      <img
                        src={
                          CATEGORY_PATH +
                          "18b0822f3a7e74e914dc229c6a10690d36c0.png"
                        }
                      />
                      <h3>{t("ad_accounting")}</h3>
                      <h2 class="brd"></h2>
                      <h4 class="pricing">{t("ad_price_2")}</h4>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
            <div class="row custImgGrid">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="imageText animated fadeInLeft">
                  <h3>{t("size_of_ads")}</h3>
                  <p>{t("example_vertical_ads")}</p>
                </div>
              </div>
              <div class="row6 col-lg-2 col-md-4 col-sm-6 animated fadeInLeft">
                <img src={chinaVerticalFlag} />
                <h4 class="imgHead">400x800px</h4>
                <p class="imgDesc">{t("Chinese")}</p>
              </div>
              <div class="row6 col-lg-2 col-md-4 col-sm-6 animated fadeInDown">
                <img src={englishVerticalFlag} />
                <h4 class="imgHead">400x800px</h4>
                <p class="imgDesc">{t("English")}</p>
              </div>
              <div class="row6 col-lg-2 col-md-4 col-sm-6 animated fadeInUp">
                <img src={japanVerticalFlag} />
                <h4 class="imgHead">400x800px</h4>
                <p class="imgDesc">{t("Japanese")}</p>
              </div>
              <div class="row6 col-lg-2 col-md-4 col-sm-6 animated fadeInRight">
                <img src={hindiVerticalFlag} />
                <h4 class="imgHead">400x800px</h4>
                <p class="imgDesc">{t("Hindi")}</p>
              </div>
            </div>
            <div class="row custImgGrid">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="imageText animated fadeInLeft">
                  <p>{t("example_horizontal_ads")}</p>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 animated fadeInLeft">
                <img src={chinaJapanFlag} />
                <h4 class="imgHead">800x400px</h4>
                <p class="imgDesc">{t("Chinese")}</p>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 animated fadeInLeft">
                <img src={englishJapanFlag} />
                <h4 class="imgHead">800x400px</h4>
                <p class="imgDesc">{t("English")}</p>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 animated fadeInLeft">
                <img src={hozitonalJapanFlag} />
                <h4 class="imgHead">800x400px</h4>
                <p class="imgDesc">{t("Japanese")}</p>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 animated fadeInLeft">
                <img src={hindiJapanFlag} />
                <h4 class="imgHead">800x400px</h4>
                <p class="imgDesc">{t("Hindi")}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="imageText animated fadeInLeft">
                  <h3>{t("payment")}</h3>
                </div>
                <div class="description animated fadeInRight">
                  <p>{t("payment_desc")}</p>
                </div>
                <div class="imageText animated fadeInLeft">
                  <h3>{t("services")}</h3>
                </div>
                <div class="description animated fadeInRight">
                  <p>{t("services_desc")}</p>
                </div>
                <div class="imageText animated fadeInLeft">
                  <h3>{t("guidelines")}</h3>
                </div>
                <div class="description animated fadeInRight">
                  <ol>
                    <li>
                      <p>{t("guidelines_desc_1")}</p>
                    </li>
                    <li>
                      <p>{t("guidelines_desc_2")}</p>
                    </li>
                    <li>
                      <p>{t("guidelines_desc_3")}</p>
                    </li>
                    <li>
                      <p>{t("guidelines_desc_4")}</p>
                    </li>
                    <li>
                      <p>{t("guidelines_desc_5")}</p>
                    </li>
                    <li>
                      <p>{t("guidelines_desc_6")}</p>
                    </li>
                    <li>
                      <p>{t("guidelines_desc_7")}</p>
                    </li>
                    <li>
                      <p>{t("guidelines_desc_8")}</p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Advertisement;
