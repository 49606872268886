/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
// import "../less/owl.carousel.min.css"
import "../less/categories-preferences.css";
import "../less/dashboard.css";
import "../less/home.css";
import "../less/test.css";
// import "../less/quiz-creation.css"
// import "../less/owl.carousel.min.css"
import axios from "axios";
import qs from "qs";
import OwlCarousel from "react-owl-carousel";
import "../less/attributes.css";
// import "../less/owl.carousel.min.css"

//import homeBannerImg from "../images/home/banner_mob.png"
import homeBannerImg from "../images/home/banner_mob_latest.png";
// import homeclient1 from "../images/home/client1.png"
// import homeclient2 from "../images/home/client2.png"
// import homeclient3 from "../images/home/client3.png"
// import homeclient4 from "../images/home/client4.png"
import i18next from "i18next";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useCookies } from "react-cookie";
import CookieConsent from "react-cookie-consent";

function MyHomePage(props) {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);

  const [genreList, setGenreList] = useState([]);
  const [pagesInGenre, setPagesInGenre] = useState();
  let [ourUsers, setOurUsers] = useState([]);
  let [whatOurUserSay, setWhatOurUserSay] = useState([]);
  const [canRender, setCanRender] = useState(false);

  const token = cookies["access_token"]; //getToken()
  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode;

  if (!globalLang) {
    globalLangCode = i18next.language;
  } else {
    globalLangCode = globalLang.lang_code;
  }
  //let globalLangCode = globalLang.lang_code;
  //let globalLangId = globalLang.id;

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let GENER_PATH = awsImageUrl + "/uploads/genre/";
  /*
    let hideLoginHF = props.hideLoginHF ? props.hideLoginHF : 0;
    if(!hideLoginHF) {
        if(props.match.params.hideLoginHF) {
            hideLoginHF = props.match.params.hideLoginHF ? props.match.params.hideLoginHF : 0;
        }
    } 
    */

  const gotoLogin = () => {
    props.history.push({
      pathname: "/login",
    });
  };

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/genres/list",
      headers: {
        locale: globalLangCode,
      },
      data: qs.stringify({
        page: "1",
      }),
    })
      .then((response) => {
        setPagesInGenre(response.data.total_pages);
      })
      .catch((error) => {
        // removeUserSession();
      });
  }, []);

  useEffect(() => {
    setGenreList([]);
    if (pagesInGenre) {
      for (let i = 1; i <= pagesInGenre; i++) {
        axios({
          method: "post",
          url: "/api/genres/list",
          headers: {
            locale: globalLangCode,
          },
          data: qs.stringify({
            page: i,
          }),
        })
          .then((response) => {
            setGenreList((genreList) => genreList.concat(response.data.data));
          })
          .catch((error) => {
            //   removeUserSession();
          });
      }
    }
  }, [pagesInGenre]);

  useEffect(async () => {
    axios({
      method: "get",
      url: "/api/our-users/get",
      headers: { locale: globalLangCode },
    })
      .then((resolve) => {
        setOurUsers(resolve.data.data);
      })
      .catch((reject) => {});
  }, []);

  useEffect(() => {
    axios({
      method: "get",
      url: "/api/what-our-user-say/get",
      headers: { locale: globalLangCode },
    })
      .then((resolve) => {
        setWhatOurUserSay(resolve.data.data);
        setTimeout(() => {
          setCanRender(true);
        }, 2000);
      })
      .catch((reject) => {});
  }, []);

  const renderCarausal = () => {
    return (
      <section class="client_feedback">
        <h2 class="main_head text-center ">{t("lp_117")}</h2>
        <div class="container">
          <div id="client_carousel" class="carousel slide" data-ride="carousel">
            <ul class="carousel-indicators">{renderLi()}</ul>
            <div class="carousel-inner">{renderCarausalItem()}</div>
          </div>
          {!token ? (
            <div class="setup-account">
              <a
                href="javascript:void(0)"
                className="blue-bg-btn"
                onClick={() => gotoLogin()}
              >
                {t("lp_signup_for_free_account")}
              </a>
            </div>
          ) : null}
        </div>
      </section>
    );
  };

  const renderLi = () => {
    return whatOurUserSay.map((item, index) => {
      return (
        <li
          key={item.id}
          data-target="#client_carousel"
          data-slide-to={index}
          class={index == 0 ? "active" : ""}
        >
          <img src={item.user_image} />
        </li>
      );
    });
  };

  const renderCarausalItem = () => {
    console.log("whatOurUserSay count", whatOurUserSay.length);
    return (
      <OwlCarousel
        className="owl-carousel logo_slider"
        // id="owl-carousel"
        items="1"
        autoplay
        nav
        dots
        loop
        margin={0}
      >
        {whatOurUserSay.map((item, index) => {
          return (
            <div>
              <div class="client_talk">
                <div class="row align-items-center">
                  <div class="col-md-6 client-info">
                    <h5>{item.fullName}</h5>
                    <h6>
                      {item.designation}, {item.company_name}
                    </h6>
                  </div>
                  <div class="col-md-6 client-img">
                    <div className="company-logo">
                      <img src={item.company_logo} />
                    </div>
                    <div className="user-img">
                      <img src={item.user_image} />
                    </div>
                  </div>
                </div>
              </div>
              <div class="client_talk">
                <p>
                  <span class="icon-quotes">
                    <i class="fa fa-quote-left"></i>
                  </span>
                  {item.content}
                  <span class="quotes">
                    <i class="fa fa-quote-right"></i>
                  </span>
                </p>
              </div>
            </div>
          );
        })}
      </OwlCarousel>
    );
  };

  const goToDashboard = () => {
    props.history.push("/myprofile");
  };
  const gotoTermsAndCconditionsPage = () => {
    props.history.push("/termsandconditions");
  };
  const gotoPrivacyPolicyPage = () => {
    props.history.push("/privacypolicy");
  };
  const getMemorizeitallCookieExpiryTime = () => {
    let exp = 30 * 24 * 60 * 60;
    //console.log('in setCookieUser',exp)
    let exp_time = new Date();
    exp_time.setTime(exp_time.getTime() + exp * 1000);
    return exp_time;
  };
  return (
    <React.Fragment>
      <CookieConsent
        onAccept={(acceptedByScrolling) => {
          if (acceptedByScrolling) {
            // triggered if user scrolls past threshold
            //alert("Accept was triggered by user scrolling");
          } else {
            //alert("Accept was triggered by clicking the Accept button");
          }
        }}
        //acceptOnScroll={true}
        //style={{background: "#294177", border: "1px solid #fff"}}
        buttonText={t("accept")}
        buttonStyle={{ color: "blue", background: "white" }}
        cookieName={"memorizeitallCookie"}
        expires={getMemorizeitallCookieExpiryTime()}
      >
        {(function render() {
          switch (i18next.language) {
            case "en":
              return (
                <>
                  <span>{t("cookie_concent")}</span>
                  <span
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={() => gotoTermsAndCconditionsPage()}
                  >
                    {t("terms_and_conditions")}
                  </span>
                  <span style={{ color: "white" }}>
                    {" "}
                    {t("cookie_concent_and")}{" "}
                  </span>
                  <span
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={() => gotoPrivacyPolicyPage()}
                  >
                    {" "}
                    {t("cookie_concent_p_p")}{" "}
                  </span>
                </>
              );
              break;
            case "ja":
              return (
                <>
                  <span>
                    {" "}
                    このウェブサイトでは、ユーザー体験を向上させるためにクッキーやその他の技術を使用しています。閲覧を継続することで、その使用に同意することになります。詳しくは
                  </span>
                  <span
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={() => gotoTermsAndCconditionsPage()}
                  >
                    利用規約
                  </span>
                  <span style={{ color: "white" }}>と</span>
                  <span
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={() => gotoPrivacyPolicyPage()}
                  >
                    プライバシーポリシー
                  </span>
                  <span>はをご覧ください。</span>
                </>
              );
              break;
            case "zh":
              return (
                <>
                  <span>
                    {" "}
                    本网站使用cookies和其他技术来提高用户体验。
                    通过继续浏览，你同意他们的使用。
                    欲了解更多信息，请参见我们的
                  </span>
                  <span
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={() => gotoTermsAndCconditionsPage()}
                  >
                    条款和条件
                  </span>
                  <span style={{ color: "white" }}>以及</span>
                  <span
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={() => gotoPrivacyPolicyPage()}
                  >
                    隐私政策。
                  </span>
                </>
              );
              break;
            case "hi":
              return (
                <>
                  <span>
                    यह वेबसाइट उपयोगकर्ता अनुभव को बढ़ाने के लिए कुकीज़ और अन्य
                    तकनीकों का उपयोग करती है। इस वेबसाइट को ब्राउज़ करना जारी
                    रखते हुए, उपयोग पर आपकी सहमति। हमारे{" "}
                  </span>
                  <span
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={() => gotoTermsAndCconditionsPage()}
                  >
                    {" "}
                    नियम और शर्तें{" "}
                  </span>
                  <span style={{ color: "white" }}>और</span>
                  <span
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={() => gotoPrivacyPolicyPage()}
                  >
                    {" "}
                    गोपनीयता नीति{" "}
                  </span>
                  <span>देखें</span>
                </>
              );
              break;
          }
        })()}
      </CookieConsent>

      <header class="banner_section">
        <div id="demo" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="container">
                <div class="row">
                  <div class="col-md-12 col-lg-6 align-item-center">
                    <img class="slide_img" src={homeBannerImg} />
                  </div>
                  <div class="col-md-12 col-lg-6 align-item-center pr-0">
                    <div class="wid-100">
                      {/* <h3 class="home_page_banner_heading">{t("memorizeitall_home_banner_heading")}</h3> */}
                      <h3 class="home_page_banner_heading">
                        Memorize<span>it</span>all
                      </h3>
                      {/* <ul class="feature_list">
                                        <li>{t("memorizeitall_home_banner_point1")}</li>
                                         <li>{t("memorizeitall_home_banner_point2")}</li>
                                         <li>{t("memorizeitall_home_banner_point3")}</li>
                                         <li>{t("memorizeitall_home_banner_point4")}</li>
                                         <li>{t("memorizeitall_home_banner_point5")}</li>
                                         <li>{t("memorizeitall_home_banner_point6")}</li>
                                         <li>{t("memorizeitall_home_banner_point7")}</li>
                                     </ul> */}
                      <div class="row">
                        <div class="col-md-6 benifits-txt">
                          {(function render() {
                            switch (i18next.language) {
                              case "en":
                                return <p>Is all about memorizing:</p>;
                                break;
                              case "ja":
                                return (
                                  <p>
                                    <small>
                                      Memorize<span>it</span>all
                                    </small>{" "}
                                    は暗記が全て
                                  </p>
                                );
                                break;
                              case "zh":
                                return (
                                  <p>
                                    <small>
                                      Memorize<span>it</span>all
                                    </small>{" "}
                                    就是关于记忆
                                  </p>
                                );
                                break;
                              case "hi":
                                return <p>सब कुछ याद रखने के बारे में है:</p>;
                                break;
                            }
                          })()}
                          {/* <p>Is all about memorizing:</p> */}
                          <ul class="feature_list">
                            <li>
                              <span class="icon icon-faster"></span>
                              {t("lp_3")}
                            </li>
                            <li>
                              <span class="icon icon-anything"></span>
                              {t("lp_4")}
                            </li>
                            <li>
                              <span class="icon icon-anytime"></span>
                              {t("lp_5")}
                            </li>
                            <li>
                              <span class="icon icon-anywhere1"></span>
                              {t("lp_6")}
                            </li>
                            <li>
                              <span class="icon icon-platform"></span>
                              {t("lp_7")}
                            </li>
                            <li>
                              <span class="icon icon-pace"></span>
                              {t("lp_8")}
                            </li>
                            <li>
                              <span class="icon icon-group2"></span>
                              {t("lp_9")}
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-6 benifits-txt">
                          {(function render() {
                            switch (i18next.language) {
                              case "en":
                                return (
                                  <p>
                                    <small>
                                      Memorize<span>it</span>all
                                    </small>{" "}
                                    is also:
                                  </p>
                                );
                                break;
                              case "ja":
                                return (
                                  <p>
                                    そして{" "}
                                    <small>
                                      Memorize<span>it</span>all
                                    </small>{" "}
                                    で出来る事は:
                                  </p>
                                );
                                break;
                              case "zh":
                                return (
                                  <p>
                                    而“{" "}
                                    <small>
                                      Memorize<span>it</span>all
                                    </small>{" "}
                                    ”可以做什么创建内容
                                  </p>
                                );
                                break;
                              case "hi":
                                return (
                                  <p>
                                    <small>
                                      Memorize<span>it</span>all
                                    </small>{" "}
                                    भी है:
                                  </p>
                                );
                                break;
                            }
                          })()}
                          {/*<p><small>Memorize<span>it</span>all</small> is also:</p>*/}
                          <ul class="feature_list">
                            <li>
                              <span class="icon icon-creating-content"></span>
                              {t("lp_10")}
                            </li>
                            <li>
                              <span class="icon icon-contents1"></span>
                              {t("lp_11")}
                            </li>
                            <li>
                              <span class="icon icon-youtube"></span>
                              {t("lp_12")}
                            </li>
                            <li>
                              <span class="icon icon-download5"></span>
                              {t("lp_13")}
                            </li>
                            <li>
                              <span class="icon icon-repeating1"></span>
                              {t("lp_14")}
                            </li>
                            <li>
                              <span class="icon icon-selling-content"></span>
                              {t("lp_15")}
                            </li>
                            <li>
                              <span class="icon icon-smile1"></span>
                              {t("lp_16")}
                            </li>
                          </ul>
                        </div>
                      </div>
                      {!token ? (
                        <div class="setup-account-bg">
                          <a
                            href="javascript:void(0)"
                            className="white-bg-btn"
                            onClick={() => gotoLogin()}
                          >
                            {t("lp_signup_for_free_account")}
                          </a>
                        </div>
                      ) : null}
                      {/* <a href="#" class="dnld_btn"><img src={homeIOSdwnlod} /></a>
                                     <a href="#" class="dnld_btn"><img src={homeandroiddwnlod} /></a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div class="tabs_section">
        {/* <h2 class="main_head text-center">{t("how_memorizeitall_works")}</h2> */}
        <h2 class="main_head text-center">{t("lp_17")}</h2>
        <div class="container">
          <div class="row justify-content-center align-items-center">
            <div class="how-it-works">
              <div class="setup-section">
                <div class="number-txt">
                  <span>1</span>
                </div>
                <div class="work-txt">
                  <span>{t("lp_18")}</span>
                  <b>{t("lp_19")}</b>
                </div>
              </div>
            </div>
            <div class="how-it-works">
              <div class="setup-section">
                <div class="number-txt">
                  <span>2</span>
                </div>
                <div class="work-txt">
                  <span>{t("lp_20")}</span>
                  <b>{t("lp_21")}</b>
                </div>
              </div>
            </div>
            <div class="how-it-works">
              <div class="setup-section">
                <div class="number-txt">
                  <span>3</span>
                </div>
                <div class="work-txt">
                  <span>{t("lp_22")}</span>
                  <b>{t("lp_23")}</b>
                </div>
              </div>
            </div>
            <div class="how-it-works">
              <div class="setup-section">
                <div class="number-txt">
                  <span>4</span>
                </div>
                <div class="work-txt">
                  <span>{t("lp_24")}</span>
                  <b>{t("lp_24")}</b>
                </div>
              </div>
            </div>
            <div class="how-it-works">
              <div class="setup-section">
                <div class="number-txt">
                  <span>5</span>
                </div>
                <div class="work-txt">
                  <span>{t("lp_25")}</span>
                  <b>{t("lp_26")}</b>
                </div>
              </div>
            </div>
            <div class="how-it-works">
              <div class="setup-section">
                <div class="number-txt">
                  <span>6</span>
                </div>
                <div class="work-txt">
                  <span>{t("lp_27")}</span>
                  <b>{t("lp_28")}</b>
                </div>
              </div>
            </div>
            <div class="how-it-works">
              <div class="setup-section">
                <div class="number-txt">
                  <span>7</span>
                </div>
                <div class="work-txt">
                  <span>{t("lp_29")}</span>
                  <b>{t("lp_30")}</b>
                </div>
              </div>
            </div>
            <div class="how-it-works">
              <div class="setup-section">
                <div class="number-txt">
                  <span>8</span>
                </div>
                <div class="work-txt">
                  <span class="testing">{t("lp_31")}</span>
                  <b>{t("lp_32")}</b>
                </div>
              </div>
            </div>
            {/* <div class="col-lg-3 col-md-4 col-sm-12">
                    <ul class="cust_tabs_designing nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#home" role="tab" aria-controls="home"><i class="fa fa-star"></i>{t("how_setup")}</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#profile" role="tab" aria-controls="profile"><i class="fa fa-star"></i>{t("how_create_search")}</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#messages" role="tab" aria-controls="messages"><i class="fa fa-star"></i>{t("how_memorizing")}</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#settings" role="tab" aria-controls="settings"><i class="fa fa-star"></i>{t("how_result")}</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#home1" role="tab" aria-controls="home"><i class="fa fa-star"></i>{t("how_rememorizing")}</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#profile1" role="tab" aria-controls="profile"><i class="fa fa-star"></i>{t("how_share")}</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#messages1" role="tab" aria-controls="messages"><i class="fa fa-star"></i>{t("how_search")}</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#settings1" role="tab" aria-controls="settings"><i class="fa fa-star"></i>{t("how_upgrade")}</a>
                      </li>
                    </ul>
                </div> */}
            {/* <div class="col-lg-7 col-md-8 col-sm-12 align-item-center">
                    <div class="tab-content">
                        <div class="tab-pane active" id="home" role="tabpanel">
                            <div class="bg_grid_img">
                                <h2>{t("how_setup")}</h2>
                                <p>{t("how_it_works_text_1")}</p>
                                <p>{t("how_it_works_text_2")}</p>
                            </div>
                        </div>
                      <div class="tab-pane" id="profile" role="tabpanel"><div class="bg_grid_img">
                                <h2>{t("how_create_search")}</h2>
                                <p>{t("how_it_works_text_3")}</p>
                            </div></div>
                      <div class="tab-pane" id="messages" role="tabpanel"><div class="bg_grid_img">
                                <h2>{t("how_memorizing")}</h2>
                                <p>{t("how_it_works_text_4")}</p>
                            </div></div>
                      <div class="tab-pane" id="settings" role="tabpanel"><div class="bg_grid_img">
                                <h2>{t("how_result")}</h2>
                                <p>{t("how_it_works_text_5")}</p>
                            </div></div>                    
                        <div class="tab-pane" id="home1" role="tabpanel">
                            <div class="bg_grid_img">
                                <h2>{t("how_rememorizing")}</h2>
                                <p>{t("how_it_works_text_6")}</p>
                            </div>
                        </div>
                        <div class="tab-pane" id="profile1" role="tabpanel">
                            <div class="bg_grid_img">
                                <h2>{t("how_share")}</h2>
                                <p>{t("how_it_works_text_7")}</p>
                            </div>
                        </div>
                        <div class="tab-pane" id="messages1" role="tabpanel">
                            <div class="bg_grid_img">
                                <h2>{t("how_search")}</h2>
                                <p>{t("how_it_works_text_8")}</p>
                            </div>
                        </div>
                        <div class="tab-pane" id="settings1" role="tabpanel">
                            <div class="bg_grid_img">
                                <h2>{t("how_upgrade")}</h2>
                                <p>{t("how_it_works_text_9")}</p>
                                <p>{t("how_it_works_text_10")}</p>
                            </div>
                        </div>
                    </div>
                </div> */}
          </div>
          {!token ? (
            <div class="setup-account">
              <a
                href="javascript:void(0)"
                className="blue-bg-btn"
                onClick={() => gotoLogin()}
              >
                {t("lp_signup_for_free_account")}
              </a>
            </div>
          ) : null}
        </div>
        {/* <div class="memorize-btn">
            <a href="#" class="play_btn">{t("play_flash_quiz")}</a>
        </div> */}
      </div>

      <section class="why_mm_tall">
        {/* <h2 class="main_head text-center">{t("why_memorizeitall")}</h2> */}
        {(function render() {
          switch (i18next.language) {
            case "en":
              return (
                <h2 class="main_head text-center">
                  Why{" "}
                  <b>
                    Memorize<span>it</span>all?
                  </b>
                </h2>
              );
              break;
            case "ja":
              return (
                <h2 class="main_head text-center">
                  <b>
                    Memorize<span>it</span>all
                  </b>{" "}
                  おすすめの理由
                </h2>
              );
              break;
            case "zh":
              return (
                <h2 class="main_head text-center">
                  <b>
                    Memorize<span>it</span>all
                  </b>{" "}
                  推荐理由
                </h2>
              );
              break;
            case "hi":
              return (
                <h2 class="main_head text-center">
                  Why{" "}
                  <b>
                    Memorize<span>it</span>ही क्यों?
                  </b>
                </h2>
              );
              break;
          }
        })()}
        {/* <h2 class="main_head text-center">Why <b>Memorize<span>it</span>all?</b></h2> */}
        <div class="container">
          <div class="row">
            {/* <div class="col-lg-6 col-md-6 col-sm-12">
                    <div id="grid_slider" class="carousel slide" data-ride="carousel">
                          
                        <ul class="carousel-indicators">
                            <li data-target="#grid_slider" data-slide-to="0" class="active"></li>
                            <li data-target="#grid_slider" data-slide-to="1"></li>
                            <li data-target="#grid_slider" data-slide-to="2"></li>
                        </ul>

                       
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img src={homeslide1} alt="Los Angeles" />
                            </div>
                            <div class="carousel-item">
                                <img src={homeslide2} alt="Chicago" />
                            </div>
                            <div class="carousel-item">
                                <img src={homeslide1} alt="New York" />
                            </div>
                        </div>
                          
                         
                          
                    </div>
                </div> */}
            <div class="col-md-12">
              <div class="mm_container">
                <div class="why_mm_content">
                  <ul class="list-icons">
                    {(function render() {
                      switch (i18next.language) {
                        case "en":
                          return (
                            <li>
                              <span class="icon icon-faster"></span>
                              Because{" "}
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              help you memorize more effectively, faster and
                              easier.
                            </li>
                          );
                          break;
                        case "ja":
                          return (
                            <li>
                              <span class="icon icon-faster"></span>
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              はより早く、より簡単に、より効率良く暗記出来る強力な助っ人ソフト
                            </li>
                          );
                          break;
                        case "zh":
                          return (
                            <li>
                              <span class="icon icon-faster"></span>
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              是一款功能强大的帮助软件，可让您更快，更轻松，更有效地进行记忆
                            </li>
                          );
                          break;
                        case "hi":
                          return (
                            <li>
                              <span class="icon icon-faster"></span>
                              क्योंकि{" "}
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              आपको अधिक प्रभावी ढंग से, तेज और आसान याद रखने में
                              मदद करता है।
                            </li>
                          );
                          break;
                      }
                    })()}
                    {/* 
                                <li>
                                    <span class="icon icon-faster"></span>
                                    Because <b>Memorize<span>it</span>all</b> help you memorize more effectively, faster and easier.
                                </li>
                                */}
                    {(function render() {
                      switch (i18next.language) {
                        case "en":
                          return (
                            <li>
                              <span class="icon icon-anything"></span>
                              Because you will find contents of your interest.{" "}
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              offer contents within 30 genres and more than 500
                              categories.
                            </li>
                          );
                          break;
                        case "ja":
                          return (
                            <li>
                              <span class="icon icon-anything"></span>
                              あなたが興味を持つコンテンツが必ず見つかる！{" "}
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              は30のジャンル、500以上のカテゴリーからあなたにピッタリのコンテンツを提案
                            </li>
                          );
                          break;
                        case "zh":
                          return (
                            <li>
                              <span class="icon icon-anything"></span>
                              您一定会找到您感兴趣的内容!{" "}
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              30+主题/500+类别中下载免费内容!
                            </li>
                          );
                          break;
                        case "hi":
                          return (
                            <li>
                              <span class="icon icon-anything"></span>
                              क्योंकि आपको अपनी रुचि की सामग्री मिल जाएगी।{" "}
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              30 शैलियों और 500 से अधिक श्रेणियों में सामग्री
                              प्रदान करता है।
                            </li>
                          );
                          break;
                      }
                    })()}
                    {/**
                                <li>
                                    <span class="icon icon-anything"></span>
                                    Because you will find contents of your interest. <b>Memorize<span>it</span>all</b> offer contents within 30 genres and more than 500 categories.
                                </li>
                                 */}
                    {(function render() {
                      switch (i18next.language) {
                        case "en":
                          return (
                            <li>
                              <span class="icon icon-increase"></span>
                              Because number of contents increases every day.
                            </li>
                          );
                          break;
                        case "ja":
                          return (
                            <li>
                              <span class="icon icon-increase"></span>
                              コンテンツは毎日増えている
                            </li>
                          );
                          break;
                        case "zh":
                          return (
                            <li>
                              <span class="icon icon-increase"></span>
                              内容每天都在增加
                            </li>
                          );
                          break;
                        case "hi":
                          return (
                            <li>
                              <span class="icon icon-increase"></span>
                              क्योंकि सामग्री की संख्या हर दिन बढ़ जाती है।
                            </li>
                          );
                          break;
                      }
                    })()}
                    {/**
                                <li>
                                    <span class="icon icon-increase"></span>
                                    Because number of contents increases every day.
                                </li>
                                 */}
                    {(function render() {
                      switch (i18next.language) {
                        case "en":
                          return (
                            <li>
                              <span class="icon icon-selling-content"></span>
                              Because much of the contents is for free.
                            </li>
                          );
                          break;
                        case "ja":
                          return (
                            <li>
                              <span class="icon icon-selling-content"></span>
                              多くのコンテンツは無料
                            </li>
                          );
                          break;
                        case "zh":
                          return (
                            <li>
                              <span class="icon icon-selling-content"></span>
                              许多内容是免费的
                            </li>
                          );
                          break;
                        case "hi":
                          return (
                            <li>
                              <span class="icon icon-selling-content"></span>
                              क्योंकि ज्यादातर सामग्री मुफ्त है।
                            </li>
                          );
                          break;
                      }
                    })()}
                    {/** 
                                <li>
                                    <span class="icon icon-selling-content"></span>
                                    Because much of the contents is for free.
                                </li>
                                */}
                    {(function render() {
                      switch (i18next.language) {
                        case "en":
                          return (
                            <li>
                              <span class="icon icon-change-language"></span>
                              Because{" "}
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              is available in four languages: English, Chinese,
                              Japanese and Hindi.
                            </li>
                          );
                          break;
                        case "ja":
                          return (
                            <li>
                              <span class="icon icon-change-language"></span>
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              は四か国語で利用可能: 英語, 中国語, 日本語,
                              インド語
                            </li>
                          );
                          break;
                        case "zh":
                          return (
                            <li>
                              <span class="icon icon-change-language"></span>
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              提供四种语言：英语，中文，日语，印度语
                            </li>
                          );
                          break;
                        case "hi":
                          return (
                            <li>
                              <span class="icon icon-change-language"></span>
                              क्योंकि{" "}
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              चार भाषाओं में उपलब्ध है: अंग्रेजी, चीनी, जापानी
                              और हिंदी।
                            </li>
                          );
                          break;
                      }
                    })()}
                    {/** 
                                <li>
                                    <span class="icon icon-change-language"></span>
                                    Because <b>Memorize<span>it</span>all</b> is available in four languages: English, Chinese, Japanese and Hindi.
                                </li>
                                */}
                    {(function render() {
                      switch (i18next.language) {
                        case "en":
                          return (
                            <li>
                              <span class="icon icon-youtube"></span>
                              Because{" "}
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              offer a lot of features other software dont, like
                              combining of text, images, audio and video files
                              and input option as well.
                            </li>
                          );
                          break;
                        case "ja":
                          return (
                            <li>
                              <span class="icon icon-youtube"></span>
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              は他社には無い機能を追加： 例
                              文章、画像、動画、音声のコンテンツ作成が可能
                            </li>
                          );
                          break;
                        case "zh":
                          return (
                            <li>
                              <span class="icon icon-youtube"></span>
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              添加了其他公司没有的功能：
                              示例：可以创建文本，图像，视频和音频内容
                            </li>
                          );
                          break;
                        case "hi":
                          return (
                            <li>
                              <span class="icon icon-youtube"></span>
                              क्योंकि{" "}
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              बहुत सारी सुविधाएँ प्रदान करता है जो अन्य
                              सॉफ़्टवेयर प्रदान नहीं करते हैं, जैसे टेक्स्ट,
                              इमेज, ऑडियो और वीडियो फ़ाइलों का संयोजन और इनपुट
                              विकल्प भी।
                            </li>
                          );
                          break;
                      }
                    })()}
                    {/**
                                <li>
                                    <span class="icon icon-youtube"></span>
                                    Because <b>Memorize<span>it</span>all</b> offer a lot of features other software dont, like combining of text, images, audio and video files and input option as well.
                                </li>
                                */}
                    {(function render() {
                      switch (i18next.language) {
                        case "en":
                          return (
                            <li>
                              <span class="icon icon-repeating1"></span>
                              Because{" "}
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              offer features like private or group content,
                              repeating missed questions, organizing contents
                              and much more.
                            </li>
                          );
                          break;
                        case "ja":
                          return (
                            <li>
                              <span class="icon icon-repeating1"></span>
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              個人だけのコンテンツ、グループコンテンツ、答えられなかった質問だけをリピートするリピート機能、コンテンツをフォルダーに整理、その他
                            </li>
                          );
                          break;
                        case "zh":
                          return (
                            <li>
                              <span class="icon icon-repeating1"></span>
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              单个内容，组内容，重复功能仅重复错回答的问题，整理文件夹中的内容等
                            </li>
                          );
                          break;
                        case "hi":
                          return (
                            <li>
                              <span class="icon icon-repeating1"></span>
                              क्योंकि{" "}
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              निजी या समूह सामग्री, छूटे हुए प्रश्नों को
                              दोहराने, सामग्री को व्यवस्थित करने और बहुत कुछ
                              जैसी सुविधाएं प्रदान करता है।
                            </li>
                          );
                          break;
                      }
                    })()}
                    {/**
                                <li>
                                    <span class="icon icon-repeating1"></span>
                                    Because <b>Memorize<span>it</span>all</b> offer features like private or group content, repeating missed questions, organizing contents and much more.
                                </li>
                                */}
                    {(function render() {
                      switch (i18next.language) {
                        case "en":
                          return (
                            <li>
                              <span class="icon icon-everybody"></span>
                              Because{" "}
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              is for everybody. Young, old, companies, public
                              institutions, schools, teachers, students,
                              professionals or for hobbyists.
                            </li>
                          );
                          break;
                        case "ja":
                          return (
                            <li>
                              <span class="icon icon-everybody"></span>
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              は誰もが使える。全ての年齢層、会社、学校、先生、学生、会社員、その他
                            </li>
                          );
                          break;
                        case "zh":
                          return (
                            <li>
                              <span class="icon icon-everybody"></span>
                              任何人都可以使用{" "}
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              所有年龄段，公司，学校，老师，学生，办公室工作人员等
                            </li>
                          );
                          break;
                        case "hi":
                          return (
                            <li>
                              <span class="icon icon-everybody"></span>
                              क्योंकि{" "}
                              <b>
                                Memorize<span>it</span>all
                              </b>{" "}
                              सभी के लिए है। युवा, बूढ़े, कंपनियां, सार्वजनिक
                              संस्थान, स्कूल, शिक्षक, छात्र, पेशेवर या शौक़ीन
                              लोगों के लिए।
                            </li>
                          );
                          break;
                      }
                    })()}
                    {/**
                                <li>
                                    <span class="icon icon-everybody"></span>
                                    Because <b>Memorize<span>it</span>all</b> is for everybody. Young, old, companies, public institutions, schools, teachers, students, professionals or for hobbyists.
                                </li>
                                */}
                  </ul>
                  {/* <p>{t("why_memorizeitall_reason_2")}</p>
                            <p>{t("why_memorizeitall_reason_3")}</p>
                            <p>{t("why_memorizeitall_reason_4")}</p>
                            <p>{t("why_memorizeitall_reason_5")}</p>
                            <p>{t("why_memorizeitall_reason_6")}</p>
                            <p>{t("why_memorizeitall_reason_7")}</p> */}
                </div>
                {!token ? (
                  <div class="setup-account-bg">
                    <a
                      href="javascript:void(0)"
                      className="white-bg-btn"
                      onClick={() => gotoLogin()}
                    >
                      {t("lp_signup_for_free_account")}
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
            {/* <div class="col-lg-4 col-md-12 col-sm-12">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-6 memorizeitall-info">
                            <a href='#' class="icon_img">
                                <span className=" icon icon-add-groups"></span>
                                <p>Add Groups</p>
                            </a>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-6 memorizeitall-info">
                            <a href='#' class="icon_img">
                                <span className=" icon icon-add-modules"></span>
                                <p>Add Modules</p>
                            </a>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-6 memorizeitall-info">
                            <a href='#' class="icon_img">
                                <span className=" icon icon-brain"></span>
                                <p>1000+ {t("contents")}</p>
                            </a>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-6 memorizeitall-info">
                            <a href='#' class="icon_img">
                                <span className=" icon icon-refer-earn"></span>
                                <p>Refer & Earn</p>
                            </a>
                        </div>
                    </div>
                </div> */}
          </div>
        </div>
      </section>

      <section class="feature_section">
        {/* <h2 class="main_head text-center">{t("memorireitall_features_for_free_user")}</h2> */}
        {(function render() {
          switch (i18next.language) {
            case "en":
              return (
                <h2 class="main_head text-center">
                  <b>
                    Memorize<span>it</span>all
                  </b>{" "}
                  features - free users:
                </h2>
              );
              break;
            case "ja":
              return (
                <h2 class="main_head text-center">
                  <b>
                    Memorize<span>it</span>all
                  </b>{" "}
                  無料ユーザーの機能一覧
                </h2>
              );
              break;
            case "zh":
              return (
                <h2 class="main_head text-center">
                  <b>
                    Memorize<span>it</span>all
                  </b>{" "}
                  免费用户功能列表
                </h2>
              );
              break;
            case "hi":
              return (
                <h2 class="main_head text-center">
                  <b>
                    Memorize<span>it</span>all
                  </b>{" "}
                  सुविधाएं - मुफ़्त उपयोगकर्ता::
                </h2>
              );
              break;
          }
        })()}
        {/* 
        <h2 class="main_head text-center"><b>Memorize<span>it</span>all</b> features - free users:</h2>
        */}
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-9 col-12">
              <ul class="bg-list-icons">
                <li>
                  <span class="icon icon-download5"></span>
                  {t("lp_100")}
                </li>
                <li>
                  <span class="icon icon-creating-content"></span>
                  {t("lp_101")}
                </li>
                <li>
                  <span class="icon icon-search1"></span>
                  {t("lp_102")}
                </li>
                <li>
                  <span class="icon icon-refer"></span>
                  {t("lp_103")}
                </li>
                <li>
                  <span class="icon icon-change-language"></span>
                  {t("lp_104")}
                </li>
                <li>
                  <span class="icon icon-repeating1"></span>
                  {t("lp_105")}
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-12 text-center">
              <span class="icon icon-verified"></span>

              <p>{t("lp_106")}</p>
            </div>
            {/* <div class="col-lg-6 col-md-6 col-sm-12"> 
                    <div class="row">
                        <div class="col-lg-10 col-sm-12">
                            <div class="mm_container">
                                <div class="why_mm_content">
                                    <ul>
                                        <li>{t("memorizeitall_free_user_feature_1")}</li>
                                        <li>{t("memorizeitall_free_user_feature_2")}</li>
                                        <li>{t("memorizeitall_free_user_feature_3")}</li>
                                        <li>{t("memorizeitall_free_user_feature_4")}</li>
                                        <li>{t("memorizeitall_free_user_feature_5")}</li>
                                    </ul>
                                </div>   
                            </div>
                        </div>
                    </div>              
                </div> */}
            {/* <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="row justify-content-end">
                        <div class="col-lg-10 col-sm-12">
                            <div class="mm_container">
                                <div class="why_mm_content">
                                    <ul>
                                        <li>{t("memorizeitall_free_user_feature_6")}</li>
                                        <li>{t("memorizeitall_free_user_feature_7")}</li>
                                        <li>{t("memorizeitall_free_user_feature_8")}</li>
                                        <li>{t("memorizeitall_free_user_feature_9")}</li>
                                        <li>{t("memorizeitall_free_user_feature_10")}</li>
                                    </ul>
                                </div>   
                            </div>
                        </div>
                    </div> 
                </div> */}
          </div>
          {!token ? (
            <div class="setup-account">
              <a
                href="javascript:void(0)"
                className="blue-bg-btn"
                onClick={() => gotoLogin()}
              >
                {t("lp_signup_for_free_account")}
              </a>
            </div>
          ) : null}
        </div>
      </section>

      <section class="feature_section additional-features">
        {/* <h2 class="main_head text-center">{t("memorireitall_features_for_pro_user")}</h2> */}
        {(function render() {
          switch (i18next.language) {
            case "en":
              return (
                <h2 class="main_head text-center">
                  <b>
                    Memorize<span>it</span>all
                  </b>{" "}
                  - Pro members - Additional features
                </h2>
              );
              break;
            case "ja":
              return (
                <h2 class="main_head text-center">
                  <b>
                    Memorize<span>it</span>all
                  </b>{" "}
                  - 有料会員追加機能一覧
                </h2>
              );
              break;
            case "zh":
              return (
                <h2 class="main_head text-center">
                  <b>
                    Memorize<span>it</span>all
                  </b>{" "}
                  - 付费会员附加功能列表
                </h2>
              );
              break;
            case "hi":
              return (
                <h2 class="main_head text-center">
                  <b>
                    Memorize<span>it</span>all
                  </b>{" "}
                  - प्रो सदस्य - अतिरिक्त सुविधाएँ
                </h2>
              );
              break;
          }
        })()}

        {/* <h2 class="main_head text-center"><b>Memorize<span>it</span>all</b> - Pro members - Aditional features</h2> */}
        <div class="container">
          <div class="mm_container">
            <div class="why_mm_content">
              <ul class="list-icons">
                {/* <li>{t("memorizeitall_pro_user_feature_1")}</li>
                        <li>{t("memorizeitall_pro_user_feature_2")}</li>
                        <li>{t("memorizeitall_pro_user_feature_3")}</li>
                        <li>{t("memorizeitall_pro_user_feature_4")}</li>
                        <li>{t("memorizeitall_pro_user_feature_5")}</li> */}
                <li>
                  <span class="icon icon-unlimited"></span>
                  {t("lp_107")}
                </li>
                <li>
                  <span class="icon icon-folder1"></span>
                  {t("lp_108")}
                </li>
                <li>
                  <span class="icon icon-create-group"></span>
                  {t("lp_109")}
                </li>
                <li>
                  <span class="icon icon-group1"></span>
                  {t("lp_110")}
                </li>
                <li>
                  <span class="icon icon-create-share1"></span>
                  {t("lp_111")}
                </li>
              </ul>
            </div>
          </div>
          {!token ? (
            <div class="setup-account-bg">
              <a
                href="javascript:void(0)"
                className="white-bg-btn"
                onClick={() => gotoLogin()}
              >
                {t("lp_signup_for_free_account")}
              </a>
            </div>
          ) : null}
        </div>
      </section>

      <section class="feature_section all-creators">
        {/* <h2 class="main_head text-center">{t("memorireitall_features_for_creator_user")}</h2> */}
        {(function render() {
          switch (i18next.language) {
            case "en":
              return (
                <h2 class="main_head text-center">
                  <b>
                    Memorize<span>it</span>all
                  </b>{" "}
                  - Creators - Additionl featues
                </h2>
              );
              break;
            case "ja":
              return (
                <h2 class="main_head text-center">
                  <b>
                    Memorize<span>it</span>all
                  </b>{" "}
                  - クリエーターの追加機能一覧
                </h2>
              );
              break;
            case "zh":
              return (
                <h2 class="main_head text-center">
                  <b>
                    Memorize<span>it</span>all
                  </b>{" "}
                  - 创建者的附加功能列表
                </h2>
              );
              break;
            case "hi":
              return (
                <h2 class="main_head text-center">
                  <b>
                    Memorize<span>it</span>all
                  </b>{" "}
                  - निर्माता - अतिरिक्त सुविधाएँ
                </h2>
              );
              break;
          }
        })()}
        {/*<h2 class="main_head text-center"><b>Memorize<span>it</span>all</b> - Creators - Additionl featues</h2>*/}
        <div class="container">
          <div class="mm_container">
            <div class="why_mm_content">
              <ul class="bg-list-icons">
                {/* <li>{t("memorizeitall_creator_user_feature_1")}</li>
                        <li>{t("memorizeitall_creator_user_feature_2")}</li>
                        <li>{t("memorizeitall_creator_user_feature_3")}</li>
                        <li>{t("memorizeitall_creator_user_feature_4")}</li>
                        <li>{t("memorizeitall_creator_user_feature_5")}</li> */}
                <li>
                  <span class="icon icon-create"></span>
                  {t("lp_112")}
                </li>
                <li>
                  <span class="icon icon-language3"></span>
                  {t("lp_113")}
                </li>
                <li>
                  <span class="icon icon-selling-content"></span>
                  {t("lp_114")}
                </li>
                <li>
                  <span class="icon icon-sales-report2"></span>
                  {t("lp_115")}
                </li>
              </ul>
            </div>
          </div>
          {!token ? (
            <div class="setup-account">
              <a
                href="javascript:void(0)"
                className="blue-bg-btn"
                onClick={() => gotoLogin()}
              >
                {t("lp_signup_for_free_account")}
              </a>
            </div>
          ) : null}
        </div>
      </section>

      <div class="mm_heading practical-use">
        {/* <h2 class="main_head text-center">{t("memorizeitall_practical_use")}</h2> */}
        {(function render() {
          switch (i18next.language) {
            case "en":
              return (
                <h2 class="main_head text-center">
                  Practical use of{" "}
                  <b>
                    Memorize<span>it</span>all
                  </b>
                </h2>
              );
              break;
            case "ja":
              return (
                <h2 class="main_head text-center">
                  <b>
                    Memorize<span>it</span>all
                  </b>{" "}
                  の利用例:
                </h2>
              );
              break;
            case "zh":
              return (
                <h2 class="main_head text-center">
                  实例:
                  <b>
                    Memorize<span>it</span>all
                  </b>
                </h2>
              );
              break;
            case "hi":
              return (
                <h2 class="main_head text-center">
                  <b>
                    Memorize<span>it</span>all
                  </b>
                  का व्यावहारिक उपयोग
                </h2>
              );
              break;
          }
        })()}
        {/* <h2 class="main_head text-center">Practical use of <b>Memorizeitall</b></h2> */}
      </div>

      <section class="org_seller">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-12 grid-header">
              <h3 class="main_head text-center">
                {t("memorizeitall_practical_use_1")}
              </h3>
              <div class="org_seller_grid">
                <span className=" icon icon-organistaion1"></span>
                <p class="bord_bot"></p>
                <p class="list_item">{t("memorizeitall_use_1_1")}</p>
                <p class="list_item">{t("memorizeitall_use_1_2")}</p>
                <p class="list_item">{t("memorizeitall_use_1_3")}</p>
                <p class="list_item">{t("memorizeitall_use_1_4")}</p>
                <p class="list_item">{t("memorizeitall_use_1_5")}</p>
                <p class="list_item">{t("memorizeitall_use_1_6")}</p>
                <p class="list_item">{t("memorizeitall_use_1_7")}</p>
                {/* <a href="#" class="add_mbtn">Add much more+</a> */}
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 grid-header">
              <h3 class="main_head text-center">
                {t("memorizeitall_practical_use_2")}
              </h3>
              <div class="org_seller_grid">
                <span className=" icon icon-student"></span>
                <p class="bord_bot"></p>
                <p class="list_item">{t("memorizeitall_use_2_1")}</p>
                <p class="list_item">{t("memorizeitall_use_2_2")}</p>
                <p class="list_item">{t("memorizeitall_use_2_3")}</p>
                {(function render() {
                  switch (i18next.language) {
                    case "en":
                      return null;
                      break;
                    case "ja":
                      return (
                        <p class="list_item">{t("memorizeitall_use_2_4")}</p>
                      );
                      break;
                    case "zh":
                      return null;
                      break;
                    case "hi":
                      return null;
                      break;
                  }
                })()}
                {/* <a href="#" class="add_mbtn">Add much more+</a> */}
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 grid-header">
              <h3 class="main_head text-center">
                {t("memorizeitall_practical_use_3")}
              </h3>
              <div class="org_seller_grid">
                <span className=" icon icon-group-of-people"></span>
                <p class="bord_bot"></p>
                <p class="list_item">{t("memorizeitall_use_3_1")}</p>
                <p class="list_item">{t("memorizeitall_use_3_2")}</p>
                <p class="list_item">{t("memorizeitall_use_3_3")}</p>
                <p class="list_item">{t("memorizeitall_use_3_4")}</p>
                {/* <a href="#" class="add_mbtn">Add much more+</a> */}
                {(function render() {
                  switch (i18next.language) {
                    case "en":
                      return null;
                      break;
                    case "ja":
                      return (
                        <>
                          <p class="list_item">{t("memorizeitall_use_3_5")}</p>
                          <p class="list_item">{t("memorizeitall_use_3_6")}</p>
                        </>
                      );
                      break;
                    case "zh":
                      return null;
                      break;
                    case "hi":
                      return null;
                      break;
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="container">
        <div className="col-lg-12 col-md-8 col-sm-12 cust_center">
          <div class="row mb-4">
            <div class="col-8">
              <h3>{t("gener")}</h3>
            </div>
          </div>
          <div class="row genre_pref_list ">
            {genreList
              ? genreList.length > 0
                ? genreList.map((eachGenre) => (
                    <div
                      class="col-lg-2 col-md-3 col-sm-4 col-xs-6 no-pad"
                      // onClick={() => genreSelected(eachGenre.id, eachGenre.name)}
                    >
                      <div className="item">
                        <div class="cust_genre">
                          <img src={GENER_PATH + eachGenre.image} />
                          <h4
                            dangerouslySetInnerHTML={{ __html: eachGenre.name }}
                          ></h4>
                        </div>
                      </div>
                    </div>
                  ))
                : null
              : null}
          </div>
        </div>
      </div>

      <section class="logo_carousel">
        <h2 class="main_head text-center">{t("lp_116")}</h2>
        <div class="container">
          <div class="row">
            <OwlCarousel
              className="owl-carousel logo_slider"
              // id="owl-carousel"
              items="3"
              autoplay
              nav
              dots
              loop
              margin={0}
            >
              {ourUsers.map((item) => (
                <div class="item">
                  <a href={item.url} target="_blank">
                    <img src={item.image} />
                  </a>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
        {/* <a href="#" class="play_btn">{t("play_flash_quiz")}</a> */}
      </section>
      {canRender ? renderCarausal() : ""}
    </React.Fragment>
  );
}

export default MyHomePage;
