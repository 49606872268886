import React from "react";
import { Redirect, Route } from "react-router-dom";

import { useCookies } from "react-cookie";

// handle the private routes
function PrivateRoute({ path, component: Component, ...rest }) {
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);
  console.log("path", cookies["access_token"], path);
  /*
      <Route
      {...rest}
      render={(props) => getToken() ? <Component {...props} restprops={rest} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
    />
   */
  return (
    <Route
      path={path}
      {...rest}
      render={(props) =>
        cookies["access_token"] ? (
          <Component {...props} restprops={rest} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
