/* eslint-disable */
import axios from "axios";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Creators from "./Creators";
import GenreDetails from "./GenreDetails";
import Pagination from "./Pagination";
import SuggestedQuizzes from "./SuggestedQuizzes";
import TopQuizzes from "./TopQuizzes";

import "../less/animate.min.css";
import "../less/bootstrap.min.css";
import "../less/common.css";
import "../less/icon-font.css";
import "../less/my-credits.css";
import "../less/releway-font.css";
// import "../less/owl.carousel.min.css"
import "../less/test.css";
// import "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"

function GenreDetailsPage(props) {
  // console.log('genre details', props)
  let _genreId = props.match.params.genreId;
  let _cat_id = props.match.params.cat_id ? props.match.params.cat_id : 0;
  let _parent_cat_id = props.match.params.parent_cat_id
    ? props.match.params.parent_cat_id
    : 0;

  const [genreId, setGenreId] = useState(_genreId);
  const [cat_id, setCat_id] = useState(_cat_id);
  const [parent_cat_id, setParent_cat_id] = useState(_parent_cat_id);

  console.log("genreId", genreId);
  console.log("cat_id", cat_id);
  console.log("parent_cat_id", parent_cat_id);

  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    "access_token",
    "user",
    "global_language",
    "login_language",
  ]);
  const user = cookies["user"]; //getUser();
  const token = cookies["access_token"]; //getToken()
  const user_type_id = user.id;

  const [pagesInTopQuizList, setPagesInTopQuizList] = useState(1);

  //For Top Quizzes Pagination.
  const [topQuizListResults, setTopQuizListResults] = useState([]);
  const [totalTopQuizResultsPages, setTotalTopQuizResultsPages] =
    useState(null);
  const [totalTopQuizListResults, setTotalTopQuizListResults] = useState(null);
  const [currentTopQuizListResults, setCurrentTopQuizListResults] = useState(1);

  //For Suggested Quizzes Pagination.
  const [suggestedQuizResults, setSuggestedQuizResults] = useState([]);
  const [totalSuggestedQuizResultsPages, setTotalSuggestedQuizResultsPages] =
    useState(null);
  const [totalSuggestedQuizResults, setTotalSuggestedQuizResults] =
    useState(null);
  const [currentSuggestedQuizResults, setCurrentSuggestedQuizResults] =
    useState(1);

  //const [suggestedQuizList, setSuggestedQuizList] = useState([]);
  //const [pagesInSuggestedQuizList, setPagesInSuggestedQuizList] = useState(1);
  const PAGE_LIMIT = 6;

  const [creators, setCreators] = useState([]);

  const globalLang = cookies["global_language"]; //JSON.parse(getUserGlobalLanguage());
  let globalLangCode = globalLang.lang_code;
  let globalLangId = globalLang.id;
  const [adv, setAdv] = useState([]);
  const [adLength, setAdLength] = useState(0);
  const [banners, setBanners] = useState();

  let awsImageUrl = process.env.REACT_APP_DO_CDN_PATH;
  let ADVERTISEMENT_PATH = awsImageUrl + "/uploads/advertisement/";
  let BANNER_PATH = awsImageUrl + "/uploads/banners/";

  const handleCatChange = (_cat_id) => {
    console.log("handleCatChange _cat_id", _cat_id);
    //setCat_id(cat_id)
    if (_cat_id > 0) {
      props.history.push("/genredetails/" + genreId + "/" + _cat_id);
    } else {
      props.history.push("/genredetails/" + genreId);
    }
  };

  useEffect(() => {
    axios({
      method: "post",
      url: "/api/banners/list",
      headers: {
        locale: globalLangCode,
        // 'x-access-token': "Memo "+token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log(response);
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].banner_type == 2) {
            setBanners(response.data.data[i]);
            break;
          }
        }
      })
      .catch((error) => {
        // removeUserSession();
        console.log("error", error);
      });

    axios({
      method: "post",
      url: "/api/advertisements/list",
      headers: {
        locale: globalLangCode,
      },
      data: qs.stringify({
        position: 0,
        size: 1,
        user_id: user_type_id,
      }),
    })
      .then((response) => {
        console.log("advertisement", response);
        setAdLength(response.data.total);
        // setAdv(response.data.data)
        // setUserSession(token, response.data.user);
      })
      .catch((error) => {
        // removeUserSession();
      });

    axios({
      method: "post",
      url: "/api/genres/details/top/quizzes",
      headers: {
        locale: globalLangCode,
      },
      data: qs.stringify({
        page: "1",
        genre_id: genreId,
        cat_id: cat_id,
        parent_cat_id: parent_cat_id,
      }),
    })
      .then((response) => {
        console.log("top quiz response", response);
        setTopQuizListResults((topQuizListResults) =>
          topQuizListResults.concat(response.data.data)
        );
        setTotalTopQuizResultsPages(response.data.total_pages);
        setTotalTopQuizListResults(response.data.total);
        //setCurrentTopQuizListResults(currentPage)
      })
      .catch((error) => {
        // removeUserSession();
      });

    axios({
      method: "post",
      url: "/api/genres/details/suggested/quizzes",
      headers: {
        locale: globalLangCode,
      },
      data: qs.stringify({
        page: "1",
        genre_id: genreId,
        cat_id: cat_id,
        parent_cat_id: parent_cat_id,
      }),
    })
      .then((response) => {
        console.log("suggested quiz response", response);
        setSuggestedQuizResults((suggestedQuizResults) =>
          suggestedQuizResults.concat(response.data.data)
        );
        setTotalSuggestedQuizResultsPages(response.data.total_pages);
        setTotalSuggestedQuizResults(response.data.total);
        //setCurrentSuggestedQuizResults(currentPage)
        //setLoading(false)
      })
      .catch((error) => {
        // removeUserSession();
      });
  }, []);

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    if (adLength > 0) {
      setAdv([]);
      for (let i = 0; i < 2; i++) {
        let randomPosition = getRandomInt(0, adLength);
        axios({
          method: "post",
          url: "/api/advertisements/list",
          headers: {
            locale: globalLangCode,
          },
          data: qs.stringify({
            position: randomPosition,
            size: 1,
            user_id: user_type_id,
          }),
        })
          .then((response) => {
            console.log("advertisement", response);
            setAdv((ad) => ad.concat(response.data.data));
          })
          .catch((error) => {
            // removeUserSession();
          });
      }
    }
  }, [adLength]);

  const goToDashboard = () => {
    props.history.push("/myprofile");
  };

  const fetchTopQuizResults = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/genres/details/top/quizzes",
      //cancelToken: ggs_cancelToken.token,
      headers: {
        locale: globalLangCode,
      },
      data: qs.stringify({
        page: currentPage.toString(),
        genre_id: genreId,
        cat_id: cat_id,
        parent_cat_id: parent_cat_id,
      }),
    })
      .then((response) => {
        console.log("search response", response.data);
        //setResults(response.data)
        setTopQuizListResults(response.data.data);
        setTotalTopQuizResultsPages(response.data.total_pages);
        setTotalTopQuizResults(response.data.total);
        setCurrentTopQuizListResults(currentPage);
        //setLoading(false)
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          //setLoading(false);
          //setMessage("Failed to fetch results.Please check network")
        }
      });
  };
  const fetchSuggestedQuizResults = async (currentPage = 1) => {
    axios({
      method: "post",
      url: "/api/genres/details/suggested/quizzes",
      headers: {
        locale: globalLangCode,
      },
      data: qs.stringify({
        page: currentPage.toString(),
        genre_id: genreId,
        cat_id: cat_id,
        parent_cat_id: parent_cat_id,
      }),
    })
      .then((response) => {
        console.log("search response", response.data);
        //setResults(response.data)
        setSuggestedQuizResults(response.data.data);
        setTotalSuggestedQuizResultsPages(response.data.total_pages);
        setTotalSuggestedQuizResults(response.data.total);
        setCurrentSuggestedQuizResults(currentPage);
        //setLoading(false)
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          //setLoading(false);
          //setMessage("Failed to fetch results.Please check network")
        }
      });
  };
  const onSuggestedQuizResultsPageChanged = (data) => {
    //setLoading(true);
    console.log("data.currentPage=" + data.currentPage);
    fetchSuggestedQuizResults(data.currentPage);
    setCurrentSuggestedQuizResults(data.currentPage);
  };
  const onTopQuizResultsPageChanged = (data) => {
    console.log("data.currentPage=" + data.currentPage);
    fetchTopQuizResults(data.currentPage);
    setCurrentTopQuizListResults(data.currentPage);
  };
  return (
    <React.Fragment>
      {/* <BasicHeader 
            {...props}
        /> */}

      <div class="inner-header">
        <div class="container">
          <div class="row">
            <div class="col-sm-5 col-md-6 col-lg-6">
              <h2 class="animated fadeInLeft">{t("genre_details")}</h2>
            </div>
            <div class="col-sm-7 col-md-6 col-lg-6">
              <div class="breadcrumb-item animated fadeInRight">
                <ul>
                  <li onClick={goToDashboard}>
                    <a href="javascript:void(0)">
                      <span class="icon-home1"></span> {t("home")}
                    </a>
                  </li>
                  <li>
                    <span class="icon-next"></span>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="active">
                      {t("genre_details")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {banners ? (
        <div className="banner_image">
          <a class="item" href={banners.link}>
            <img
              src={BANNER_PATH + banners.image}
              class="img_banner"
              style={{ "max-height": "50vh" }}
            ></img>
          </a>
        </div>
      ) : null}

      <GenreDetails
        cat_id={cat_id}
        parent_cat_id={parent_cat_id}
        onCatChange={(_cat_id) => handleCatChange(_cat_id)}
        {...props}
      />

      {suggestedQuizResults.length > 0 ? (
        <SuggestedQuizzes suggestedQuizList={suggestedQuizResults} {...props} />
      ) : null}
      <div class="text-center pt-4">
        {totalSuggestedQuizResults > PAGE_LIMIT ? (
          <div className="d-flex flex-row py-4 align-items-center justify-content-center">
            <Pagination
              totalRecords={totalSuggestedQuizResults}
              totalPages={totalSuggestedQuizResultsPages}
              currentPage={currentSuggestedQuizResults}
              pageLimit={PAGE_LIMIT}
              pageNeighbours={1}
              onPageChanged={onSuggestedQuizResultsPageChanged}
            />
          </div>
        ) : null}
        {/* <a href="javascript:void(0)" class="btn-border mr-2">Save as draft</a> */}
        {/* <a href="javascript:void(0)" class="btn-background">Publish now</a> */}
      </div>

      <div class="advertisement-slider margin-top">
        <div class="container">
          <div class="row">
            {/*
            <OwlCarousel
                            className="owl-carousel logo_slider"
                            // id="owl-carousel"
                            items="2"
                            autoplay
                            nav
                            dots
             <               margin={10}
                            nav
                        >
                 */}
            {adv && adv.length > 0
              ? adv.map((ad) => (
                  <div className="col-md-6 col-sm-12">
                    <a class="item" target="_blank" href={ad.link}>
                      <img
                        src={ADVERTISEMENT_PATH + ad.image}
                        className="mb-2"
                      />
                    </a>
                  </div>
                ))
              : null}
            {/*</OwlCarousel>*/}
          </div>
        </div>
      </div>

      {topQuizListResults.length > 0 ? (
        <TopQuizzes topQuizList={topQuizListResults} {...props} />
      ) : null}
      <div class="text-center pt-4">
        {totalTopQuizListResults > PAGE_LIMIT ? (
          <div className="d-flex flex-row py-4 align-items-center justify-content-center">
            <Pagination
              totalRecords={totalTopQuizListResults}
              totalPages={totalTopQuizResultsPages}
              currentPage={currentTopQuizListResults}
              pageLimit={PAGE_LIMIT}
              pageNeighbours={1}
              onPageChanged={onTopQuizResultsPageChanged}
            />
          </div>
        ) : null}
        {/* <a href="javascript:void(0)" class="btn-border mr-2">Save as draft</a> */}
        {/* <a href="javascript:void(0)" class="btn-background">Publish now</a> */}
      </div>

      <Creators fetchType={2} genreId={genreId} {...props} />

      {/* <BasicFooter /> */}
    </React.Fragment>
  );
}

export default GenreDetailsPage;
