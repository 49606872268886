import React from "react";
import { Redirect, Route } from "react-router-dom";

import { useCookies } from "react-cookie";

// handle the public routes
function PublicRoute({ component: Component, ...rest }) {
  const [cookies, setCookie] = useCookies(["access_token", "user"]);

  {
    /* 
    <Route
      {...rest}
      render={(props) => !getToken() ? <Component {...props} /> : <Redirect to={{ pathname: '/dashboard' }} />}
    />
    */
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        !cookies["access_token"] ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/myprofile" }} />
        )
      }
    />
  );
}

export default PublicRoute;
